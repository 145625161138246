import React, { useState, createContext, useEffect, useCallback } from "react";

export const GapiClientContext = createContext();

const GAPI_CONFIG = {
    clientId: "493644900586-hkio4j2g3228k7qu9qqbmhblelm5n3dj.apps.googleusercontent.com",
    scope: "email",
};


const GapiContextProvider = ({ ...props }) => {
    const [GapiClient, setGapiClient] = useState(); //{ gapi });
    const initClient = useCallback(async () => {
        if (window.gapiIsInitialized) return;
        return gapi.client.init(GAPI_CONFIG).then(
            () => {
                return;
            },
            (e) => {
                window.gapiIsLoading = false;
                console.info("error init gapi client", e.details);
            }
        );
    }, []);

    const setupGapi = useCallback(async () => {
        const gapi = await import("gapi-script").then((pack) => pack.gapi);
        setGapiClient({ gapi });

        try {
            await gapi.load("client:auth2", initClient);
        } catch (e) {
            window.gapiIsLoading = false;
            console.log("couldnt sign in to gAPI!", e);
        }
    }, [initClient]);

    useEffect(() => {
        if (window.gapiIsInitialized || window.gapiIsLoading) return;
        window.gapiIsLoading = true;
        setupGapi();
    }, [initClient, setupGapi]);

    return (
        <GapiClientContext.Provider
            value={{
                GapiClient
            }}
        >
            {props.children}
        </GapiClientContext.Provider>
    );
};

export default GapiContextProvider;