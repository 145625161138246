import React, { Fragment } from "react";
import "@components/custom-button/CustomButtonStyle.scss";

const CustomButton = ({ type, name, disable, onClick, classname, id }) => {
  return (
    <div className="CustomButton">
      <button
        id={id}
        disabled={disable}
        type={type}
        onClick={onClick}
        className={`buttonmain ${classname && classname}`}
      >
        {name}
      </button>
    </div>
  );
};
export default CustomButton;
