import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/booking-success-popup/BookingSuccessPopupStyle.scss";

const BookingSuccessPopup = ({
  show,
  setShowModal,
  bookingDetails,
  setBookingDetails,
  forGuest,
  closeForGuest,
  successOrFail,
  setSucc,
}) => {
  let history = useHistory();

  return (
    <Modal
      className="BookingSuccessPopup"
      show={show}
      onHide={() => {
        setBookingDetails();
        forGuest === true && closeForGuest(false);
        setSucc(false);
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setBookingDetails();
            setSucc(false);
            forGuest === true && closeForGuest(false);
            setShowModal(false);
          }}
        />
        <Container>
          {forGuest === true ? (
            <Row>
              <Col lg={12}>
                <div className="heading-text">
                  Your booking details are sent to your mail !
                </div>
              </Col>
            </Row>
          ) : successOrFail === true ? (
            <Row>
              <Col lg={12}>
                <div className="heading-text">Your booking is successful !</div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12}>
                <div className="heading-text">
                 {bookingDetails?.booking.totalAmount=="0.00"?"Booking successful ": 
                 'Payment is pending. It may get successful or failed in some time. Please check booking details page to see status of payment.'}
                </div>
              </Col>
            </Row>
          )}
          {forGuest === true ? (
            <Row>
              <Col xs={12}>
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Go to booking"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setBookingDetails();

                    closeForGuest(false);
                    history.push(
                      `/booking-details-guest/${bookingDetails.booking.id}`
                    );
                    setShowModal(false);
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Go to booking"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    history.push(
                      `/booking-details/${bookingDetails.booking.id}`
                    );
                    setBookingDetails();
                    setShowModal(false);
                  }}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(BookingSuccessPopup);
