import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  eventsCardLoadingStart,
  eventsCardSuccess,
  eventsCardFail,
  eventCategoryLoadingStart,
  eventCategorySuccess,
  eventCategoryFail,
  allCategoryLoadingStart,
  allCategorySuccess,
  allCategoryFail,
  allLocationLoadingStart,
  allLocationSuccess,
  allLocationFail,
  becomePartnerCalled,
  becomePartnerSuccess,
  becomepartnerFailure,
  resetBecomepartnerStates,
  getBannerImageLoadingStart,
  getBannerImageSuccess,
  getBannerImageFailure,
  clearAppRedux,
} from "./appredux";

export function* getEventsCardSaga(api, { payload }) {
  yield put(eventsCardLoadingStart());
  try {
    const result = yield call(Api.callServer, api, true);
    yield put(eventsCardSuccess(result));
  } catch (e) {
    yield put(eventsCardFail(e.message));
  }
}
export function* getEventCategorySaga(api, { payload }) {
  yield put(eventCategoryLoadingStart());
  try {
    const result = yield call(Api.callServer, api.adminEventcategory, {}, true);
    // console.log("resss , ", result);
    yield put(eventCategorySuccess(result));
  } catch (e) {
    yield put(eventCategoryFail(e.message));
  }
}
export function* getAllcategorySaga(api, { payload }) {
  yield put(allCategoryLoadingStart());
  const { skip, limit } = payload;

  try {
    let eventCategorties = yield call(Api.callServer, api.adminEventcategory, {}, true);
    let allCategoryEvents = {};
    for (let i = 0; i < eventCategorties.length; i++) {
      const result = yield call(
        Api.callServer,
        api.getAllCategoryEvents,
        { category: eventCategorties[i], skip: skip, limit: limit },
        true
      );
      allCategoryEvents[eventCategorties[i].title] = result;
    }

    yield put(allCategorySuccess(allCategoryEvents));
  } catch (e) {
    yield put(allCategoryFail(e.message));
  }
}

export function* getLocationSaga(api, { payload }) {
  yield put(allLocationLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getLocationForHomePageApi,
      {},
      true
    );
    yield put(allLocationSuccess(result));
  } catch (e) {
    yield put(allLocationFail(e.message));
  }
}

export function* becomePartnerSaga(api, { payload }) {
  yield put(becomePartnerCalled());
  try {
    const result = yield call(Api.callServer, api.becomePartnerCall, {
      email: payload.email,
    });
    yield put(becomePartnerSuccess(result));
  } catch (err) {
    yield put(becomepartnerFailure(err.message));
  }
}

export function* GetBannerImageSaga(api, { payload }) {
  yield put(getBannerImageLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetHomeBannerImages,
      {},
      true
    );
    // console.log("banner imag", result);
    yield put(getBannerImageSuccess(result));
  } catch (e) {
    yield put(getBannerImageFailure(e.message));
  }
}

export function* logout(api, { payload }) { }
