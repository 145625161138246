import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Cardimg from "@images/cardimg.svg";
import "@components/booking-history-card/BookingHistoryCardStyles.scss";

const BookingHistoryCard = ({ data }) => {
  const history = useHistory();
  const [eventDet, setEventDet] = useState();
  const [bookId, setBookId] = useState();
  const [bookedDate, setBookedDate] = useState();
  const [status, setStatus] = useState();
  const [amount, setAmount] = useState();

  useEffect(() => {
    if (data !== undefined) {
      setEventDet(data.bmtEvent);
      setBookId(data.id);
      setBookedDate(data.createdOn);
      setStatus(data.bookingStatus);
      setAmount(data.totalAmount);
    }
  }, [data]);

  return (
    <div
      className="bookinghistorycard d-flex"
      onClick={() => {
        if (bookId !== undefined && eventDet !== undefined) {
          history.push(`/booking-details/${bookId}`, { state: eventDet });
        }
      }}
    >
      {eventDet !== undefined && (
        <div className="leftalign">
          <img
            src={eventDet.banner !== null ? eventDet.banner : Cardimg}
            className="cardimg"
          />
        </div>
      )}
      <div className="rightsidecardwrapper">
        <p className="eventtitle">{eventDet !== undefined && eventDet.title}</p>

        <p className="bookingid">
          Booking Id : {bookId !== undefined && bookId}
        </p>
        {bookedDate !== undefined && (
          <p className="details">
            Booked on : {moment(bookedDate).format("MMMM Do YYYY")}{" "}
          </p>
        )}
        {/* {eventDet !== undefined && eventDet.startDate !== null && (
          <p className="details">
            Event Date : {moment(eventDet.startDate).format("MMMM Do YYYY")}{" "}
          </p>
        )} */}
        {amount !== undefined && <p className="details">₹{amount}</p>}
        <div
          className={
            status !== "successful"
              ? status !== "failed"
                ? "statusupcoming"
                : "statusfailed "
              : "statusCompleted"
          }
        >
          {status !== undefined && <span>{status}</span>}
        </div>
      </div>
    </div>
  );
};

export default BookingHistoryCard;
