import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "@components/location-cards/LocationCardsStyle.scss";
import { useHistory } from "react-router-dom";
import {
  clearLoacationTitleForSearch,
  locationForSearchLoading,
  clearCategoryForSearch,
  triedForLocationSearch,
} from "@store/eventredux";

import { connect } from "react-redux";
import * as _ from "lodash";
const LocationCards = ({
  locations,
  clearLocationAndTitleAsync,
  clearCategoryAsync,
  locationForSearchAsync,
  locationTrialAsync,
}) => {
  // const Location = [
  //   {
  //     img: Location1,
  //     location: "Pernem",
  //   },
  //   {
  //     img: Location2,
  //     location: "Panjim",
  //   },
  //   {
  //     img: Location3,
  //     location: "Margao",
  //   },
  //   {
  //     img: Location4,
  //     location: "Cancona",
  //   },
  // ];
  const history = useHistory();
  return (
    <div className="LocationCards">
      <div className="heading">
        <span>Trendings</span>
      </div>
      <Row>
        {locations !== undefined &&
          _.isArray(locations) &&
          locations.length !== 0 &&
          locations.slice(0, 8).map((data) => (
            // console.log("data", data)
            <Col
              lg={3}
              md={4}
              sm={6}
              className="card-div"
              key={`locations${data.id}`}
              onClick={() => {
                history.push("/allevents");
                locationForSearchAsync(data.destination);
                locationTrialAsync(true);
                clearLocationAndTitleAsync();
                clearCategoryAsync();
              }}
            >
              <Card>
                <div className="img-div">
                  <img
                    className="img-fluid loc-img"
                    src={data?.banner}
                    alt="Card image cap"
                  />
                </div>
                <div className="location">
                  <span className="location-text">{data?.destination}</span>
                </div>
              </Card>
            </Col>
          ))}
        {/* {Location !== undefined &&
          Location.length !== 0 &&
          Location.map((data) => (
            <Col lg={3} md={4} sm={6} className="card-div">
              <Card>
                <div className="img-div">
                  <img
                    className="img-fluid loc-img"
                    src={data?.img}
                    alt="Card image cap"
                  />
                </div>
                <div className="location">
                  <span className="location-text">{data?.location}</span>
                </div>
              </Card>
            </Col>
          ))} */}
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearLocationAndTitleAsync: () => dispatch(clearLoacationTitleForSearch()),
  locationForSearchAsync: (data) => dispatch(locationForSearchLoading(data)),
  clearCategoryAsync: () => dispatch(clearCategoryForSearch()),
  locationTrialAsync: (data) => dispatch(triedForLocationSearch(data)),
});

export default connect(null, mapDispatchToProps)(LocationCards);
