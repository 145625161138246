import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import csc from "country-state-city";
import _ from "lodash";
import moment from "moment";
import { clearEditProfileError } from "@store/userredux";
import InputField from "@components/input-field/InputField";
import { ReactComponent as CaretDownIcon } from "@images/dropdown.svg";
import "@components/edit-profile-form/EditProfileFormStyles.scss";

function EditProfileForm({
  UserProfileData,
  EditProfileData,
  SetInfo,
  EditProfileBackenError,
  clearBackend,
}) {
  const Gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const Country = [
    { value: "IND", label: "India" },
    { value: "BRL", label: "BRAZIL" },
  ];
  const {
    name,
    email,
    mobile,
    dob,
    gender,
    address,
    country,
    city,
    state,
    location,
    zipcode,
    profileImage,
  } = UserProfileData || {};

  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const [newDob, setNewDob] = useState();
  const [newMobile, setNewMobile] = useState(mobile);
  const [newGender, setNewGender] = useState(gender);
  const [newAddress, setNewAddress] = useState(address);
  const [newZipCode, setNewZipCode] = useState(zipcode);
  const [newState, setNewState] = useState(state);
  const [newCity, setNewCity] = useState(city);

  const countries = csc.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedStates = (countryId) =>
    csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  const [newCountry, setNewCountry] = useState(
    _.find(updatedCountries, { label: country })
  );

  useEffect(() => {
    setNewDob(ChangeDate(UserProfileData.dob));
  }, [UserProfileData]);
  const ChangeDate = (dt) => {
    if (dt !== null) {
      // const [onlyD] = dt.split("T");
      // console.log("ony d", onlyD);
      // return onlyD;
      const onlyYear = moment(dt).format("YYYY");
      const onlyMonth = moment(dt).format("MMMM");
      const getMonthNumber = moment().month(onlyMonth).format("MM");
      const onlyDate = moment(dt).format("DD");

      const finalDate = onlyYear + "-" + getMonthNumber + "-" + onlyDate;

      return finalDate;
    }
  };
  useEffect(() => {
    SetInfo({
      address: newAddress,
      email: newEmail,
      name: newName,
      mobile: newMobile,
      gender: newGender,
      dob: moment(newDob).toISOString(),
      zipcode: Number(newZipCode),
      country: newCountry?.label,
      state: newState?.label,
      city: newCity,
    });
  }, [
    newName,
    newAddress,
    newMobile,
    newDob,
    newGender,
    newCity,
    newCountry,
    newState,
    newZipCode,
  ]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#261B1B",
      background: state.isFocused
        ? "#F3F3F3"
        : state.isSelected
        ? "#F3F3F3"
        : "white",
      padding: "10px 17.17px",
      cursor: "pointer",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon className="dropdown-icon" />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (EditProfileBackenError !== undefined) {
      if (alert(`${EditProfileBackenError}`)) {
        clearBackend();
      } else {
        clearBackend();
      }
    }
  }, [EditProfileBackenError]);

  return (
    <div className="editprofileform ">
      <Form>
        <Row>
          <Col lg="12">
            <div>
              <span className="form-title">Account Details</span>
            </div>
            <div className="textboxes">
              <FormGroup className="form-input col-lg-6">
                <label>Name</label>
                <InputField
                  placeholder={"Enter Name"}
                  type="text"
                  classname="form-field"
                  value={newName}
                  onChange={(event) => setNewName(event.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-input col-lg-6">
                <label>Date of Birth</label>
                <InputField
                  placeholder={newDob ? newDob : "Date of Birth"}
                  type="date"
                  classname="date-field"
                  value={newDob}
                  onChange={(event) => setNewDob(event.target.value)}
                />
              </FormGroup>
            </div>
            <div className="textboxes">
              <FormGroup className="form-input col-lg-6">
                <label>Email</label>
                <div className="emailtxt">
                  <InputField
                    placeholder={"Email"}
                    type="text"
                    classname="form-field"
                    value={newEmail}
                    disabled={true}
                    // onChange={(event) => setNewEmail(event.target.value)}
                  />
                </div>
              </FormGroup>
              <FormGroup className="form-input select-dp col-lg-6">
                <label>Gender</label>
                <Select
                  placeholder={newGender ? _.capitalize(newGender) : "Gender"}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  value={newGender}
                  options={Gender}
                  onChange={(event) => setNewGender(event.value)}
                />
              </FormGroup>
            </div>
            <div className="textboxes">
              <FormGroup className="form-input col-lg-6">
                <label>Mobile</label>
                <InputField
                  placeholder={"Mobile"}
                  type="text"
                  classname="form-field"
                  value={newMobile}
                  onChange={(event) => setNewMobile(event.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-input col-lg-6">
                <label>Pin Code</label>
                <InputField
                  placeholder={"Area Code"}
                  type="text"
                  classname="form-field"
                  value={newZipCode}
                  onChange={(event) => setNewZipCode(event.target.value)}
                />
              </FormGroup>
            </div>
            <div className="textboxes"></div>
            <div className="textboxes">
              <FormGroup className="form-input col-lg-12">
                <label>Address</label>
                <InputField
                  placeholder={"Address"}
                  type="text"
                  classname="form-field"
                  as="textarea"
                  value={newAddress}
                  onChange={(event) => setNewAddress(event.target.value)}
                />
              </FormGroup>
            </div>
            <div className="textboxes">
              <FormGroup className="form-input select-dp col-lg-6">
                <label>Country</label>
                <Select
                  placeholder={newCountry ? newCountry : "Select Country"}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  value={newCountry}
                  options={updatedCountries}
                  onChange={(value) => {
                    setNewCountry(value);
                  }}
                />
              </FormGroup>
              <FormGroup className="form-input select-dp col-lg-6">
                <label>State</label>
                <Select
                  id="state"
                  name="state"
                  placeholder={newState ? newState : "Select State"}
                  label="state"
                  value={newState}
                  options={updatedStates(newCountry ? newCountry?.value : null)}
                  onChange={(value) => {
                    setNewState(value);
                  }}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
              </FormGroup>
            </div>
            <div className="textboxes">
              <FormGroup className="form-input col-lg-6">
                <label>City</label>
                <InputField
                  placeholder={"City"}
                  type="text"
                  classname="form-field"
                  value={newCity}
                  onChange={(event) => setNewCity(event.target.value)}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const ProfileDataLoading = _.get(user, "userProfileLoading", false);
  const UserProfileData = _.get(user, "userDetails", null);
  const EditProfileBackenError = _.get(user, "editProfileDataError", undefined);
  return {
    ProfileDataLoading,
    UserProfileData,
    EditProfileBackenError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ChangeProfileImage: (data) =>
    dispatch({ type: "ChangeProfileImageCalled", payload: data }),
  EditProfileData: (data) =>
    dispatch({ type: "EditProfileDataCalled", payload: data }),
  clearBackend: () => dispatch(clearEditProfileError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
