import React from "react";
import Navbar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import { ReactComponent as Marker1 } from "@images/marker1.svg";
import { ReactComponent as Marker2 } from "@images/marker2.svg";
import "@features/contact-us/ContactUsStyles.scss";

function ContactUs() {
  return (
    <div id="contact-us">
      <Navbar />
      <div className="container">
        <h2 className="heading">Contact Us</h2>
        <div className="outerwrapper">
          <div className="leftalign ">
            <span className="title">Address : </span>{" "}
            <span className="subtitle">
              {" "}
              Shop 1107/B, Morjim, Goa, India - 403512
            </span>
          </div>
          <div className="leftalign ">
            <span className="title">Phone Number : </span>
            <span className="subtitle"> +91 9409999910</span>
          </div>
          <div className="leftalign ">
            <span className="title">Email ID : </span>
            <span className="subtitle">booking@followgoa.com</span>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}

export default ContactUs;
