import React, { useState } from "react";
import { Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import NavBar from "@components/navbar/Navbar";
import logo from "@images/logo.svg";
import SuccessPopup from "@components/success-popup/SuccessPopup";
import "@features/reset-password/ResetPasswordStyle.scss";
import * as _ from "lodash";

function ResetPassword({ }) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let history = useHistory();
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [showeye, setshoweye] = useState(true);
  const [showeye1, setshoweye1] = useState(true);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [confirmPasswordLoaded, setConfirmPasswordLoaded] = useState(false);
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState();
  const [status, setStatus] = useState();


  // let userRole =query.get("userRole=operator")

  const setNewPassword = async () => {
    if (password.length === 0) {
      setStatus(false);
      setConfirmPasswordMessage("Password should not be empty");
    } else {
      if (password !== password1) {
        setStatus(false);
        setConfirmPasswordMessage("Password and confirm password are not same");
      } else {
        if (password.length < 8) {
          setStatus(false);
          setConfirmPasswordMessage(
            "Password must be longer than or equals to 8 characters"
          );
        } else {
          setConfirmPasswordLoaded(true);
          setStatus(true);
          // let fmData = new FormData();
          // fmData.append("newPassword", password)
          //fmData.append("confirmation", password1);
          try {
            let userRole =query.get("userRole")
            let result = await axios({
              method: "post",
              url: `${process.env.RAZZLE_APP_APILINK
                }/auth/set-new-password?email=${query.get(
                  "email"
                )}&token=${query.get("token")}`,
              data: { newPassword: password },
              // headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
            setConfirmPasswordLoaded(false);
            //console.log(result);
            location.href =
              `${process.env.RAZZLE_APP_APPLINK}/reset-password-success?userRole=${userRole}`;
          } catch (err) {
            setStatus(false);
            setConfirmPasswordMessage(err.response.data.message);
            setConfirmPasswordLoaded(false);
          }
        }
      }
    }
  };

  return (
    <div id="ResetPassword">
      <NavBar />
      {confirmPasswordLoaded && <Loader />}
      <Container>
        <SuccessPopup
          show={successPopUp}
          setShowModal={setSuccessPopUp}
          message={"Password Changed Successfully"}
        />
        <div className="form-div">
          <Row>
            <Col lg={12}>
              <Card className="login-card">
                <span className="heading">Reset Password</span>
                <img src={logo} className="brandlogo" />
                <Card.Body>
                  <Form>
                    <FormGroup className="form-input">
                      <label>New Password</label>
                      <InputField
                        type={showeye ? "password" : "text"}
                        classname="password-control"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        placeholder="New Password"
                        eye
                        setshow={() => setshoweye(!showeye)}
                        showeye={showeye}
                      />
                    </FormGroup>
                    <FormGroup className="form-input">
                      <label>Confirm Password</label>
                      <InputField
                        type={showeye1 ? "password" : "text"}
                        classname="password-control"
                        value={password1}
                        onChange={(event) => {
                          setPassword1(event.target.value);
                        }}
                        placeholder="Confirm Password"
                        eye
                        setshow={() => setshoweye1(!showeye1)}
                        showeye={showeye1}
                      />
                      {status !== undefined && status === false ? (
                        <p className="errorText">{confirmPasswordMessage}</p>
                      ) : null}
                    </FormGroup>
                    <Row>
                      <CustomButton
                        type="button"
                        name="Submit"
                        filled={true}
                        primary={true}
                        classname="login-btn"
                        onClick={setNewPassword}
                      />
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default ResetPassword;
