import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import EditProfileForm from "@components/edit-profile-form/EditProfileForm";
import MainFooter from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import Navbar from "@components/navbar/Navbar";
import profile from "@images/demo-profile.svg";
import "@features/edit-profile/EditProfileStyles.scss";
import * as _ from "lodash";

function EditProfile({
  ProfileImage,
  EditProfileData,
  ProfileImageChanged,
  ProfileImageLoading,
}) {
  let history = useHistory();

  const [profileImg, setProfileImg] = useState(null);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });

  const [info, setInfo] = useState(null);

  const OnSubmit = () => {
    EditProfileData({ info, banner, history });
    // if(imagechange){
    //   ChangeProfileImage({banner , history})
    // }
    // if(info != null){
    //   EditProfileData({info , history})
    // }
  };

  const OnCancel = () => {
    setInfo(null);
    history.goBack();
  };

  const fileChange = (e) => {
    if (e.target.files.length) {
      setimagechange(true);
      setbanner({
        imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <div id="editprofile">
      <Navbar />
      <Container>
        <div className="outerwrapper">
          <Row>
            <Col lg={8}>
              <EditProfileForm SetInfo={setInfo} />
            </Col>
            <Col lg={4}>
              <div className="titlewrapper">
                <span className="title">Profile Image</span>
              </div>
              <div className="aligncenterimg">
                {ProfileImageLoading ? <Loader /> : null}
                {!imagechange ? (
                  <img
                    src={ProfileImage ? ProfileImage?.md : profile}
                    className="profileimg"
                  />
                ) : null}
                {imagechange ? (
                  <img src={banner.imagePreviewUrl} className="profileimg" />
                ) : null}
                <div className="choosefile">
                  <input type="file" accept="image/*" onChange={fileChange} />
                </div>
              </div>
              <div className="buttonstyle">
                <div className="buttonspace">
                  <CustomButton
                    type="button"
                    name="Submit"
                    filled={true}
                    primary={true}
                    classname="buttonmain"
                    onClick={OnSubmit}
                  />
                </div>
                <div className="buttonspace">
                  <CustomButton
                    type="button"
                    name="Cancel"
                    filled={true}
                    primary={true}
                    classname="nonfilledbtn"
                    onClick={OnCancel}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <MainFooter />
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const ProfileImage = _.get(user, "userProfile.profileImage", null);
  const ProfileImageChanged = _.get(user, "changeProfileImage", null);
  const ProfileImageLoading = _.get(user, "changeProfileImageLoading", false);
  return { ProfileImage, ProfileImage, ProfileImageLoading };
};

const mapDispatchToProps = (dispatch) => ({
  //  ChangeProfileImage : (data) => dispatch({type : "ChangeProfileImageCalled" , payload : data}),
  EditProfileData: (data) =>
    dispatch({ type: "EditProfileDataCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
