import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Navbar from "@components/navbar/Navbar";
import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import { resendEmailVerificationStateReset } from "@store/userredux";
import SuccessPopup from "@components/success-popup/SuccessPopup";
import logo from "@images/logo.svg";
import "@features/user-verification/UserVerificationStyles.scss";

function UserVerification({
  resendEmailVerificationLoading,
  resendEmailVerificationSuc,
  resendEmailVerificationError,
  resendEmailVerification,
  resendEmailVerificationReset,
}) {
  const [resendPopup, setResendPopup] = useState(false);
  useEffect(() => {
    return () => {
      resendEmailVerificationReset();
    };
  }, []);

  useEffect(() => {
    if (resendEmailVerificationError !== undefined) {
      if (alert(`${resendEmailVerificationError}`)) {
        resendEmailVerificationReset();
      } else {
        resendEmailVerificationReset();
      }
    }
  }, [resendEmailVerificationError]);

  useEffect(() => {
    if (
      resendEmailVerificationSuc !== undefined &&
      resendEmailVerificationSuc === true
    ) {
      setResendPopup(true);
    }
  }, [resendEmailVerificationSuc]);

  return (
    <div id="userverification">
      <Navbar />
      {resendEmailVerificationLoading && <Loader />}
      <Container>
        <img src={logo} className="brandlogo" />

        <div className="msg">
          <span>
            Your Email is not verified. Kindly click the link sent to your email
            ID to verify your account.
          </span>
        </div>
        <div className="resend">
          <a href="#" onClick={resendEmailVerification}>
            {" "}
            <span>Resend verification mail</span>
          </a>
        </div>
        <div className="msg">
          <span>Kindly verify your email to activate your account.</span>
        </div>
        {/* { */}

        <SuccessPopup
          show={resendPopup}
          setShowModal={setResendPopup}
          message={
            "Verification email is sent successfully. Please check your email"
          }
        />
        {/* </div> */}
        {/* )} */}

        {/* <div className="buttonstyle">
          <CustomButton
            type="button"
            name="Login"
            filled={true}
            primary={true}
            classname="login-btn"
          />
        </div> */}
      </Container>
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const resendEmailVerificationLoading = _.get(
    user,
    "resendEmailVerificationLoading",
    false
  );
  const resendEmailVerificationSuc = _.get(
    user,
    "resendEmailVerificationSuc",
    false
  );
  const resendEmailVerificationError = _.get(
    user,
    "resendEmailVerificationError",
    undefined
  );
  return {
    resendEmailVerificationLoading,
    resendEmailVerificationSuc,
    resendEmailVerificationError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resendEmailVerification: () =>
    dispatch({ type: "resendEmailVerificationCalled" }),
  resendEmailVerificationReset: () =>
    dispatch(resendEmailVerificationStateReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification);
