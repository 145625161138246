import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { clearPasswordError } from "@store/userredux";
import _ from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/Navbar";
import SuccessPopup from "@components/success-popup/SuccessPopup";
import logo from "@images/logo.svg";
import "@features/change-password/ChangePasswordStyles.scss";

function ChangePassword({
  ChangePassword,
  ChangePasswordLoading,
  ChangePasswordComplete,
  ChangePasswordError,
  ClearPassowrdError,
}) {
  let history = useHistory();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showeye, setshoweye] = useState(true);
  const [showeye1, setshoweye1] = useState(true);
  const [showeye2, setshoweye2] = useState(true);

  const [successPopUp, setSuccessPopUp] = useState(false);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    ClearPassowrdError();
    setSuccessPopUp(false);
  }, []);

  const OnSubmit = () => {
    if (!oldPassword) {
      setShowError(true);
      setError("Please fill out all the mandatory fields");
    } else if (!password) {
      setShowError(true);
      setError("please fill out all the mandatory field");
    } else if (!password2) {
      setShowError(true);
      setError("please fill out all the mandatory field");
    } else if (password != password2) {
      setShowError(true);
      setError("Password Should Match");
    } else if (password.length < 8) {
      setShowError(true);
      setError("Password must be longer than 8 character");
    } else if (password == password2 && password.length >= 8) {
      let data = {
        currentpass: oldPassword,
        password: password,
        onSuccess: () => setSuccessPopUp(true),
      };
      ChangePassword({ data });

      setOldPassword("");
      setPassword("");
      setPassword2("");
    }
  };

  return (
    <div id="changepassword">
      <NavBar />
      <Container>
        {ChangePasswordLoading && <Loader />}
        {/* {_.isEmpty(ChangePasswordError) && successPopUp ? ( */}
        <SuccessPopup
          show={successPopUp}
          setShowModal={setSuccessPopUp}
          message={"Password Changed Successfully"}
          scope={"ChangePassword"}
        />
        {/* ): null} */}
        <div className="form-div">
          <Row>
            <Col lg={12}>
              <Card className="login-card">
                <span className="heading">Change Password</span>
                <img src={logo} className="brandlogo" />
                <Card.Body>
                  <Form>
                    <FormGroup className="form-input">
                      <label>Current Password</label>
                      <InputField
                        type={showeye ? "password" : "text"}
                        classname="password-control"
                        value={oldPassword}
                        onChange={(event) => {
                          ClearPassowrdError(), setShowError(false);
                          setError(null);
                          setOldPassword(event.target.value);
                        }}
                        placeholder="Current Password"
                        eye
                        setshow={() => setshoweye(!showeye)}
                        showeye={showeye}
                      />
                    </FormGroup>{" "}
                    <FormGroup className="form-input">
                      <label>New Password</label>
                      <InputField
                        type={showeye1 ? "password" : "text"}
                        classname="password-control"
                        value={password}
                        onChange={(event) => {
                          ClearPassowrdError(), setShowError(false);
                          setError(null);
                          setPassword(event.target.value);
                        }}
                        placeholder="New Password"
                        eye
                        setshow={() => setshoweye1(!showeye1)}
                        showeye={showeye1}
                      />
                    </FormGroup>
                    <FormGroup className="form-input">
                      <label>Confirm Password</label>
                      <InputField
                        type={showeye2 ? "password" : "text"}
                        classname="password-control"
                        value={password2}
                        onFocus={() => {
                          ClearPassowrdError(), setShowError(false);
                          setError(null);
                        }}
                        onChange={(event) => {
                          setPassword2(event.target.value);
                        }}
                        placeholder="Confirm Password"
                        eye
                        setshow={() => setshoweye2(!showeye2)}
                        showeye={showeye2}
                      />
                      {showError || ChangePasswordError ? (
                        <p className="errorText">
                          {ChangePasswordError
                            ? "The Password you entered is incorrect"
                            : error}
                        </p>
                      ) : null}
                    </FormGroup>
                    <Row>
                      <CustomButton
                        type="button"
                        name="Submit"
                        filled={true}
                        primary={true}
                        classname="login-btn"
                        onClick={OnSubmit}
                      />
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const ChangePasswordLoading = _.get(user, "changePasswordLoading", false);
  const ChangePasswordComplete = _.get(user, "changePasswordComplete", false);
  const ChangePasswordError = _.get(user, "changePasswordError", null);
  return { ChangePasswordLoading, ChangePasswordComplete, ChangePasswordError };
};

const mapDispatchToProps = (dispatch) => ({
  ChangePassword: (data) =>
    dispatch({ type: "ChangePasswordCalled", payload: data }),
  ClearPassowrdError: (data) => dispatch(clearPasswordError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
