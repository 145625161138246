import React from 'react';
import { Col, Container, Modal, Row } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/confirmation-popup/ConfirmationPopupStyle.scss";

const ConfirmationPopup = ({ 
  show, 
  setShowModal, 
  setShowSuccess,
 
}) => {

  return (
    <Modal
      className="ConfirmationPopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">Do you want to join as a operator?</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Yes"
                  filled={true}
                  primary={true}
                  onClick={() => {
                   setShowSuccess();
                   setShowModal(false)
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="No"
                  filled={true}
                  primary={true}
                  onClick={() => {
                   setShowModal(false)
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(ConfirmationPopup);