import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Col, Container, Form, Row, Carousel } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import CustomButton from "@components/custom-button/CustomButton";
import MainFooter from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import Navbar from "@components/navbar/Navbar";
import { ReactComponent as NoData } from "@images/nodata.svg";
import calender from "@images/calender.svg";
import clock from "@images/clock.svg";
import locations from "@images/location.svg";
import insta from "@images/insta.svg";
import fb from "@images/fb.svg";
import twittericon from "@images/twittericon.svg";
import whatsapp from "@images/whatsapp.svg";
import banner from "@images/banner.png";
import "@features/tour-details/TourDetailsStyles.scss";
import * as _ from "lodash";

import { resetBookingVilla } from "@store/eventredux";
import { clearBackendError } from "@store/eventredux";
import { useLocation } from "react-router-dom";

import moment from "moment";
import {
  FacebookShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

function TourDetails({
  GetEventDetail,
  eventDetails,
  eventLoading,
  faqs,
  faqsLoading,
  faqsError,
  GetFaqs,
  userLoggedIn,
  eventError,
}) {
  let history = useHistory();
  var para;
  const location2 = useLocation();
  // console.log("loc", location2.pathname);
  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };
  // const [Description, setDescription] = useState();
  // const [description, setdescription] = useState();

  useEffect(() => {
    console.log("event error", eventError);
  }, [eventError]);
  const [counter, setCounter] = useState(1);
  const [counter2, setCounter2] = useState(1);
  const [arrivalTime, setArrivalTime] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [finalCheckInPeriod, setFinalCheckInPeriod] = useState("");
  const [finalCheckOutPeriod, setFinalCheckoutPeriod] = useState("");
  let { id } = useParams();
  // const params = new URLSearchParams(window.location.search);

  const [readMore, setReadMore] = useState(false);
  const [Description, setDescription] = useState();
  const [backendError, setBackendError] = useState();
  const [dateExceeded, setDateExceeded] = useState(0);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [slider, setSlider] = useState();
  const [myRedirect, setMyRedirect] = useState("");
  const [eveId, setEveId] = useState();
  useEffect(() => {
    console.log("id", id);
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);
  useEffect(() => {
    //    console.log("para", para);
    if (para !== undefined) {
      // GetEventDetail({ urlSlug: true, slugVal: params.get("event") });
      "para", para;
      setMyRedirect(para);
    }
  }, [para]);
  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({
        urlSlug: true,
        slugVal: decodeURIComponent(myRedirect),
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        console.log("called with event id");
        GetEventDetail({ urlSlug: false, eventId: id });
      }
    }
  }, [myRedirect, id]);
  useEffect(() => {
    // GetEventDetail({ eventId: id });
    handleBackendCallsBasedOnIdChange();
  }, [id, eveId]);
  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      GetFaqs({ eventId: eveId });
      // eventItinerariesCalled({ eventId: eveId });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        console.log("called");
        GetFaqs({ eventId: id });
        // eventItinerariesCalled({ eventId: eveId });
      }
    }
  };

  useEffect(() => {
    if (eventDetails !== undefined) {
      // console.log("ev", eventDetails);
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      setStartDate(eventDetails.startDate);
      setEveId(eventDetails.id);
      setEndDate(eventDetails.endDate);
      if (
        eventDetails !== undefined &&
        eventDetails.eventImages !== undefined &&
        eventDetails.eventImages.length > 0
      ) {
        setSlider(eventDetails.eventImages);
      }
      setdescription(eventDetails.description);
      // checkExceedDataTime(eventDetails.endDate);
      // setTicketSaleStartDate(eventDetails.ticketSaleStartDate);
      // setTicketSaleEndDate(eventDetails.ticketSaleEndDate);
    }
  }, [eventDetails, id]);

  function checkExceedDataTime(dt) {
    let exceedLimit = 0;

    const dateLimit = moment(dt, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const now = moment();
    if (dateLimit.isValid() && now.isAfter(dateLimit)) {
      exceedLimit++;
      setDateExceeded(exceedLimit);
    }
  }
  const modifyDate = (date) => {
    const d = new Date(date).toLocaleDateString();
    // console.log(d);
  };
  const hasWindow = typeof window !== "undefined";

  const eventData = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: eventDetails?.title,
    image: eventDetails?.banner,
    description: `${eventDetails?.title} at ${eventDetails?.location}. ${
      eventDetails?.description?.replace(/\s+/g, " ").trim().substr(0, 120) +
      (eventDetails?.description?.length > 120 ? "..." : "")
    }`,
    location: {
      "@type": "Place",
      name: eventDetails?.location,
      address: {
        "@type": "PostalAddress",
        streetAddress: eventDetails?.location,
        addressLocality: eventDetails?.destination,
        addressRegion: "Goa",
        addressCountry: "India",
      },
    },
    startDate: eventDetails?.startDate,
    endDate: eventDetails?.endDate,
    eventStatus:
      eventDetails?.status === "active" ? "EventScheduled" : "EventCancelled",
    eventAttendanceMode: "OfflineEventAttendanceMode",
    organizer: {
      "@type": "Organization",
      name: eventDetails?.user?.name,
      email: eventDetails?.user?.email,
      // logo: "https://followgoa.com/images/logo.png",
    },
  };

  return (
    <div id="tourdetails">
      <Helmet>
        <title>{`${eventDetails?.title} | Followgoa`}</title>
        <script type="application/ld+json">{JSON.stringify(eventData)}</script>
      </Helmet>
      <Navbar />
      {eventLoading && <Loader />}
      {eventError === undefined ? (
        <div className="topbannerwrapper">
          {eventDetails !== undefined && eventDetails.banner !== null && (
            <img src={banner} className="topbanner" />
          )}
        </div>
      ) : (
        <div className="topbannerwrapper">
          <p>Invalid URL</p>
        </div>
      )}
      {eventError === undefined && (
        <Container>
          <Row>
            {eventError === undefined && (
              <Col lg={12}>
                <div className="outerwrapper">
                  <div className="titleblock">
                    <div className="titlewrapper">
                      <div className="title">
                        <span>
                          {eventDetails !== undefined &&
                            title !== undefined &&
                            title}
                        </span>
                      </div>

                      <div className="referencetext">
                        {eventDetails !== undefined &&
                          eventDetails.tourRef !== null && (
                            <span>Reference ID: {tourRef}</span>
                          )}
                      </div>
                    </div>

                    <div className="buttonwrapper">
                      <CustomButton
                        className="all-events-btn1"
                        // name={"Book"}
                        name={
                          eventDetails !== undefined && eventDetails.amount > 0
                            ? "Book"
                            : "RSVP HERE"
                        }
                        onClick={() => {
                          if (eventDetails.urlSlug === null) {
                            history.push(
                              `/booktour/${
                                eventDetails !== undefined && eventDetails.id
                              }`
                            );
                          } else {
                            history.push(
                              `/booktour/${
                                eventDetails !== undefined &&
                                encodeURIComponent(eventDetails.urlSlug)
                              }`
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="event-block">
                    <div className="leftalign">
                      {eventDetails !== undefined && location !== undefined && (
                        <span className="location">
                          <img src={locations} className="leftsideimg" />{" "}
                          {location}
                        </span>
                      )}
                    </div>
                    {/* <div className="leftalign">
                  <span className="viewlocation">View Location</span>
                </div> */}
                    {eventDetails !== undefined &&
                      eventDetails.googleMapLink !== null && (
                        <div className="leftalign">
                          <a
                            className="viewlocation"
                            href={`${eventDetails.googleMapLink}`}
                            target="_blank"
                          >
                            View Location
                          </a>
                        </div>
                      )}
                    {/* <div className="leftalign">
                      <img src={calender} className="leftsideimg" />
                      <span className="locationtext">
                        {moment(startDate).format("dddd, MMMM Do YYYY")}{" "}
                      </span>
                      <img src={clock} className="smallicon" />
                      <span className="locationtext">
                        {" "}
                        {moment(startDate).format("h:mm A")}{" "}
                      </span>
                      <span className="details-text-to">To</span>
                      <img src={calender} className="smallicon" />
                      <span className="locationtext">
                        {moment(endDate).format("dddd, MMMM Do YYYY")}{" "}
                      </span>
                      <img src={clock} className="smallicon" />
                      <span className="locationtext">
                        {" "}
                        {moment(endDate).format("h:mm A")}{" "}
                      </span>
                    </div> */}
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {eventError === undefined && (
            <Row className="mb-5">
              <Col lg={12}>
                <div className="outerwrapper">
                  <h2 className="about ">About</h2>
                  <span className="aboutdes">
                    {Description === undefined
                      ? eventDetails !== undefined &&
                        eventDetails.description !== undefined
                        ? String(description).slice(0, 370)
                        : ""
                      : Description}
                  </span>{" "}
                  {String(description).length > 370 ? (
                    readMore === false ? (
                      <span
                        className="readmore"
                        onClick={() => {
                          setReadMore(true);
                          setDescription(
                            String(description).slice(0, description.length)
                          );
                        }}
                      >
                        Read More
                      </span>
                    ) : (
                      <span
                        className="readmore"
                        onClick={() => {
                          setReadMore(false);
                          setDescription(String(description).slice(0, 370));
                        }}
                      >
                        Show Less
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                  <Accordion flush>
                    {/* {eventDetails !== undefined && eventDetails.type === "event" && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="about">
                        Event Highlights
                      </Accordion.Header>
                      <Accordion.Body>
                        {eventItineraries !== undefined &&
                        eventItineraries.length !== 0 ? (
                          eventItineraries.map((itm) => (
                            <div className="highlight">
                              <h4 className="days">
                                Day {itm.day !== undefined ? itm.day : ""}
                              </h4>
                              <h5 className="eventtitle">
                                {itm.title !== undefined ? itm.title : ""}
                              </h5>
                            
                              <Row className="d-flex ">
                                <Col lg={12}>
                                  <p className="toptitle"> Event Description</p>
                                  <p className="eventcontent">
                                    {itm?.description !== undefined
                                      ? itm.description
                                      : ""}
                                  </p>
                                </Col>
                                <Col lg={12}>
                                  {itm.accommodation !== undefined &&
                                    itm.accommodation !== "" && (
                                      <p className="toptitle">
                                        {" "}
                                        Accommodation Description
                                      </p>
                                    )}
                                  <p className="eventcontent">
                                    {itm?.accommodation !== undefined
                                      ? itm.accommodation
                                      : ""}
                                  </p>
                                </Col>
                                <Col lg={12}>
                                  {itm.food !== undefined &&
                                    itm.food !== "" && (
                                      <p className="toptitle">
                                        Food Description
                                      </p>
                                    )}
                                  <h6 className="eventcontent">
                                    {itm.food !== undefined ? itm.food : ""}
                                  </h6>
                                </Col>
                              </Row>

                              <h6 className="eventcontent">
                                {itm.location !== undefined &&
                                  itm.location !== "" && (
                                    <img
                                      src={LocationIcon}
                                      className="leftsideimg"
                                    />
                                  )}{" "}
                                {itm.location !== undefined ? itm.location : ""}
                              </h6>
                            </div>
                          ))
                        ) : (
                          <div className="nothing-found">
                            <NoData className="no-data" />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )} */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        className="about"
                        onClick={() => {
                          if (myRedirect !== "" && eveId !== undefined) {
                            GetFaqs({ eventId: eveId });
                            // eventItinerariesCalled({ eventId: eveId });
                          } else {
                            if (id !== undefined) {
                              GetFaqs({ eventId: id });
                              // eventItinerariesCalled({ eventId: eveId });
                            }
                          }
                        }}
                      >
                        FAQs
                      </Accordion.Header>
                      <Accordion.Body>
                        {faqsLoading && (
                          <Spinner
                            className=""
                            animation="border"
                            variant="dark"
                            role="status"
                            aria-hidden="true"
                          ></Spinner>
                        )}
                        <ol>
                          {faqs !== undefined && faqs.length > 0 ? (
                            faqs.map((item) => {
                              return (
                                <div className="leftalign mb-3">
                                  <li>
                                    <h3 className="question">
                                      {item.question}
                                    </h3>
                                  </li>
                                  <p className="answer">{item.answer}</p>
                                </div>
                              );
                            })
                          ) : (
                            <Row>
                              <Col lg={12}>
                                <div className="nothing-found">
                                  {hasWindow && (
                                    <NoData className="no-data faq-no-data" />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
              {eventDetails !== undefined &&
                eventDetails.eventImages !== undefined &&
                eventDetails.eventImages.length > 2 && (
                  <Col lg={7}>
                    <div className="slider-div">
                      <div className="title">
                        <span>Gallery</span>
                      </div>
                      <Carousel interval={null} indicators={false}>
                        {eventDetails.eventImages.map((item) => (
                          <Carousel.Item>
                            <div className="slider">
                              <img src={item.lg} className="slider-img" />
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  </Col>
                )}
              {eventDetails !== undefined &&
                eventDetails.eventImages !== undefined &&
                eventDetails.eventImages.length > 0 &&
                eventDetails.eventImages.length < 1 && (
                  <Col lg={7}>
                    <div className="slider-div">
                      <div className="title">
                        <span>Gallery</span>
                      </div>
                      {/* <Carousel interval={null} indicators={false}> */}
                      {eventDetails.eventImages.map((item) => (
                        // <Carousel.Item>
                        <div className="slider">
                          <img src={item.lg} className="slider-img" />
                        </div>
                        // </Carousel.Item>
                      ))}
                      {/* </Carousel> */}
                    </div>
                  </Col>
                )}

              <Col lg={5}>
                <div
                  className={
                    eventDetails.eventImages !== undefined &&
                    eventDetails.eventImages.length !== 0
                      ? "outerwrappershare"
                      : "outerwrappershare outersharediv"
                  }
                >
                  <div className="share-event">
                    <span>Share this Event</span>
                  </div>
                  <div className="d-flex">
                    <WhatsappShareButton
                      url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                      hashtag="#programing joke"
                    >
                      <img src={whatsapp} className="sharewhatsappimg" />
                    </WhatsappShareButton>

                    <FacebookShareButton
                      url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                      hashtag="#programing joke"
                    >
                      <img src={fb} className="shareimg" />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                      hashtag="#programing joke"
                    >
                      <img src={twittericon} className="shareimg" />
                    </TwitterShareButton>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      )}
      <div className="footer-div">
        <MainFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user = {}, event = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", []);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const faqsLoading = _.get(event, "faqsLoading", false);
  const faqs = _.get(event, "faqs", []);
  const faqsError = _.get(event, "faqsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const eventItinerariesLoading = _.get(
    event,
    "eventItinerariesLoading",
    false
  );
  const eventItineraries = _.get(event, "eventItineraries", undefined);
  const eventItinerariesError = _.get(
    event,
    "eventItinerariesError",
    undefined
  );
  const villaDetLoading = _.get(event, "villaDetailLoading", false);
  const villaDet = _.get(event, "villaDetails", undefined);
  const villaError = _.get(event, "villaDetailsError", undefined);
  const villaFaqLoading = _.get(event, "villaFacilityLoading", false);
  const villaFaq = _.get(event, "villaFacility", undefined);
  const villaFaqError = _.get(event, "villaFacilityError", undefined);
  const villaBookingLoading = _.get(event, "villaBookLoading", false);
  const villaBookingSucc = _.get(event, "villaBooking", undefined);
  const villaBookError = _.get(event, "villaBookingError", undefined);
  const userDet = _.get(user, "userDetails", undefined);

  return {
    eventLoading,
    eventDetails,
    eventError,
    faqsLoading,
    faqs,
    userDet,
    faqsError,
    userLoggedIn,
    eventItinerariesLoading,
    eventItineraries,
    eventItinerariesError,
    villaDetLoading,
    villaDet,
    villaError,
    villaFaqLoading,
    villaFaq,
    villaFaqError,
    villaBookingLoading,
    villaBookingSucc,
    villaBookError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  GetFaqs: (data) => dispatch({ type: "getFaqsCalled", payload: data }),
  clearBackendErrorAsync: () => dispatch(clearBackendError()),
  eventItinerariesCalled: (data) =>
    dispatch({ type: "eventItinerariesCalled", payload: data }),
  GetVillaDetails: (data) =>
    dispatch({ type: "villaDetailsSagaCalled", payload: data }),
  GetVillaFacilities: (data) =>
    dispatch({ type: "villaFacilitySagaCalled", payload: data }),
  BookVillaAsync: (data) =>
    dispatch({ type: "bookVillasagaCalled", payload: data }),
  refreshBookingState: () => dispatch(resetBookingVilla()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourDetails);
