import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import NavBar from "@components/navbar/Navbar";
import SearchFilter from "@components/search-filter/SearchFilter";
import SearchPageCards from "@components/searchpage-cards/SearchPageCards";
import Footer from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import { clearSearchError } from "@store/userredux";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import "@features/search-properties/SearchPagePropertiesStyle.scss";

import * as _ from "lodash";

function SearchLuxuryCar({
  searchLoading,
  resultsOfSearch,
  searhErrorFromBackend,
  callSearchSaga,
  countLoading,
  counts,
  countError,
  getCountAsync,
  searchTitleAsync,
}) {
  const location = useLocation();
  const [eventType, setEventType] = useState("");
  const [skip, setskip] = useState(0);
  const [limit, setlimit] = useState(12);
  const [pages, setpages] = useState(0);

  const history = useHistory();
  const [destinationType, setDestinationType] = useState();
  const handleSearch = (skipv) => {
    const query = new URLSearchParams(location.search);
    let sname = query.get("name");
    let strending = query.get("trending");
    let slocation = query.get("location");
    let smax = query.get("max");
    let smin = query.get("min");

    if (sname == null) sname = "";
    if (strending == null) strending = "";
    if (slocation == null) slocation = "";
    if (scategory == null) scategory = "";

    if (smax == null) smax = "";
    if (smin == null) smin = "";

    let data = {
      ["name"]: sname,
      ["trending"]: strending || "",
      ["location"]: slocation,
      ["min"]: smin,
      ["max"]: smax,
      ["limit"]: limit,
      ["skip"]: skipv,
    };

    const params = new URLSearchParams(data);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };
  return (
    <>
      <NavBar />
      <div id="SearchPageProperties">
        <Container>
          {searchLoading && <Loader />}
          <div className="search-section">
            <SearchFilter
              typeofEvent={eventType}
              typeOfDestination={destinationType}
              titleValue={searchTitleAsync}
              type={"luxuryCar"}
            />
          </div>

          <div className="cards-section">
            <SearchPageCards dataItem={resultsOfSearch} />
          </div>
          {counts !== undefined && counts.count > 12 && (
            <div className="paginationsection mb-5">
              <span className="noofpagetxt">
                Showing {pages + 1} From {Math.ceil(counts.count / limit)}
              </span>
              <div className="paginationallignment">
                <ReactPaginate
                  previousLabel={<PreviousIcon className="paginationicon" />}
                  nextLabel={<NextIcon className="paginationicon" />}
                  breakLabel={"..."}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  breakClassName={"break-me"}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageCount={counts.count / limit}
                  forcePage={pages}
                  onPageChange={(e) => {
                    setskip(e.selected * limit);
                    setpages(e.selected);
                    handleSearch(e.selected * limit);
                  }}
                />
              </div>
            </div>
          )}
          <div className="error-text">
            {searhErrorFromBackend !== undefined && searhErrorFromBackend}
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}
const mapStateToProps = ({ user = {}, event = {}, app = {} }) => {
  const searchLoading = _.get(user, "searchLoading", false);
  const resultsOfSearch = _.get(user, "searchResults", []);
  const searhErrorFromBackend = _.get(user, "searchError", undefined);
  const countLoading = _.get(event, "getCountForSearchLoading", false);
  const counts = _.get(event, "getCount", undefined);
  const countError = _.get(event, "getCountForSearchError", undefined);
  const searchTitleAsync = _.get(event, "titleForSearch", undefined);
  const searchLocationAsync = _.get(event, "locationForSearch", undefined);
  const searchCategoryAsync = _.get(event, "categoryForSearch", undefined);
  const locationSearchTrial = _.get(event, "triedForLocation", false);
  const categorySearchTrial = _.get(event, "treidForCategory", false);
  const eventCallCheck = _.get(event, "callFromEventNav", false);
  const eventCategorties = _.get(app, "getEventCategoriesSuccess", undefined);
  const allLocations = _.get(app, "getLocationsSuccess", undefined);

  return {
    searchLoading,
    resultsOfSearch,
    countLoading,
    counts,
    countError,
    searhErrorFromBackend,
    searchTitleAsync,
    searchLocationAsync,
    searchCategoryAsync,
    locationSearchTrial,
    categorySearchTrial,
    eventCallCheck,
    eventCategorties,
    allLocations,
  };
};
const mapDispatchToProps = (dispatch) => ({
  callSearchSaga: (data) =>
    dispatch({ type: "searchPageSagaCalled", payload: data }),
  getCountAsync: (data) =>
    dispatch({ type: "getCountForSearchSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchLuxuryCar);
