import React from "react";
import Navbar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import { ReactComponent as Marker1 } from "@images/marker1.svg";
import { ReactComponent as Marker2 } from "@images/marker2.svg";
import "@features/about-us/AboutUsStyle.scss";

function AboutUs() {
  return (
    <div id="about-us">
      <Navbar />
      <div className="container footer-con">
        <h2>About Us</h2>

        <h2>What is Followgoa?</h2>
        <p>
          Followgoa, an Online Tourism Event Marketplace Company, makes it easy
          to find and book something you'll love to do. With access to
          highquality adventure experiences, there's always something new to
          discover, both near and far from home. We list the top adventure
          operators, so you don't have to open multiple tabs on Google, or spend
          hours doing your research. We're also totally transparent about the
          operators you're traveling with, so you can check out their profiles,
          see how much experience they have, and decide whom you want to travel
          with.
        </p>
        <p>
          Our awesome search filters help you plan your trip, whether you know
          exactly what you want to do, or if you're just browsing. You can
          choose an activity, destination, date, or a combination of any of the
          above, and find your awesome adventure.
        </p>
        <p>
          It's never too late to make any day extraordinary. Plan ahead, or
          decide that morning. Change your dates. Or change your mind entirely!
        </p>

        <p>
          We also know that your time off is precious. So we ruthlessly focus on
          quality, and we specialize in experiences so incredible, you'll want
          to tell your friends. Spend less time searching, and more time making
          memories. Discover story-worthy adventure experiences, anytime,
          anywhere.
        </p>

        <h2>Why choose Followgoa?</h2>
        <p>
          We’re here to make it easy for travellers to research and book their
          adventure.
        </p>

        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Research
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>
                Browse through a wide range of trips and experience levels, and
                talk directly to operators.
              </p>
            </li>
          </ul>
        </ul>

        <ul className="list">
          <li>
            <Marker1 className="marker1" />
            Decide
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>
                Access complete information about the trip, destination, and
                operator.
              </p>
            </li>
          </ul>
        </ul>

        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Choose
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>
                {" "}
                Make decisions based on the activity you want OR the dates you
                want to travel.
              </p>
            </li>
          </ul>
        </ul>

        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Pay
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>Make online payments safely and in real time.</p>
            </li>
          </ul>
        </ul>

        <h2>Are you a tour operator?</h2>
        <ul className="list">
          <li>
            <Marker1 className="marker1" /> List
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>Sign up with us for free</p>
            </li>
          </ul>
        </ul>

        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Sell
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>Get real time online sales for your trips.</p>
            </li>
          </ul>
        </ul>

        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Manage
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>
                Access efficient dashboards, calendars and booking systems to
                make your life easier.
              </p>
            </li>
          </ul>
        </ul>
        <ul className="list">
          <li>
            <Marker1 className="marker1" /> Reach out
          </li>
          <ul className="sublist">
            <li>
              <Marker2 className="marker2" />
              <p>
                Get unlimited customer leads, for free. Talk to a wider audience
                and showcase your trips.
              </p>
            </li>
          </ul>
        </ul>

        <p>Button to list with us Take user to the Signup as Operator Screen</p>

        <h2>Connect with Us!</h2>
        <p>
          Want to know more? Join us on{" "}
          <a
            className="alinks"
            href="https://www.facebook.com/followgoa"
            target="_blank"
          >
            Facebook
          </a>{" "}
          or follow us on{" "}
          <a
            className="alinks"
            href="https://www.instagram.com/follow_goa/"
            target="_blank"
          >
            Instagram
          </a>{" "}
        </p>
        <p>
          Press inquiries contact{" "}
          <a href="mailto:booking@followgoa.com" className="alinks">
            booking@followgoa.com
          </a>
        </p>
      </div>
      <MainFooter />
    </div>
  );
}

export default AboutUs;
