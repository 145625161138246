import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Helmet } from "react-helmet";
import moment from "moment";
import ExploreExperiences from "@components/explore-experiences/ExploreExperiences";
import MainFooter from "@components/footer/MainFooter";
import HomepageCards from "@components/homepage-cards/HomepageCards";
import LocationCards from "@components/location-cards/LocationCards";
import Navbar from "@components/navbar/Navbar";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import banner from "@images/banner.png";
import img1 from "@images/banner1.png";
import img2 from "@images/banner2.png";
import img3 from "@images/banner3.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "@features/home-page/HomePageStyle.scss";
import { resetAppReduxOnMount } from "@store/appredux";
import { resetUserReduxOnMount } from "@store/userredux";
import { resetEventReduxOnMount } from "@store/eventredux";
import { useHistory } from "react-router-dom";
const ReactOwlCarousel = loadable(() => import("react-owl-carousel"), {
  ssr: false,
});

function HomePage({
  getAllEventCategories,
  getLocations,
  allLocations,
  eventCategoryLoading,
  eventCategorties,
  eventCategoryFail,
  getAllEventsForCategories,
  getAllEvents,
  getAllCategoryEventsLoading,
  locationsLoading,
  GetAllBannerImagesAsync,
  bannerImages,
  bannerLoading,
  bannerError,
  resetAppOnMount,
  resetEventOnMount,
  resetUserOnMount,
}) {
  const [allLoc, setAllLoc] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [skip, setskip] = useState(0);
  const [limit, setLimit] = useState(10);
  const history = useHistory();
  const hasWindow = true;

  useEffect(() => {
    resetAppOnMount();
    resetEventOnMount();
    resetUserOnMount();
    getLocations();
    getAllEventCategories();
    GetAllBannerImagesAsync();
    getAllEventsForCategories({ limit, skip });
  }, []);

  useEffect(() => {
    if (allLocations !== undefined) {
      setAllLoc(allLocations);
    }
  }, [allLocations]);

  // useEffect(() => {
  //   console.log("event cateogry", eventCategories);
  //   if(eventCategories !== undefined && eventCategories.length > 0){
  //     for(let i = 0  ; i < eventCategories.length ; i++){
  //       let cardTypeMapper = {
  //         `"${eventCategories[i].title}"` : `"cardtype${eventCategories[i].id}"`,
  //       }
  //     }
  //   }
  // }, [eventCategories]);

  let cardTypeMapper2 = {};
  eventCategories !== undefined &&
    eventCategories.forEach((itm, index) => {
      let modifiedIndex = index + 1;
      cardTypeMapper2[itm.title] = `cardtype${modifiedIndex}`;
    });

  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: false,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      991: {
        items: 3,
      },
    },
  };

  return (
    <div id="homepage">
      <Helmet>
        <title>Followgoa</title>
      </Helmet>
      <Navbar />
      <div className="banner-section">
        {/* <img src={banner} className="bannerbgimg" /> */}
        {(getAllCategoryEventsLoading ||
          locationsLoading ||
          eventCategoryLoading ||
          bannerLoading) && <Loader />}
        <div className="slider-div">
          {hasWindow && (
            <ReactOwlCarousel className="owl-carousel owl-theme" {...options}>
              {bannerImages !== undefined &&
                bannerImages.length > 0 &&
                bannerImages.map((item, index) => (
                  <img
                    key={`bannerhome${index}`}
                    src={item.imageLink !== null && item.imageLink}
                    className={item.event !== null ? "item" : "itempasted"}
                    alt="slide1"
                    onClick={() => {
                      if (item.redirectUrl !== null) {
                        // console.log("item.event for home", item);
                        window.open(item.redirectUrl);
                      }
                    }}
                  />
                ))}
            </ReactOwlCarousel>
          )}
        </div>
      </div>

      <Container>
        {_.isArray(eventCategories) && (
          <div className="experiences-section">
            <ExploreExperiences categories={eventCategorties} key={"INP"} />
          </div>
        )}
        <div className="cards-section">
          {getAllEvents !== undefined &&
            _.isObject(getAllEvents) &&
            Object.keys(getAllEvents).map((itm, index) => {
              let filteredEvents = getAllEvents[itm].filter((itm2) => {
                if (itm2.type === "villa") {
                  return itm2;
                } else if (itm2.type == "luxuryCar") {
                  return itm2;
                } else {
                  var given = moment(new Date(), "YYYY-MM-DD");
                  var current = moment(itm2.endDate, "YYYY-MM-DD");
                  //Difference in number of days
                  let difTime = moment.duration(current.diff(given)).asDays();

                  if (difTime >= 0) {
                    return itm2;
                  }
                }
              });

              if (filteredEvents.length > 0 && cardTypeMapper2 !== undefined) {
                return (
                  <HomepageCards
                    key={`HMI${index}`}
                    cardType={itm}
                    cName={cardTypeMapper2[itm]}
                    data={getAllEvents[itm]}
                  />
                );
              }
            })}
        </div>
        <div className="location-section">
          <LocationCards locations={allLoc} />
        </div>
      </Container>
      <div className="footer-section">
        <MainFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ app = {} }) => {
  const locationsLoading = _.get(app, "getLocationsLoading", false);
  const allLocations = _.get(app, "getLocationsSuccess", undefined);
  const locationError = _.get(app, "getLocationsFailure", undefined);
  const eventCategoryLoading = _.get(app, "getEventCategoriesLoading", false);
  const eventCategorties = _.get(app, "getEventCategoriesSuccess", []);
  const eventCategoryFail = _.get(app, "getEventCategoriesFailure", undefined);
  const getAllCategoryEventsLoading = _.get(
    app,
    "getAllCategoryEventsLoading",
    false
  );
  const getAllEvents = _.get(app, "getAllCategoryEventsSuccess", []);
  const getAllCategoryEventsFailure = _.get(
    app,
    "getAllCategoryEventsFailure",
    undefined
  );
  const bannerLoading = _.get(app, "getBannerImageLoading", false);
  const bannerImages = _.get(app, "getBannerImages", []);
  const bannerError = _.get(app, "getBannerImageError", undefined);

  return {
    locationsLoading,
    allLocations,
    locationError,
    eventCategoryLoading,
    eventCategorties,
    eventCategoryFail,
    getAllCategoryEventsLoading,
    getAllEvents,
    getAllCategoryEventsFailure,
    bannerLoading,
    bannerImages,
    bannerError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllEventCategories: () => dispatch({ type: "getEventCategorySagaCalled" }),
  getLocations: () => dispatch({ type: "getLocationSagaCalled" }),
  getAllEventsForCategories: (data) =>
    dispatch({ type: "getAllCategorySagaCalled", payload: data }),
  GetAllBannerImagesAsync: () => dispatch({ type: "getBannerImageSagaCalled" }),

  resetUserOnMount: () => dispatch(resetUserReduxOnMount()),
  resetAppOnMount: () => dispatch(resetAppReduxOnMount()),
  resetEventOnMount: () => dispatch(resetEventReduxOnMount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
