import React, { useEffect, useState } from "react";
import { userLogout } from "@store/userredux";
import { Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import {
  clearLoacationTitleForSearch,
  clearCategoryForSearch,
  clearLocationForSearch,
  callFromEventNavTrial,
  clearCallFromEventNav,
} from "@store/eventredux";
import profile from "@images/demo-profile.svg";
import { ReactComponent as Followgoa } from "@images/followgoa.svg";
import "@components/navbar/NavbarStyle.scss";
import * as _ from "lodash";
const NavBar = ({
  signedIn,
  userDetails,
  logout,
  ProfileImage,
  callSearchSaga,
  clearLocationAndTitleAsync,
  clearCategoryAsync,
  clearLocationAsync,
  getCountAsync,

  callFromEventAsync,
}) => {
  let history = useHistory();
  const hasWindow = typeof window !== "undefined";

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [validated, setValidated] = useState(false);
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(new Date());
  const [error, seterror] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [country, setcountry] = useState("");
  const [about, setaboutus] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileImage, setProfile] = useState("");
  const [address, setaddress] = useState("");
  const [countrylist, setcountrylist] = useState(["India", "America"]);
  const [listnew, setnewlist] = useState([""]);
  const [edit, setEdit] = useState(false);
  const [loader, setloader] = useState(true);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });
  const [message, setmessage] = useState("");

  // useEffect(() => {
  //   Api.get(`/users/my-profile`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }).then((res) => {
  //     setloader(false);
  //     if (res.status === 200) {
  //       setemail(res.data.email);
  //       setname(res.data.name);
  //       setmobile(res.data.mobile);
  //       setaboutus(res.data.about);
  //       setcity(res.data.city);
  //       setcountry(res.data.country);
  //       setstate(res.data.state);
  //       console.log("image link", res.data.profileImage);
  //       setProfile(res.data.profileImage);
  //       setaddress(res.data.address);
  //       setdob(moment(res.data.dob).format("YYYY-MM-DD"));
  //       setgender(res.data.gender);
  //       setzipcode(res.data.zipcode);
  //     }
  //   });
  // }, []);
  // const handleSearchPageFromNavbar = ()=>{
  //   let data ={

  //       category:"" ,
  //       destination: "",
  //       title: "",
  //       scope  : true,
  //   }
  //   callSearchSaga(data)
  // }

  return (
    <div className="Navbar">
      {hasWindow && (
        <Navbar className="" expand="lg">
          <Navbar.Brand href="/">
            <div className="brand-logo">
              <Followgoa className="logo-img" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="redirect" activeKey={window.location.pathname}>
              <Nav.Link className="login-btn" href="/">
                <span>Home</span>
              </Nav.Link>
              {userDetails && (
                <Nav.Link className="login-btn" href="/booking-history">
                  <span>Bookings</span>
                </Nav.Link>
              )}
              <Nav.Link
                className="login-btn"
                active={window.location.href.includes("/allevents")}
                onClick={() => {
                  history.push(`/allevents`);
                  clearLocationAndTitleAsync();
                  clearCategoryAsync();
                  clearLocationAsync();
                  callFromEventAsync(true);
                }}
              >
                <span> Events</span>
              </Nav.Link>
              <Nav.Link
                className="login-btn"
                active={window.location.href.includes("/searchPropertiesTours")}
                onClick={() => {
                  history.push(`/searchPropertiesTours`);
                  clearLocationAndTitleAsync();
                  clearCategoryAsync();
                  clearLocationAsync();
                  callFromEventAsync(true);
                }}
              >
                <span> Properties</span>
              </Nav.Link>

              <Nav.Link
              className="login-btn"
              active={window.location.href.includes("/searchLuxuryCars")}
              onClick={() => {
                history.push(`/searchLuxuryCars`);
                clearLocationAndTitleAsync();
                clearCategoryAsync();
                clearLocationAsync();
                callFromEventAsync(true);
              }}
            >
              <span>Luxury car rentals</span>
            </Nav.Link>
              <Nav.Link className="login-btn" href="/Aboutus">
                <span>About</span>
              </Nav.Link>
            </Nav>

            <Nav className="right-section" activeKey={window.location.pathname}>
              {!userDetails && (
                <Nav.Link className="login-btn" href="/login">
                  <a>Login</a>
                </Nav.Link>
              )}

              {!userDetails && (
                <Nav.Link className="login-btn" href="/signup">
                  <a>SignUp</a>
                </Nav.Link>
              )}
              <Nav.Link>
                {userDetails && (
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className="p-0 profile-btn">
                      <h1>
                        {" "}
                        {ProfileImage !== null ? (
                          <img
                            className="profile-img"
                            src={ProfileImage && ProfileImage.md}
                            //src={profile}
                          />
                        ) : (
                          <img
                            className="profile-img"
                            // src={profileImage && profileImage.md}
                            //src={profile}
                            src={profile}
                          />
                        )}
                      </h1>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-lg nav-menu">
                      <Nav.Link eventKey={2} className="login-btn">
                        <Link to={{ pathname: "/myprofile" }} className="dpopt">
                          Profile
                        </Link>
                      </Nav.Link>
                      <Nav.Link
                        eventKey={2}
                        className="login-btn"
                        onClick={() => {
                          logout();
                          localStorage.clear();
                          history.push("/");
                          window.location.reload();
                        }}
                      >
                        <Link className="dpopt">Logout</Link>
                      </Nav.Link>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </div>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const signedIn = _.get(user, "signedIn", undefined);
  const userDetails = _.get(user, "userDetails", undefined);
  const ProfileImage = _.get(user, "userDetails.profileImage", null);

  return {
    signedIn,
    userDetails,
    ProfileImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userLogout()),
  callSearchSaga: (data) =>
    dispatch({ type: "searchPageSagaCalled", payload: data }),
  getCountAsync: (data) =>
    dispatch({ type: "getCountForSearchSagaCalled", payload: data }),
  clearLocationAndTitleAsync: () => dispatch(clearLoacationTitleForSearch()),
  clearCategoryAsync: () => dispatch(clearCategoryForSearch()),
  clearLocationAsync: () => dispatch(clearLocationForSearch()),
  callFromEventAsync: (data) => dispatch(callFromEventNavTrial(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
