import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { persistor, store } from "./store/index";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider, useSelector } from "react-redux";
import ScrollToTop from "@components/scroll-to-top/ScrollToTop";

hydrate(
  <GoogleOAuthProvider clientId="493644900586-hkio4j2g3228k7qu9qqbmhblelm5n3dj.apps.googleusercontent.com">
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter >
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider >,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
