import React, { useState, createContext, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, Form, FormGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetSignInState,
  changeCounterSuccess,
  resetChangeCounter,
} from "@store/userredux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/Navbar";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import logo from "@images/logo.svg";
import "@features/login/Login.scss";
import { GoogleLogin } from "@react-oauth/google";
import GapiContextProvider from "./GoogleContext";
import * as _ from "lodash";

const Login = ({
  signInLoading,
  signInError,
  callLogin,
  resetLoginState,
  callGoogleLoginAsync,
  googleSignInLoading,
  googleSignInError,
  incrementCounter,
  resetCounter,
  myCounterValue,
}) => {
  let history = useHistory();
  const hasWindow = typeof window !== "undefined";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showeye, setshoweye] = useState(true);
  const params = new URLSearchParams(hasWindow && window.location.search);
  const [redirectionParam, setMyRedirection] = useState("");

  // useEffect(() => {
  //   if (signInError !== undefined) {
  //     if (alert(`Error occured -> ${signInError}`)) {
  //       resetLoginState();
  //     } else {
  //       resetLoginState();
  //     }
  //   }
  // }, [signInError]);
  useEffect(() => {
    if (params !== undefined && params.has("name")) {
      setMyRedirection(params.get("name"));
    }
  }, [params]);
  useEffect(() => {
    if (hasWindow) {
      resetLoginState();
      // window.localStorage.removeItem(
      //   "oauth2_ss::http://localhost:3000::1::DEFAULT::_ss_"
      // );
      return () => {
        resetLoginState();
      };
    }
  }, []);

  const responseGoogle = (response) => {
    // console.log("Response from GOOGLE -> ", response);
    // resetCounter();
    if (response.credential) {
      callGoogleLoginAsync({
        link: history,
        accessToken: response.credential,
        redirectTo: redirectionParam,
      });
    }
    //  else if (response.error === "idpiframe_initialization_failed") {
    //   if (myCounterValue !== 1) {
    //     incrementCounter(1);
    //     alert("Please enable cookies to continue!");
    //   }
    // }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      if (password.length !== 0) {
        let data = {
          email: email.trim(),
          password,
          link: history,
          redirectTo: redirectionParam,
        };
        callLogin(data);
      }
    } else {
      alert("Please enter valid email");
    }
  };

  return (
    <GapiContextProvider>
      <NavBar />
      {(signInLoading || googleSignInLoading) && <Loader />}
      <div id="login">
        <Container>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="login-card">
                  <span className="heading">Login</span>
                  <img src={logo} className="brandlogo" />
                  <Card.Body>
                    <Form>
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          placeholder="Enter email"
                          type="text"
                          value={email}
                          classname="form-field"
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Password</label>
                        <InputField
                          type={showeye ? "password" : "text"}
                          classname="password-control"
                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          placeholder="Enter password"
                          eye
                          setshow={() => setshoweye(!showeye)}
                          showeye={showeye}
                        />
                      </FormGroup>
                      {signInError !== undefined ? (
                        <p className="errorText">{signInError}</p>
                      ) : null}
                      {/* {googleSignInError !== undefined ? (
                        <p className="errorText">{googleSignInError}</p>
                      ) : null} */}
                      <Row>
                        <CustomButton
                          type="submit"
                          name="Login"
                          filled={true}
                          primary={true}
                          classname="login-btn"
                          onClick={(e) => onSubmit(e)}
                        />
                      </Row>
                      <Row className="mt-2 mb-3">
                        <Col align="center">
                          <GoogleLogin
                            // https://accounts.google.com/o/oauth2/auth?

                            // clientId="493644900586-hkio4j2g3228k7qu9qqbmhblelm5n3dj.apps.googleusercontent.com"
                            // buttonText="LOGIN WITH GOOGLE"
                            cookiePolicy={"single_host_origin"}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            prompt={"consent"}
                            shape={"pill"}
                            size={"large"}
                            state={"name=bookscreen"}
                            // theme={"filled_blue"}
                            // isSignedIn={true}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className="forgot-div">
                          <a href="/forgot-password" className="forgot-text">
                            Forgot Password
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="redirect-text">
                          Don't have an account yet?{" "}
                          <a href="/signup" className="login-signup">
                            Sign up here
                          </a>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </GapiContextProvider>
  );
};

const mapStateToProps = ({ app = {}, user = {} }) => {
  const signInLoading = _.get(user, "signInLoading", false);
  const signInError = _.get(user, "signInError", undefined);

  const googleSignInLoading = _.get(
    user,
    "passGoogleAccessTokenLoading",
    false
  );
  const googleSignInError = _.get(
    user,
    "passGoogleAccessTokenError",
    undefined
  );
  const myCounterValue = _.get(user, "changeCounter", 0);
  return {
    signInLoading,
    signInError,
    googleSignInLoading,
    googleSignInError,
    myCounterValue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callLogin: (data) => dispatch({ type: "userSignInCalled", payload: data }),
  callGoogleLoginAsync: (data) =>
    dispatch({ type: "passLoaginFromGoogleSagaCalled", payload: data }),
  resetLoginState: () => dispatch(resetSignInState()),
  incrementCounter: (data) => dispatch(changeCounterSuccess(data)),
  resetCounter: () => dispatch(resetChangeCounter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
