import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import NavBar from "@components/navbar/Navbar";
import SearchFilter from "@components/search-filter/SearchFilter";
import SearchPageCards from "@components/searchpage-cards/SearchPageCards";
import Footer from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import { clearSearchError } from "@store/userredux";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import "@features/search-page/SearchPageStyle.scss";
import {
  locationForSearchLoading,
  titleForSearchLoading,
  clearLoacationTitleForSearch,
  categoryForSearchLoading,
  triedForCategorySearch,
  triedForLocationSearch,
  callFromEventNavTrial,
  clearCallFromEventNav,
} from "@store/eventredux";
import * as _ from "lodash";

function SearchPage({
  searchLoading,
  resultsOfSearch,
  searhErrorFromBackend,
  clearBackendError,
  callSearchSaga,
  countLoading,
  counts,
  countError,
  getCountAsync,
  searchTitleAsync,
}) {
  const location = useLocation();
  const [eventType, setEventType] = useState("");
  const [skip, setskip] = useState(0);
  const [limit, setlimit] = useState(12);
  const [pages, setpages] = useState(0);

  const history = useHistory();
  const [destinationType, setDestinationType] = useState();
  const handleSearch = (skipv) => {
    const query = new URLSearchParams(location.search);
    let sname = query.get("name");
    let strending = query.get("trending");
    let slocation = query.get("location");
    let scategory = query.get("category");

    let smax = query.get("max");
    let smin = query.get("min");

    if (sname == null) sname = "";
    if (strending == null) strending = "";
    if (slocation == null) slocation = "";
    if (scategory == null) scategory = "";

    if (smax == null) smax = "";
    if (smin == null) smin = "";

    let data = {
      ["name"]: sname,
      ["trending"]: strending || "",
      ["location"]: slocation,
      ["category"]: scategory,
      ["min"]: smin,
      ["max"]: smax,
      ["limit"]: limit,
      ["skip"]: skipv,
    };

    const params = new URLSearchParams(data);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <>
      <NavBar />
      <div id="SearchPage">
        <Container>
          {searchLoading && <Loader />}
          <div className="search-section">
            <SearchFilter
              typeofEvent={eventType}
              typeOfDestination={destinationType}
              titleValue={searchTitleAsync}
              type={"event"}
            />
          </div>
          {/* {resultsOfSearch !== undefined &&
            resultsOfSearch.length > 0 &&
            resultsOfSearch.map((item) => (
              <div className="cards-section">
                <SearchPageCards dataItem={item} />
              </div>
            ))} */}
          <div className="cards-section">
            <SearchPageCards dataItem={resultsOfSearch} />
          </div>
          {counts !== undefined && counts.count > 12 && (
            <div className="paginationsection mb-5">
              <span className="noofpagetxt">
                Showing {pages + 1} From {Math.ceil(counts.count / limit)}
              </span>
              <div className="paginationallignment">
                <ReactPaginate
                  previousLabel={<PreviousIcon className="paginationicon" />}
                  nextLabel={<NextIcon className="paginationicon" />}
                  breakLabel={"..."}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  breakClassName={"break-me"}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageCount={counts.count / limit}
                  forcePage={pages}
                  onPageChange={(e) => {
                    setskip(e.selected * limit);

                    handleSearch(e.selected * limit);

                    setpages(e.selected);
                  }}
                />
              </div>
            </div>
          )}
          <div className="error-text">
            {searhErrorFromBackend !== undefined && searhErrorFromBackend}
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}
const mapStateToProps = ({ user = {}, event = {}, app = {} }) => {
  const searchLoading = _.get(user, "searchLoading", false);
  const resultsOfSearch = _.get(user, "searchResults", []);
  const searhErrorFromBackend = _.get(user, "searchError", undefined);
  const countLoading = _.get(event, "getCountForSearchLoading", false);
  const counts = _.get(event, "getCount", undefined);
  const countError = _.get(event, "getCountForSearchError", undefined);

  return {
    searchLoading,
    resultsOfSearch,
    countLoading,
    counts,
    countError,
    searhErrorFromBackend,
  };
};
const mapDispatchToProps = (dispatch) => ({
  callSearchSaga: (data) =>
    dispatch({ type: "searchPageSagaCalled", payload: data }),
  getCountAsync: (data) =>
    dispatch({ type: "getCountForSearchSagaCalled", payload: data }),
  clearBackendError: () => dispatch(clearSearchError()),
  titleForSearchAsync: (data) => dispatch(titleForSearchLoading(data)),
  locationForSearchAsync: (data) => dispatch(locationForSearchLoading(data)),
  clearLocationAndTitleAsync: () => dispatch(clearLoacationTitleForSearch()),
  categoryForSearchAsync: (data) => dispatch(categoryForSearchLoading(data)),
  categorySearchTrialAsync: (data) => dispatch(triedForCategorySearch(data)),
  locationSearchTrialAsync: (data) => dispatch(triedForLocationSearch(data)),
  clearEventCall: () => dispatch(clearCallFromEventNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
