import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Col, Row, Carousel } from "react-bootstrap";
import { Container } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import * as _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { resetBookingVilla } from "@store/eventredux";
import { clearBackendError } from "@store/eventredux";
import MainFooter from "@components/footer/MainFooter";
import Navbar from "@components/navbar/Navbar";
import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import BookingSuccessPopup from "@components/booking-success-popup/BookingSuccessPopup";
import LocationIcon from "@images/location.svg";
import { ReactComponent as NoData } from "@images/nodata.svg";
import banner from "@images/banner.png";
import facility from "@images/facility.svg";
import insta from "@images/insta.svg";
import fb from "@images/fb.svg";
import twittericon from "@images/twittericon.svg";
import whatsapp from "@images/whatsapp.svg";
import "@features/luxuryCars-details/LuxuryCarsDetailsStyle.scss";
import {
  FacebookShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

function LuxuryCarsDetails({
  GetEventDetail,
  eventDetails,
  eventLoading,
  faqs,
  faqsLoading,
  faqsError,
  GetFaqs,
  userLoggedIn,
  eventError,
  clearBackendErrorAsync,
  eventItinerariesCalled,
  eventItinerariesLoading,
  eventItineraries,
  eventItinerariesError,
  villaDetLoading,
  villaDet,
  villaError,
  villaFaqLoading,
  villaFaq,
  villaFaqError,
  GetVillaDetails,
  GetVillaFacilities,
  villaBookingLoading,
  villaBookingSucc,
  villaBookError,
  BookVillaAsync,
  clearBackend,
  userDet,
  refreshBookingState,
}) {
  let history = useHistory();
  const hasWindow = typeof window !== "undefined";

  const [counter, setCounter] = useState(1);
  const [counter2, setCounter2] = useState(1);
  const [arrivalTime, setArrivalTime] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [finalCheckInPeriod, setFinalCheckInPeriod] = useState("");
  const [finalCheckOutPeriod, setFinalCheckoutPeriod] = useState("");
  let { id } = useParams();
  var para;
  const location2 = useLocation();

  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };

  const [readMore, setReadMore] = useState(false);
  const [Description, setDescription] = useState();
  const [backendError, setBackendError] = useState();
  const [dateExceeded, setDateExceeded] = useState(0);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [ticketSaleStartdate, setTicketSaleStartDate] = useState();
  const [ticketSaleEndDate, setTicketSaleEndDate] = useState();
  const [maxGuest, setMaxGuest] = useState();
  const [calTotal, setCalTotal] = useState();
  const [checked, setChecked] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [bookingDetails, setBookingDetails] = useState();
  const [slider, setSlider] = useState();
  const [myRedirect, setMyRedirect] = useState("");
  const [eveId, setEveId] = useState();

  useEffect(() => {
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);
  useEffect(() => {
    if (para !== undefined) {
      setMyRedirect(para);
    }
  }, [para]);
  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({
        urlSlug: true,
        slugVal: decodeURIComponent(myRedirect),
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetEventDetail({ urlSlug: false, eventId: id });
      }
    }
  }, [myRedirect, id]);
  useEffect(() => {
    handleBackendCallsBasedOnIdChange();
  }, [id, eveId]);
  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      GetVillaDetails({ id: eveId, type: "luxuryCar" });
      GetVillaFacilities({ id: eveId, type: "luxuryCar" });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetVillaDetails({ id, type: "luxuryCar" });
        GetVillaFacilities({ id, type: "luxuryCar" });
      }
    }
  };

  useEffect(() => {
    if (eventDetails !== undefined) {
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      setEveId(eventDetails.id);

      setdescription(eventDetails.description);
    }
  }, [eventDetails, id, eveId]);

  return (
    <div id="LuxuryCarsDetails">
      <Helmet>
        <title>{`${eventDetails?.title} | Followgoa`}</title>
      </Helmet>
      <Navbar />
      {eventLoading && <Loader />}
      {eventError === undefined && (
        <div className="topbannerwrapper">
          {eventDetails !== undefined && eventDetails.banner !== null && (
            <img src={banner} className="topbanner" />
          )}
        </div>
      )}
      {eventError === undefined && (
        <Container>
          <div className="outerwrapper">
            <div className="titleblock">
              <div className="titlewrapper">
                <div className="title">
                  <span>
                    {eventDetails !== undefined && title !== undefined && title}
                  </span>
                </div>
                <div className="referencetext">
                  {eventDetails !== undefined &&
                    eventDetails.tourRef !== null && (
                      <span>Reference ID: {tourRef}</span>
                    )}
                </div>
              </div>
              <div className="buttonwrapper">
                <CustomButton
                  className="all-events-btn1"
                  // name={"Book"}
                  name={
                    villaDet !== undefined &&
                    villaDet.price !== null &&
                    villaDet.price > 0
                      ? "Book"
                      : "RSVP HERE"
                  }
                  onClick={() => {
                    if (eventDetails.urlSlug === null) {
                      history.push(
                        `/bookluxurycar/${
                          eventDetails !== undefined && eventDetails.id
                        }`
                      );
                    } else {
                      history.push(
                        `/bookluxurycar/${
                          eventDetails !== undefined &&
                          encodeURIComponent(eventDetails.urlSlug)
                        }`
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="property-block">
              <div className="leftalign">
                {eventDetails !== undefined && location !== undefined && (
                  <span className="location">
                    <img src={LocationIcon} className="leftsideimg" />{" "}
                    {location}
                  </span>
                )}
              </div>

              {villaDet !== undefined && villaDet.price !== null && (
                <div className="rightalign">
                  <span className="price">₹{villaDet.price}/day</span>
                </div>
              )}
            </div>
            {/* <div className="leftalign">
            <span className="viewlocation">View Location</span>
          </div> */}
            {eventDetails !== undefined &&
              eventDetails.googleMapLink !== null && (
                <div className="leftalign">
                  <a
                    className="viewlocation"
                    href={`${eventDetails.googleMapLink}`}
                    target="_blank"
                  >
                    View Location
                  </a>
                </div>
              )}
          </div>
          <Row className="mb-5">
            <Col lg={12}>
              <div className="outerwrapper">
                <div className="about-block">
                  {eventDetails !== undefined && description !== "" && (
                    <h2 className="about ">About</h2>
                  )}
                  <span className="aboutdes">
                    {Description === undefined
                      ? eventDetails !== undefined &&
                        eventDetails.description !== undefined
                        ? String(description).slice(0, 370)
                        : ""
                      : Description}
                  </span>{" "}
                  {String(description).length > 370 ? (
                    readMore === false ? (
                      <span
                        className="readmore"
                        onClick={() => {
                          setReadMore(true);
                          setDescription(
                            String(description).slice(0, description.length)
                          );
                        }}
                      >
                        Read More
                      </span>
                    ) : (
                      <span
                        className="readmore"
                        onClick={() => {
                          setReadMore(false);
                          setDescription(String(description).slice(0, 370));
                        }}
                      >
                        Show Less
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {villaDet !== undefined && (
                  <div className="details-block">
                    {villaDet !== undefined &&
                      villaDet.no_of_seaters !== null && (
                        <h2 className="about">Car Details</h2>
                      )}
                    {villaDet !== undefined &&
                      villaDet.no_of_seaters !== null && (
                        <div className="v-details">
                          <span className="noof">No. of Seaters :</span>
                          <span className="count-text">
                            {villaDet.no_of_seaters}
                          </span>
                        </div>
                      )}
                  </div>
                )}
                {villaFaq !== undefined &&
                  villaFaq.length > 0 &&
                  villaFaq.filter((item) => item.price > 0).length > 0 && (
                    <div className="facility-block">
                      <h2 className="about ">Paid Facilities</h2>

                      {villaFaq !== undefined &&
                        villaFaq.length > 0 &&
                        villaFaq
                          .filter((item) => item.price > 0)
                          .map((item) => (
                            <div className="d-flex" key={`villfq${item.id}`}>
                              <img src={facility} className="facilities-icon" />
                              <p className="facilities-text">
                                {item.facility_name} - ₹{item.price}{" "}
                              </p>
                            </div>
                          ))}
                      {/* {villaFaq !== undefined &&
                    villaFaq.length > 0 && villaFaq.filter((item)=>(item.price) == 0).map((item) => (
                      <div className="d-flex">
                        <img src={facility} className="facilities-icon" />
                        <p className="facilities-text">{item.facility_name} - Complimentary </p>
                      </div>
                    ))} */}
                    </div>
                  )}
                {villaFaq !== undefined &&
                  villaFaq.length > 0 &&
                  villaFaq.filter((item) => item.price == 0).length > 0 && (
                    <div className="facility-block">
                      <h2 className="about ">Complimentary Facilities</h2>

                      {/* {villaFaq !== undefined &&
                    villaFaq.length > 0 && villaFaq.filter((item)=>(item.price) > 0).map((item) => (
                      <div className="d-flex">
                        <img src={facility} className="facilities-icon" />
                        <p className="facilities-text">{item.facility_name} - ₹{item.price} </p>
                      </div>
                    ))} */}
                      {villaFaq !== undefined &&
                        villaFaq.length > 0 &&
                        villaFaq
                          .filter((item) => item.price == 0)
                          .map((item) => (
                            <div className="d-flex" key={`vil2lfq${item.id}`}>
                              <img src={facility} className="facilities-icon" />
                              <p className="facilities-text">
                                {item.facility_name}{" "}
                              </p>
                            </div>
                          ))}
                    </div>
                  )}
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className="about"
                      onClick={() => {
                        if (myRedirect !== "" && eveId !== undefined) {
                          GetFaqs({ eventId: eveId });
                          // eventItinerariesCalled({ eventId: eveId });
                        } else {
                          if (id !== undefined) {
                            GetFaqs({ eventId: id });
                            // eventItinerariesCalled({ eventId: eveId });
                          }
                        }
                      }}
                    >
                      FAQs
                    </Accordion.Header>
                    <Accordion.Body>
                      {faqsLoading && (
                        <Spinner
                          className=""
                          animation="border"
                          variant="dark"
                          role="status"
                          aria-hidden="true"
                        ></Spinner>
                      )}
                      <ol>
                        {faqs !== undefined && faqs.length > 0 ? (
                          faqs.map((item) => {
                            return (
                              <div
                                className="leftalign mb-3"
                                key={`fq${item.id}`}
                              >
                                <li>
                                  <h3 className="question">{item.question}</h3>
                                </li>
                                <p className="answer">{item.answer}</p>
                              </div>
                            );
                          })
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <div className="nothing-found">
                                {hasWindow && (
                                  <NoData className="no-data faq-no-data" />
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            {eventDetails !== undefined &&
              eventDetails.eventImages !== undefined &&
              eventDetails.eventImages.length > 1 && (
                <Col lg={7}>
                  <div className="slider-div">
                    <div className="title">
                      <span>Gallery</span>
                    </div>
                    <Carousel interval={null} indicators={false}>
                      {eventDetails.eventImages.map((item) => (
                        <Carousel.Item key={`gallery${item.id}`}>
                          <div className="slider">
                            <img src={item.lg} className="slider-img" />
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </Col>
              )}
            {eventDetails !== undefined &&
              eventDetails.eventImages !== undefined &&
              eventDetails.eventImages.length > 0 &&
              eventDetails.eventImages.length < 2 && (
                <Col lg={7}>
                  <div className="slider-div">
                    <div className="title">
                      <span>Gallery</span>
                    </div>
                    {/* <Carousel interval={null} indicators={false}> */}
                    {eventDetails.eventImages.map((item) => (
                      // <Carousel.Item>
                      <div className="slider" key={`cgallery${item.id}`}>
                        <img src={item.lg} className="slider-img" />
                      </div>
                      // </Carousel.Item>
                    ))}
                    {/* </Carousel> */}
                  </div>
                </Col>
              )}
            <Col lg={5}>
              <div
                className={
                  eventDetails.eventImages !== undefined &&
                  eventDetails.eventImages.length !== 0
                    ? "outerwrappershare"
                    : "outerwrappershare outersharediv"
                }
              >
                <div className="share-event">
                  <span>Share this Event</span>
                </div>
                <div className="d-flex">
                  <WhatsappShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={whatsapp} className="sharewhatsappimg" />
                  </WhatsappShareButton>

                  <FacebookShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={fb} className="shareimg" />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={twittericon} className="shareimg" />
                  </TwitterShareButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <MainFooter />
    </div>
  );
}

const mapStateToProps = ({ user = {}, event = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", []);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const faqsLoading = _.get(event, "faqsLoading", false);
  const faqs = _.get(event, "faqs", []);
  const faqsError = _.get(event, "faqsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const eventItinerariesLoading = _.get(
    event,
    "eventItinerariesLoading",
    false
  );
  const eventItineraries = _.get(event, "eventItineraries", undefined);
  const eventItinerariesError = _.get(
    event,
    "eventItinerariesError",
    undefined
  );
  const villaDetLoading = _.get(event, "villaDetailLoading", false);
  const villaDet = _.get(event, "villaDetails", undefined);
  const villaError = _.get(event, "villaDetailsError", undefined);
  const villaFaqLoading = _.get(event, "villaFacilityLoading", false);
  const villaFaq = _.get(event, "villaFacility", undefined);
  const villaFaqError = _.get(event, "villaFacilityError", undefined);
  const villaBookingLoading = _.get(event, "villaBookLoading", false);
  const villaBookingSucc = _.get(event, "villaBooking", undefined);
  const villaBookError = _.get(event, "villaBookingError", undefined);
  const userDet = _.get(user, "userDetails", undefined);

  return {
    eventLoading,
    eventDetails,
    eventError,
    faqsLoading,
    faqs,
    userDet,
    faqsError,
    userLoggedIn,
    eventItinerariesLoading,
    eventItineraries,
    eventItinerariesError,
    villaDetLoading,
    villaDet,
    villaError,
    villaFaqLoading,
    villaFaq,
    villaFaqError,
    villaBookingLoading,
    villaBookingSucc,
    villaBookError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  GetFaqs: (data) => dispatch({ type: "getFaqsCalled", payload: data }),
  clearBackendErrorAsync: () => dispatch(clearBackendError()),
  eventItinerariesCalled: (data) =>
    dispatch({ type: "eventItinerariesCalled", payload: data }),
  GetVillaDetails: (data) =>
    dispatch({ type: "villaDetailsSagaCalled", payload: data }),
  GetVillaFacilities: (data) =>
    dispatch({ type: "villaFacilitySagaCalled", payload: data }),
  BookVillaAsync: (data) =>
    dispatch({ type: "bookVillasagaCalled", payload: data }),
  refreshBookingState: () => dispatch(resetBookingVilla()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LuxuryCarsDetails);
