import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { ReactComponent as Whatsapp } from "@images/whatsapp-logo.svg";
import '@components/whatsapp-support/WhatsappSupportStyle.scss';

const WhatsAppSupport = () => {
  const whatsappLink = `https://wa.me/${process.env.RAZZLE_APP_WHATSAPP_SUPPORT}`;

  const tooltip = (
    <Tooltip id="whatsapp-tooltip">Have questions? Chat with us on WhatsApp</Tooltip>
  );

  return (
    <div>
    <OverlayTrigger overlay={tooltip} placement="left">
      <a href={whatsappLink} className="whatsapp-support" target="_blank" rel="noopener noreferrer">
        <Whatsapp className="whatsapp-icon" alt="WhatsApp Icon" />
      </a>
    </OverlayTrigger>
  </div>
  );
};

export default WhatsAppSupport;
