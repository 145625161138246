import { all, takeLatest } from "redux-saga/effects";
import API from "./../services/Api";
import {
  becomePartnerSaga,
  getAllcategorySaga,
  getEventCategorySaga,
  getEventsCardSaga,
  getLocationSaga,
  GetBannerImageSaga,
} from "./appsaga";
import {
  bookEventSaga,
  BookingHistoryDetailsSaga,
  BookingHistoryIndividualDetailsSaga,
  getEventDetails,
  getFaqs,
  GetCountSearchSaga,
  eventItinerariesSaga,
  GetCountBookingSaga,
  GetBookingEventDetails,
  GetEventTickets,
  VillaDetailsSaga,
  VillaFacilitySaga,
  BookVillaSaga,
  GetAfterBookingVillaDetailsSaga,
  TourBookingSaga,
  ValidateCouponCodeSaga,
  getBookingDetailsForGuestSaga,
  getBookedDatesSaga,
} from "./eventsaga";

import {
  changePassword,
  editProfileData,
  GetUserProfileData,
  joinAsOperator,
  operatorSignUpRequestSaga,
  requestPasswordResetSaga,
  resendEmailVerificationSaga,
  SearchPageSaga,
  userLoginSaga,
  userSignUpSaga,
  googleAccessTokenSaga,
  guestFormSaga,
  logout,
} from "./usersaga";

const api = API.create();
export default function* root() {
  yield all([
    takeLatest("getEventCardsSagaCalled", getEventsCardSaga, api),
    takeLatest("getEventCategorySagaCalled", getEventCategorySaga, api),
    takeLatest("getAllCategorySagaCalled", getAllcategorySaga, api),
    takeLatest("getLocationSagaCalled", getLocationSaga, api),
    takeLatest("becomePartnerSagaCalled", becomePartnerSaga, api),
    takeLatest("userSignInCalled", userLoginSaga, api),
    takeLatest("userSignupcalled", userSignUpSaga, api),
    takeLatest("requestResetPasswordCalled", requestPasswordResetSaga, api),
    takeLatest("requestOperatorSignUpCalled", operatorSignUpRequestSaga, api),
    takeLatest("searchPageSagaCalled", SearchPageSaga, api),
    takeLatest("getEventDetailsCalled", getEventDetails, api),
    takeLatest("getFaqsCalled", getFaqs, api),

    takeLatest("GetProfileDataCalled", GetUserProfileData, api),
    takeLatest("joinAsOperatorCalled", joinAsOperator, api),
    takeLatest(
      "resendEmailVerificationCalled",
      resendEmailVerificationSaga,
      api
    ),
    takeLatest("ChangePasswordCalled", changePassword, api),

    // takeLatest("ChangeProfileImageCalled", changeProfileImage, api),
    takeLatest("EditProfileDataCalled", editProfileData, api),
    takeLatest("bookingsWithAttendeeNameSagaCalled", bookEventSaga, api),
    takeLatest(
      "bookingHistoryDetailsSagaCalled",
      BookingHistoryDetailsSaga,
      api
    ),
    takeLatest(
      "bookingHistoryIndividulaDetailsCalled",
      BookingHistoryIndividualDetailsSaga,
      api
    ),
    takeLatest("getCountForSearchSagaCalled", GetCountSearchSaga, api), //takeLatest("searchBasedOnTitleSagaCalled",SearchPageBasedOnTitleAndDestinationSaga,api),
    takeLatest("eventItinerariesCalled", eventItinerariesSaga, api),
    takeLatest("getCountForBookingSagaCalled", GetCountBookingSaga, api),
    takeLatest("getBookingEventDetailsSagaCalled", GetBookingEventDetails, api),
    takeLatest("getEventTickets", GetEventTickets, api),
    takeLatest("villaDetailsSagaCalled", VillaDetailsSaga, api),
    takeLatest("villaFacilitySagaCalled", VillaFacilitySaga, api),
    takeLatest("bookVillasagaCalled", BookVillaSaga, api),
    takeLatest(
      "getVillaBookingDetailsSaga",
      GetAfterBookingVillaDetailsSaga,
      api
    ),
    takeLatest("tourBookingSagaCalled", TourBookingSaga, api),
    takeLatest("getBannerImageSagaCalled", GetBannerImageSaga, api),
    takeLatest("passLoaginFromGoogleSagaCalled", googleAccessTokenSaga, api),
    takeLatest("validateCouponCodeSagaCalled", ValidateCouponCodeSaga, api),
    takeLatest("guestFormSagaCalled", guestFormSaga, api),
    takeLatest("bookedDatesSagaCalled" , getBookedDatesSaga , api),
    takeLatest(
      "bookingDetailsForGuestSagaCalled",
      getBookingDetailsForGuestSaga,
      api
    ),
    takeLatest(
      "logoutCalled",
      logout,
      api
    ),
    //takeLatest("searchBasedOnTitleSagaCalled",SearchPageBasedOnTitleAndDestinationSaga,api),
  ]);
}
