import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup } from "react-bootstrap";
import InputField from "@components/input-field/InputField";
import "@components/booking-form/BookingFormStyle.scss";
import { connect } from "react-redux";
import CustomButton from "@components/custom-button/CustomButton";
import * as _ from "lodash";

const BookingForm = ({ guestDet, setGuestDet }) => {
  const [userName, setUserName] = useState(guestDet.name);
  const [email, setEmail] = useState(guestDet.email);
  const [mobileNumber, setMobileNumber] = useState(guestDet.mobile);
  const [validated, setValidated] = useState(false);
  let emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // const onSendmsg = (event) => {
  //   event.preventDefault();
  //   console.log("submit");
  //   const form = event.currentTarget;

  //   if (
  //     form.checkValidity() === false ||
  //     emailPattern.test(guestDet.email) === false ||
  //     re.test(guestDet.mobile) === false ||
  //     guestDet.name.length < 3
  //   ) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     setValidated(true);
  //   } else {
  //     console.log("inside else");
  //     setValidated(false);
  //     let data = {
  //       name: userName,
  //       email,
  //       mobile: mobileNumber,
  //     };
  //     guestFormAsyncCalled(data);
  //   }
  // };
  return (
    <div className="BookingForm">
      <Form>
        <Row>
          <Col lg="12">
            <Row>
              <Col>
                <p className="form-title">
                  Enter Booking Details Or{" "}
                  <a href="/login" className="underline">
                    Sign in
                  </a>
                </p>
              </Col>
            </Row>
            <Row>
              <FormGroup className="form-input col-12">
                <label>Name</label>
                <InputField
                  placeholder="Name"
                  type="text"
                  classname="form-field"
                  // isInvalid={guestDet.name.length < 3}
                  name={"userName"}
                  value={guestDet.userName}
                  error={"Please enter name more then 3 characters !"}
                  onChange={(e) => setGuestDet(e)}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="form-input col-12">
                <label>Email</label>
                <InputField
                  placeholder="Email"
                  type="text"
                  name={"email"}
                  classname="form-field"
                  value={guestDet.email}
                  // isInvalid={emailPattern.test(guestDet.email) === false}
                  error={"Please enter a valid email address !"}
                  onChange={(e) => setGuestDet(e)}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="form-input col-12">
                <label>Mobile</label>
                <InputField
                  placeholder="Mobile"
                  type="number"
                  classname="form-field"
                  value={guestDet.mobile}
                  name={"mobile"}
                  // isInvalid={re.test(guestDet.mobile) === false}
                  error={"Please enter a valid mobile number !"}
                  onChange={(e) => setGuestDet(e)}
                />
              </FormGroup>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Submit"
                  filled={true}
                  primary={true}
                  onClick={() => {}}
                />
              </Col>
            </Row> */}
            <Row>
              <Col className="redirect-col">
                <div className="redirect">
                  <span>
                    Already have an account? <a href="/login">Sign in here</a>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const guestFormLoad = _.get(user, "guestFormSignupLoading", false);
  const guestFormBackendError = _.get(user, "guestFormError", false);
  return {
    guestFormLoad,
    guestFormBackendError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  guestFormAsyncCalled: (data) =>
    dispatch({ type: "guestFormSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
