import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as _ from "lodash";
import { resetBecomepartnerStates } from "@store/appredux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import MainFooter from "@components/footer/MainFooter";
import bg from "@images/bg.png";
import navlogo from "@images/logo.png";
import heart from "@images/heart.svg";
import sparkpluslogo from "@images/spark+ logo.svg";
import "@features/coming-soon/ComingSoonStyle.scss";
function ComingSoon({
  becomePartner,
  resetBecomePartnerStates,
  becomePartnerLoading,
  becomePartnerSuccess,
  becomePartnerFailure,
}) {
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    if (becomePartnerSuccess !== undefined) {
      setUserEmail("");
      alert("Successfully saved the response!");
      resetBecomePartnerStates();
    }
  }, [becomePartnerSuccess]);

  useEffect(() => {
    if (becomePartnerFailure !== undefined) {
      alert(`${becomePartnerFailure}`);
      resetBecomePartnerStates();
    }
  }, [becomePartnerFailure]);

  const callBecomePartnerApi = () => {
    // console.log(userEmail);
    if (userEmail === undefined || userEmail === "") {
      alert("Please enter an email");
    } else {
      becomePartner({ email: userEmail });
    }
  };
  return (
    <>
      <div id="comingsoon">
        <img className="bannerbg" src={bg} />
        <img className="navlogo" src={navlogo} />
        <div className="textwrapper ">
          <img className="logo" src={navlogo} />
          <div className="title">
            <span>Goa’s home grown experiential travel & tourism platform</span>
          </div>
          <p className="subtitle">
            We aim to serve over a million users in discovering Goa like never
            before by booking exclusive travel experiences, tours, shopping and
            activities across Goa.
          </p>

          <p className="becomepartner">Become a partner</p>
          <div className="inputwrapper">
            <InputField
              placeholder="Email Address"
              type="text"
              classname="email-field"
              value={userEmail}
              onChange={(element) => {
                setUserEmail(element.target.value);
              }}
            />
            <CustomButton
              type="submit"
              name="Become a partner"
              filled={true}
              primary={true}
              classname="partner-btn"
              onClick={callBecomePartnerApi}
            />
            {/* <span className="coming">Coming Soon</span> */}
          </div>
          <Row>
            <Col lg={4}></Col>
            <Col lg={4}>
              {" "}
              <div className="footertext ">
                <span>MADE FOR GOA WITH LOVE</span>
                <span className="footerimg">
                  <img src={heart} />
                </span>
              </div>
            </Col>
            <Col lg={4}>
              {" "}
              <div className="productby ">
                <span>A Product By</span>
                <span className="footerimg">
                  <a href="https://sparkplustech.com/">
                    {" "}
                    <img src={sparkpluslogo} />
                  </a>
                </span>
              </div>
            </Col>
          </Row>
          <MainFooter />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ app = {} }) => {
  const becomePartnerLoading = _.get(app, "becomePartnerLoading", false);
  const becomePartnerSuccess = _.get(app, "becomePartnerSuccess", undefined);
  const becomePartnerFailure = _.get(app, "becomePartnerFailure", undefined);

  return {
    becomePartnerLoading,
    becomePartnerSuccess,
    becomePartnerFailure,
  };
};

const mapDispatchToProps = (dispatch) => ({
  becomePartner: (data) =>
    dispatch({ type: "becomePartnerSagaCalled", payload: data }),
  resetBecomePartnerStates: () => dispatch(resetBecomepartnerStates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);
