import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { clearBackendError } from "@store/eventredux";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { clearBookingEventOnUnmount } from "@store/eventredux";
import Navbar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import Loaders from "@components/full-screen-loader/FullScreenLoader";
import banner from "@images/banner.png";
import "@features/booking-details/BookingDetailsStyle.scss";
import * as _ from "lodash";

function BookingDetailsForGuest({
  bookdetailsLoading,
  bookingDet,
  bookingError,
  clearBackend,
  getBookingDetAsync,
  userLoggedIn,
  getBookingEventsDet,
  bookingEventDetLoading,
  bookingEventDet,
  bookingEventError,
  clearBackendEventDet,
  getVillaBookingDetails,
  getVillaBookingDetLoading,
  getVillaBookingDet,
  getVillaBookingError,
}) {
  let { id } = useParams();
  const location = useLocation();
  const [eventDet, setEventDet] = useState();
  const [bookId, setBookId] = useState();
  const [bookedDate, setBookedDate] = useState();
  const [status, setStatus] = useState();
  const [amount, setAmount] = useState();
  const [operator, setOperator] = useState();
  const [transactionId, setTransactionId] = useState();
  const [days, setDays] = useState();
  useEffect(() => {
    if (id !== undefined) {
      getBookingDetAsync({
        // token: userLoggedIn.accessToken,
        id,
      });
      // getVillaBookingDetails({
      //   token: userLoggedIn.accessToken,
      //   id,
      // });
    }
  }, [id]);
  useEffect(() => {
    if (bookingEventDet !== undefined) {
      setEventDet(bookingEventDet);
    }
  }, [bookingEventDet, bookingDet]);

  useEffect(() => {
    if (bookingDet !== undefined) {
      console.log("booking det", bookingDet);
      if (bookingDet.operator !== undefined)
        setOperator(bookingDet.operator.name);
      setBookId(bookingDet.id);
      setBookedDate(bookingDet.createdOn);
      if (bookingDet.bookingStatus !== null) {
        setStatus(bookingDet.bookingStatus);
      }

      setAmount(bookingDet.totalAmount);
      if (bookingDet.eventDate !== null) {
        abc();
      }
      if (bookingDet.payment !== undefined && bookingDet.payment !== null) {
        setTransactionId(bookingDet.payment.transactionId);
      }

      getBookingEventsDet({
        eventId: bookingDet.bmtEventId,
      });
    }
  }, [bookingDet]);

  useEffect(() => {
    if (bookingError !== undefined) {
      if (alert(`${bookingError}`)) {
        clearBackend();
      } else {
        clearBackend();
      }
    }
  }, [bookingError]);

  function abc() {
    //console.log("Details ->>>>> ", bookingDet);

    var given = moment(bookingDet.eventDate.startDate, "YYYY-MM-DD");
    var current = moment(bookingDet.eventDate.endDate, "YYYY-MM-DD");

    //Difference in number of days
    let difTime = moment.duration(current.diff(given)).asDays();
    setDays(difTime);
    //console.log("my days", difTime);
  }

  useEffect(() => {
    return () => clearBackendEventDet();
  }, []);

  return (
    <div id="BookingDetails">
      <Navbar />
      <div className="banner-section">
        <img
          src={
            eventDet !== undefined && eventDet.banner !== null
              ? eventDet.banner
              : banner
          }
          className="bannerbgimg"
        />
      </div>
      {(bookingEventDetLoading || bookdetailsLoading) && <Loaders />}
      <Container>
        <Row>
          <Col lg={6} className="booking-details-div">
            <div className="container-div event-details">
              <div className="heading">
                {eventDet !== undefined && eventDet.type == "event" && (
                  <span>Event Details</span>
                )}
                {eventDet !== undefined && eventDet.type == "villa" && (
                  <span>Villa Details</span>
                )}
                {eventDet !== undefined && eventDet.type == "tour" && (
                  <span>Tour Details</span>
                )}
                {eventDet !== undefined && eventDet.type == "luxuryCar" && (
                  <span>Luxury Car Details</span>
                )}
              </div>
              <div className="name">
                {eventDet !== undefined && <span>{eventDet.title}</span>}
              </div>
              <div className="details-div">
                {/* <div className="details">
                  <div className="title">
                    <span>Duration :</span>
                  </div>
                  {days !== undefined && (
                    <div className="value days">
                      <span>{days} Day(s)</span>
                    </div>
                  )}
                </div> */}
                <div className="details">
                  <div className="title">
                    <span>Host Name :</span>
                  </div>
                  <div className="value">
                    {operator !== undefined && <span>{operator}</span>}{" "}
                  </div>
                </div>
              </div>
              <div className="details-div">
                {/* <div className="details">
                  <div className="title">
                    <span>Age limit :</span>
                  </div>
                  <div className="value">
                    {eventDet !== undefined &&
                      eventDet.ageMin !== undefined &&
                      eventDet.ageMax !== undefined && (
                        <span>
                          {" "}
                          {eventDet.ageMin} yrs - {eventDet.ageMax} yrs
                        </span>
                      )}
                  </div>
                </div> */}
                <div className="details">
                  <div className="title">
                    <span>Venue :</span>
                  </div>
                  <div className="value">
                    {eventDet !== undefined &&
                      eventDet.location !== undefined && (
                        <span> {eventDet.location}</span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="booking-details-div">
            <div className="container-div">
              <div className="payment-heading">
                <span>Payment Details</span>
              </div>
              <div className="details-div">
                <div className="details">
                  <div className="title">
                    <span>Booking ID :</span>
                  </div>
                  <div className="value">
                    {bookId !== undefined && <span>{bookId}</span>}
                  </div>
                </div>
                <div className="details">
                  <div className="title">
                    <span>Created On :</span>
                  </div>
                  <div className="value">
                    {bookedDate !== undefined && (
                      <span>
                        {" "}
                        {moment(bookedDate).format("dddd, MMMM Do YYYY")}{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="details-div">
                <div className="details">
                  <div className="title">
                    <span>Status :</span>
                  </div>
                  <div className="value">
                    {status !== undefined && <span>{status}</span>}
                  </div>
                </div>
                {eventDet !== undefined &&
                  eventDet.gstPercentage !== null &&
                  eventDet.gstPercentage !== "0.00" && (
                    <div className="details">
                      <div className="title">
                        <span>GST :</span>
                      </div>
                      <div className="value">
                        <span>{eventDet.gstPercentage} %</span>
                      </div>
                    </div>
                  )}
              </div>

              {bookingDet !== undefined &&
                bookingDet.couponUsed !== undefined &&
                bookingDet.couponUsed.length > 0 &&
                bookingDet.couponUsed.map((item) => (
                  <div className="details-div">
                    {item.coupon !== null && (
                      <div className="details">
                        <div className="title">
                          <span>Coupon Applied :</span>
                        </div>
                        <div className="value">
                          <span>{item.coupon?.couponCode} </span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {bookingDet !== undefined &&
                bookingDet.couponDiscount !== null && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Coupon Discount :</span>
                      </div>
                      <div className="value">
                        {bookingDet.couponDiscount !== null && (
                          <span>₹ {bookingDet.couponDiscount} </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {bookingDet !== undefined && bookingDet.total !== null && (
                <div className="details-div">
                  <div className="details">
                    <div className="title">
                      {eventDet !== undefined && eventDet.type === "tour" ? (
                        <span>Number of travellers :</span>
                      ) : eventDet !== undefined &&
                        eventDet.type === "villa" ? (
                        <span>Number of guests :</span>
                      ) : (
                        <span>Number of Person(s) :</span>
                      )}
                    </div>
                    <div className="value">
                      <span>{bookingDet.total}</span>
                    </div>
                  </div>
                  <div className="details">
                    <div className="title">
                      <span>Base Amount :</span>
                    </div>
                    {bookingDet !== undefined &&
                      bookingDet.amountWithoutGST !== null && (
                        <div className="value">
                          {amount !== undefined && (
                            <span> ₹ {bookingDet.amountWithoutGST}</span>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )}
              {bookingDet !== undefined &&
                bookingDet.departureDetails !== undefined && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Departure date:</span>
                      </div>
                      <div className="value">
                        <span>
                          {moment(
                            bookingDet.departureDetails.eventStart
                          ).format("DD/MM/YYYY hh:mm A")}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              {/* {bookingDet !== undefined &&
              bookingDet.couponUsed !== undefined &&
              bookingDet.couponUsed.length > 0 ? (
                bookingDet.couponUsed.map((item) =>
                  item.coupon.type === "flat" ? (
                    <div className="details-div">
                      <div className="details">
                        <div className="title">
                          <span>Total Amount :</span>
                        </div>
                        <div className="value">
                          {amount !== undefined && (
                            <span> ₹ {amount - item.coupon.value}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="details-div">
                      <div className="details">
                        <div className="title">
                          <span>Total Amount :</span>
                        </div>
                        <div className="value">
                          {amount !== undefined && (
                            <span>
                              {" "}
                              ₹ {amount - (item.value.coupon * amount) / 100}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : ( */}
              <div className="details-div">
                <div className="details">
                  <div className="title">
                    <span>Total Amount :</span>
                  </div>
                  <div className="value">
                    {amount !== undefined && <span> ₹ {amount}</span>}
                  </div>
                </div>
              </div>

              {eventDet !== undefined &&
                eventDet.type === "luxuryCar" &&
                bookingDet !== undefined &&
                bookingDet.luxuryCarBookings !== undefined &&
                bookingDet.luxuryCarBookings.length > 0 &&
                bookingDet.luxuryCarBookings?.[0]?.luxuryCarFacilities !==
                  undefined &&
                bookingDet.luxuryCarBookings?.[0]?.luxuryCarFacilities.filter(
                  (item) => item.price == 0
                ).length > 0 && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Complimentary Facilities :</span>
                      </div>
                      {bookingDet.luxuryCarBookings[0]?.luxuryCarFacilities
                        .filter((item) => item.price == 0)
                        .map((item, index) => (
                          <div className="value" key={index}>
                            {item.facility_name !== undefined && (
                              <p>
                                {item.facility_name} - ₹ {item.price}
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              {eventDet !== undefined &&
                eventDet.type === "luxuryCar" &&
                bookingDet !== undefined &&
                bookingDet.luxuryCarBookings !== undefined &&
                bookingDet.luxuryCarBookings.length > 0 &&
                bookingDet.luxuryCarBookings?.[0]?.luxuryCarFacilities !==
                  undefined &&
                bookingDet.luxuryCarBookings?.[0]?.luxuryCarFacilities.filter(
                  (item) => item.price > 0
                ).length > 0 && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Paid Facilities :</span>
                      </div>
                      {bookingDet.luxuryCarBookings[0]?.luxuryCarFacilities
                        .filter((item) => item.price > 0)
                        .map((item, index) => (
                          <div className="value" key={index}>
                            {item.facility_name !== undefined && (
                              <p>
                                <ul>
                                  <li>
                                    {item.facility_name} - ₹ {item.price}
                                  </li>
                                </ul>
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}

              {eventDet !== undefined &&
                eventDet.type === "villa" &&
                bookingDet !== undefined &&
                bookingDet.villaBookings?.[0] !== undefined &&
                bookingDet?.villaBookings[0]?.villaFacilities?.filter(
                  (item) => item.price == 0
                ).length > 0 && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Complimentary Facilities :</span>
                      </div>
                      {bookingDet.villaBookings[0]?.villaFacilities
                        .filter((item) => item.price == 0)
                        .map((item, index) => (
                          <div className="value" key={index}>
                            {item.facility_name !== undefined && (
                              <p>
                                {item.facility_name} - ₹ {item.price}
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              {eventDet !== undefined &&
                eventDet.type === "villa" &&
                bookingDet !== undefined &&
                bookingDet.villaBookings?.[0] !== undefined &&
                bookingDet?.villaBookings[0]?.villaFacilities?.filter(
                  (item) => item.price > 0
                ).length > 0 && (
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Paid Facilities :</span>
                      </div>
                      {bookingDet.villaBookings[0]?.villaFacilities
                        .filter((item) => item.price > 0)
                        .map((item, index) => (
                          <div className="value" key={index}>
                            {item.facility_name !== undefined && (
                              <p>
                                <ul>
                                  <li>
                                    {item.facility_name} - ₹ {item.price}
                                  </li>
                                </ul>
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}

              {/* )} */}

              <div className="details-div">
                <div className="transection-details">
                  {transactionId !== undefined && (
                    <div className="title">
                      <span>Transaction ID :</span>
                    </div>
                  )}
                  <div className="value">
                    {transactionId !== undefined && (
                      <span>{transactionId}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col lg={6}>
            <div className="container-div">
              <div className="traveller-heading">
                <span>Traveller Details</span>
              </div>
              <div className="subheading">
                <span>Traveller 1</span>
              </div>
              <div className="details-div">
                <div className="details">
                  <div className="title"><span>Name :</span></div>
                  <div className="value"><span>{" "} Dhanashree sawant</span></div>
                </div>
                <div className="details">
                  <div className="title"><span>Gender :</span></div>
                  <div className="value"><span>{" "}  Female</span></div>
                </div>
              </div>
              <div className="details-div">
                <div className="details">
                  <div className="title"><span>Mobile :</span></div>
                  <div className="value"><span> +91 800764097</span>{" "}</div>
                </div>
                <div className="details">
                  <div className="title"><span>Date Of Birth :</span></div>
                  <div className="value"><span>{" "} June 20 1991</span></div>
                </div>
              </div>
              <div className="details-div">
                <div className="details">
                  <div className="title"><span>State :</span></div>
                  <div className="value"><span>{" "} Maharastra</span></div>
                </div>
                <div className="details">
                  <div className="title"><span>Country :</span></div>
                  <div className="value"><span>{" "} India</span></div>
                </div>
              </div>
            </div>
          </Col> */}
          {eventDet !== undefined &&
            eventDet.type !== "tour" &&
            bookingDet !== undefined &&
            bookingDet.travellers !== undefined &&
            bookingDet.travellers.length > 0 &&
            bookingDet.travellers.map((itm, ind) => (
              <Col lg={6} className="booking-details-div">
                <div className="container-div attendee-div">
                  <div className="traveller-heading">
                    <span>Attendee - {ind + 1} </span>
                  </div>
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Ticket ID :</span>
                      </div>
                      <div className="value">
                        <span>{itm.ticketId}</span>
                      </div>
                    </div>
                    <div className="details">
                      <div className="title">
                        {itm.ticketTypeDetails !== undefined && (
                          <span>Ticket Type Name :</span>
                        )}
                      </div>
                      <div className="value">
                        <span>
                          {itm.ticketTypeDetails !== undefined &&
                            itm.ticketTypeDetails.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          {getVillaBookingDet !== undefined && eventDet?.type == "villa" && (
            <Col lg={6} className="booking-details-div">
              <div className="container-div attendee-div">
                <div className="details-div">
                  <div className="details">
                    <div className="title">
                      <span>Check In Date :</span>
                    </div>

                    <div className="value">
                      <span>
                        {moment(getVillaBookingDet.check_in_date_time).format(
                          "dddd, MMMM Do YYYY"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="details">
                    <div className="title">
                      <span>Check Out Date :</span>
                    </div>
                    <div className="value">
                      <span>
                        {moment(getVillaBookingDet.check_out_date_time).format(
                          "dddd, MMMM Do YYYY"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="details-div">
                  <div className="details">
                    <div className="title">
                      <span>Arrival Time :</span>
                    </div>
                    <div className="value">
                      <span>
                        {moment(getVillaBookingDet.check_in_date_time).format(
                          "h:mm A"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="details">
                    <div className="title">
                      <span>Checkout Time :</span>
                    </div>
                    <div className="value">
                      <span>
                        {moment(getVillaBookingDet.check_out_date_time).format(
                          "h:mm A"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {bookingDet !== undefined &&
            bookingDet.type == "luxuryCar" &&
            bookingDet.luxuryCarBookings !== undefined &&
            bookingDet.luxuryCarBookings.length > 0 && (
              <Col lg={6} className="booking-details-div">
                <div className="container-div attendee-div">
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Pick-Up Date :</span>
                      </div>

                      <div className="value">
                        <span>
                          {moment(
                            bookingDet?.luxuryCarBookings?.[0]
                              ?.check_in_date_time
                          ).format("dddd, MMMM Do YYYY")}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="details">
                      <div className="title">
                        <span>Return Date :</span>
                      </div>
                      <div className="value">
                        <span>
                          {moment(
                            bookingDet?.luxuryCarBookings?.[0]
                              ?.check_out_date_time
                          ).format("dddd, MMMM Do YYYY ")}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="details-div">
                    <div className="details">
                      <div className="title">
                        <span>Pick-Up Time :</span>
                      </div>
                      <div className="value">
                        <span>
                          {moment(
                            bookingDet?.luxuryCarBookings?.[0]
                              ?.check_in_date_time
                          ).format("h:mm A")}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="details">
                      <div className="title">
                        <span>Return Time :</span>
                      </div>
                      <div className="value">
                        <span>
                          {moment(
                            bookingDet?.luxuryCarBookings?.[0]
                              ?.check_out_date_time
                          ).format("h:mm A")}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
        </Row>
      </Container>
      <div className="footer-div">
        <MainFooter />
      </div>
    </div>
  );
}
const mapStateToProps = ({ event, user }) => {
  const bookdetailsLoading = _.get(
    event,
    "bookingDetailsForGuestLoading",
    false
  );
  const bookingDet = _.get(event, "bookingDetailsForGuest", undefined);
  const bookingError = _.get(event, "bookingDetailsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const bookingEventDetLoading = _.get(
    event,
    "getBookingEventDetailsLoading",
    false
  );
  const bookingEventDet = _.get(event, "getBookingEventDetails", undefined);
  const bookingEventError = _.get(
    event,
    "getBookingEventDetailsError",
    undefined
  );
  const getVillaBookingDetLoading = _.get(
    event,
    "afterVillaBookingDetailsLoading",
    false
  );
  const getVillaBookingDet = _.get(
    event,
    "afterVillaBookingDetails",
    undefined
  );
  const getVillaBookingError = _.get(
    event,
    "afterVillaBookingDetailsError",
    undefined
  );
  return {
    bookdetailsLoading,
    bookingDet,
    bookingError,
    userLoggedIn,
    bookingEventDetLoading,
    bookingEventDet,
    bookingEventError,
    getVillaBookingDetLoading,
    getVillaBookingDet,
    getVillaBookingError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getBookingDetAsync: (data) =>
    dispatch({ type: "bookingDetailsForGuestSagaCalled", payload: data }),
  getBookingEventsDet: (data) =>
    dispatch({ type: "getBookingEventDetailsSagaCalled", payload: data }),
  clearBackendEventDet: () => dispatch(clearBookingEventOnUnmount()),
  getVillaBookingDetails: (data) =>
    dispatch({ type: "getVillaBookingDetailsSaga", payload: data }),
  clearBackend: () => dispatch(clearBackendError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingDetailsForGuest);
