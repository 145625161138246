import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/Navbar";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import { resetSignUp } from "@store/userredux";
import SuccessPopup from "@components/success-popup/SuccessPopup";
import logo from "@images/logo.svg";
import "@features/signup/SignUp.scss";
import * as _ from "lodash";

function SignUp({
  userSignUpCalled,
  signUpLoadingStart,
  signUpErr,
  resetSignUpState,
}) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showeye1, setshoweye1] = useState(true);
  const [showeye2, setshoweye2] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  let history = useHistory();
  const onSubmit = () => {
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      if (
        fullName !== "" &&
        password !== "" &&
        password === confirmPassword &&
        mobile.length <= 10
      ) {
        let data = {
          name: fullName,
          email: email.trim(),
          password,
          mobile,
          link: history,
          openSuccessPopup: () => setShowSuccess(true),
        };
        // console.log("data for signup", data);
        userSignUpCalled(data);
      } else {
        if (fullName.length < 3)
          alert("Please enter name more than 3 letters ");
        else if (password !== confirmPassword) {
          alert("Password and confirm password must be same");
        } else if (mobile.length < 10 || mobile.length > 10) {
          alert("Please enter valid mobile number");
        } else if (password == "") {
          alert("Please enter a password more than 8 characters");
        }
      }
    } else {
      alert("Please enter valid email");
    }
  };

  useEffect(() => {
    if (signUpErr !== undefined) {
      if (alert(`${signUpErr}`)) {
        resetSignUpState();
      } else {
        resetSignUpState();
      }
    }
  }, [signUpErr]);
  return (
    <>
      <NavBar />
      <div id="SignUp">
        <Container>
          {signUpLoadingStart && <Loader />}
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="signup-card">
                  <span className="heading">Sign Up</span>
                  <img src={logo} className="brandlogo" />
                  <Card.Body>
                    <Form>
                      <FormGroup className="form-input">
                        <label>Full Name</label>
                        <InputField
                          placeholder="Enter full name"
                          type="text"
                          classname="form-field"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          placeholder="Enter email"
                          type="email"
                          classname="form-field"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Mobile</label>
                        <InputField
                          placeholder="Mobile"
                          type="number"
                          classname="form-field"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Password</label>
                        <InputField
                          placeholder="Password"
                          type={showeye1 ? "password" : "text"}
                          classname="password-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          eye
                          setshow={() => setshoweye1(!showeye1)}
                          showeye={showeye1}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Confirm Password</label>
                        <InputField
                          placeholder="Confirm Password"
                          type={showeye2 ? "password" : "text"}
                          classname="password-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          eye
                          setshow={() => setshoweye2(!showeye2)}
                          showeye={showeye2}
                        />
                      </FormGroup>

                      <Row>
                        <CustomButton
                          type="button"
                          name="Sign Up"
                          filled={true}
                          primary={true}
                          classname="signup-btn"
                          onClick={onSubmit}
                          // onClick={() => {
                          //   setShowSuccess(true);
                          // }}
                        />
                      </Row>
                      <Row>
                        <Col className="redirect-text">
                          Already have an account yet?{" "}
                          <a href="/login" className="login-signup">
                            Login here
                          </a>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <SuccessPopup
          show={showSuccess}
          scope="user"
          setShowModal={(e) => setShowSuccess(e)}
          message={
            "Account created successfully ! Kindly click the link sent to your Email ID to verify your Account."
          }
        />
      </div>
    </>
  );
}
const mapStateToProps = ({ user = {} }) => {
  const signUpLoadingStart = _.get(user, "signUpLoading", false);
  const signUpErr = _.get(user, "signUpError", undefined);
  return {
    signUpLoadingStart,
    signUpErr,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userSignUpCalled: (data) =>
    dispatch({ type: "userSignupcalled", payload: data }),
  resetSignUpState: () => dispatch(resetSignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
