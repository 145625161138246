import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import Select, { components } from "react-select";
import Slider from "rc-slider";
import { connect } from "react-redux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import { ReactComponent as CaretDownIcon } from "@images/dropdown.svg";
import "@components/search-filter/SearchFilterStyle.scss";
import { useHistory, useLocation } from "react-router-dom";
import * as _ from "lodash";

const SearchFilter = ({
  typeofEvent,
  callSearchSaga,
  getAllEventCategories,
  eventCategoryLoading,
  eventCategorties,
  eventCategoryFail,
  locationsLoading,
  allLocations,
  locationError,
  getLocations,

  limitForSearch,
  skipForSearch,
  setDestinationForSearch,
  setClickedForSearch,
  setClearForSearch,
  titleValue,
  searchTitleAsync,
  searchLocationAsync,
  searchCategoryAsync,
  getCountAsync,
  type,
}) => {
  const history = useHistory();
  const locationHook = useLocation();
  const [eventName, setEventName] = useState(
    titleValue !== undefined ? titleValue : ""
  );
  const numOptionsObj = [
    {
      value: "",
      label: "Any",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
  ];
  const [allCategory, setAllCategory] = useState("");
  const [allLoc, setAllLoc] = useState("");
  const [chosenLocation, setChosenLocation] = useState("");
  const [chosenEventCategory, setChosenEventcategory] = useState(
    typeofEvent !== undefined ? typeofEvent : ""
  );
  const [skip, setskip] = useState(0);
  const [limit, setlimit] = useState(12);
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [location, setLocation] = useState("");
  const [noBedrooms, setNoBedrooms] = useState("");
  const [noBathrooms, setNoBathrooms] = useState("");
  const [noBeds, setNoBeds] = useState("");
  const [noGuests, setNoGuests] = useState("");
  const mp = 50000;
  const [priceRange, setPriceRange] = useState([0, mp]);

  const [marks, setmarks] = useState({
    0: "0",
    [mp]: mp,
  });

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#261B1B",
      background: state.isFocused
        ? "#F3F3F3"
        : state.isSelected
        ? "#F3F3F3"
        : "white",
      padding: "10px 17.17px",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "300",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon className="dropdown-icon" />
      </components.DropdownIndicator>
    );
  };
  let unlisten = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    unlisten = history.listen((location, action) => {
      callSetFunction(location);
    });
    callSetFunction(locationHook);

    getAllEventCategories();
    getLocations();
    return () => {
      unlisten();
    };
  }, []);

  const callSetFunction = (locationData) => {
    const query = new URLSearchParams(locationData.search);
    let sname = query.get("name");
    let strending = query.get("trending");
    let slocation = query.get("location");
    let sbedroom = query.get("bedroom");
    let sbathroom = query.get("bathroom");
    let sbeds = query.get("beds");
    let sguests = query.get("guests");
    let smax = query.get("max");
    let smin = query.get("min");
    let slimit = query.get("limit");
    let sskip = query.get("skip");
    let scategory = query.get("category");

    confirmSearchParmas({
      sname,
      strending,
      slocation,
      sbedroom,
      sbathroom,
      sbeds,
      sguests,
      smax,
      smin,
      slimit,
      sskip,
      scategory,
    });
  };

  useEffect(() => {
    if (eventCategorties !== undefined) {
      setAllCategory(eventCategorties);
    }
  }, [eventCategorties]);
  useEffect(() => {
    if (allLocations !== undefined) {
      setAllLoc(allLocations);
    }
  }, [allLocations]);

  const CategoriesForSelect = () => {
    let categoryType = [];
    if (allCategory !== undefined) {
      for (let i = -1; i < allCategory.length; i++) {
        let label = "";
        let value = "";
        if (i == -1) {
          value = undefined;
          label = "Any";
        } else {
          label = allCategory[i].title;
          value = allCategory[i].id;
        }
        let obj = {
          label,
          value,
        };
        categoryType.push(obj);
      }
      return categoryType;
    } else {
      return [
        {
          value: undefined,
          label: "Any",
        },
      ];
    }
  };

  const LocationsForSelect = () => {
    let locationType = [];
    if (allLoc !== undefined) {
      for (let i = -1; i < allLoc.length; i++) {
        let label = "";
        let value = "";
        if (i == -1) {
          value = undefined;
          label = "Any";
        } else {
          label = allLoc[i].destination;
          value = allLoc[i].id;
        }

        let obj = {
          label,
          value,
        };
        locationType.push(obj);
      }
      return locationType;
    } else {
      return [
        {
          value: undefined,
          label: "Any",
        },
      ];
    }
  };

  const handleUpdateLocation = (e) => {
    if (e !== undefined && e.label !== undefined) {
      setChosenLocation(e);
      setDestinationForSearch(e.label);
    }
  };

  const handleUpdateEventCategory = (e) => {
    if (e !== undefined && e.label !== undefined) {
      setChosenEventcategory(e);
    }
  };
  const handleTitle = (e) => {
    if (e) {
      setEventName(e.target.value);
    }
  };

  const handleSearch = () => {
    let data = {
      ["name"]: eventName,
      ["trending"]:
        chosenLocation?.label == "Any" ? "" : chosenLocation?.label || "",
      ["location"]: location,
      ["min"]: minPrice,
      ["max"]: maxPrice,
      ["limit"]: limit,
      ["skip"]: skip,
    };
    if (type == "properties") {
      data.bedroom = noBedrooms?.value || "";
      data.bathroom = noBathrooms?.value || "";
      data.guests = noGuests?.value || "";
      data.beds = noBeds?.value || "";
    }
    if (type == "event") {
      data.category =
        chosenEventCategory?.label == "Any"
          ? ""
          : chosenEventCategory?.label || "";
    }
    const params = new URLSearchParams(data);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };
  const handleClear = () => {
    console.log("called");
    history.push({
      pathname: location.pathname,
      search: "",
    });
  };

  const handlePriceRangeChange = (value) => {
    setPriceRange(value);
    setMinPrice(priceRange[0]);
    setMaxPrice(priceRange[1]);
    setmarks({
      [value[0]]: value[0],
      [value[1]]: value[1],
    });
  };

  const confirmSearchParmas = ({
    sname,
    strending,
    slocation,
    sbedroom,
    sbathroom,
    sbeds,
    sguests,
    smax,
    smin,
    slimit,
    sskip,
    scategory,
  }) => {
    console.log({ type });

    console.log({
      sname,
      strending,
      slocation,
      sbedroom,
      sbathroom,
      sbeds,
      sguests,
      smax,
      smin,
      slimit,
      sskip,
      scategory,
    });

    if (sname == null) sname = "";
    if (strending == null) strending = "";
    if (slocation == null) slocation = "";
    if (sbedroom == null) sbedroom = "";
    if (sbathroom == null) sbathroom = "";
    if (sguests == null) sguests = "";
    if (sbeds == null) sbeds = "";
    if (smax == null) smax = "";
    if (smin == null) smin = "";
    if (slimit == null) slimit = 12;
    if (sskip == null) sskip = 0;
    if (scategory == null) scategory = "";
    let loc = {},
      idEvent = {};
    setEventName(sname);
    if (strending != "") {
      loc = allLocations?.find((item) => item.destination == strending);
      setChosenLocation({
        value: loc?.id,
        label: loc?.destination,
      });
    } else {
      setChosenLocation({
        value: undefined,
        label: "Any",
      });
    }
    if (scategory != "") {
      idEvent = eventCategorties?.find((item) => item.title == scategory);
      setChosenEventcategory({
        value: idEvent?.id,
        label: idEvent?.title,
      });
    } else {
      setChosenEventcategory({
        value: undefined,
        label: "Any",
      });
    }
    setlimit(slimit);
    setskip(0);
    setLocation(slocation);
    if (smax != "") setMaxPrice(smax);
    if (smin != "") setMinPrice(smin);
    if (smin != "" && smax != "") {
      setmarks({
        [smin]: smin,
        [smax]: smax,
      });
      setPriceRange([smin, smax]);
    }
    setNoBeds({
      value: sbeds,
      label: sbeds == "" ? "Any" : sbeds,
    });

    setNoGuests({
      value: sguests,
      label: sguests == "" ? "Any" : sguests,
    });

    setNoBedrooms({
      value: sbedroom,
      label: sbedroom == "" ? "Any" : sbedroom,
    });

    setNoBathrooms({
      value: sbathroom,
      label: sbathroom == "" ? "Any" : sbathroom,
    });
    callSearchSaga({
      title: sname,
      destinationId: loc?.id || undefined,
      location: slocation,
      skip: sskip,
      limit: slimit,
      scopeType: "search",
      maxPrice: smax,
      minPrice: smin,
      noOfBedrooms: sbedroom,
      noOfBathrooms: sbathroom,
      noOfBeds: sbeds,
      guestsNumber: sguests,
      categoryId: idEvent?.id,
      type: type == "properties" ? "villa" : type == "event" ? "" : type,
    });
    getCountAsync({
      title: sname,
      destinationId: loc?.id || undefined,
      location: slocation,
      maxPrice: smax,
      minPrice: smin,
      noOfBedrooms: sbedroom,
      noOfBathrooms: sbathroom,
      noOfBeds: sbeds,
      guestsNumber: sguests,
      categoryId: idEvent?.id,

      type: type == "properties" ? "villa" : type == "event" ? "" : type,
    });
  };
  return (
    <div className="SearchFilter">
      <div className="search-container">
        <Form className="searchform" autocomplete="off">
          <Row className="searchpage-form">
            <Col lg={12} xl={8} xxl={9}>
              <Row>
                <Col
                  lg={4}
                  xl={3}
                  // className={
                  //   type !== undefined && type !== "properties"
                  //     ? "col-lg-4"
                  //     : "col-lg-6"
                  // }
                >
                  <Form.Group className="input-div">
                    <label>Name</label>
                    <InputField
                      name="event"
                      placeholder={"Name"}
                      type="text"
                      value={eventName}
                      classname="form-field"
                      onChange={(event) => {
                        // setEventName(event.target.value);
                        handleTitle(event);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  xl={3}
                  // className={
                  //   type !== undefined && type !== "properties"
                  //     ? "col-lg-4"
                  //     : "col-lg-6"
                  // }
                >
                  <Form.Group className="input-div select-dp">
                    <label>Trending</label>
                    <Select
                      placeholder={
                        searchLocationAsync !== undefined
                          ? `${_.capitalize(searchLocationAsync)}`
                          : "Select Trending"
                      }
                      styles={customStyles}
                      value={chosenLocation}
                      components={{ DropdownIndicator }}
                      options={LocationsForSelect()}
                      // options={NewLocation}
                      onChange={(e) => handleUpdateLocation(e)}
                    />
                  </Form.Group>
                </Col>
                {type !== undefined && type == "event" && (
                  <Col lg={4} xl={3}>
                    <Form.Group className="input-div select-dp">
                      <label>Categories</label>
                      <Select
                        placeholder={
                          searchCategoryAsync !== undefined
                            ? `${searchCategoryAsync}`
                            : "Select category"
                        }
                        styles={customStyles}
                        value={chosenEventCategory}
                        components={{ DropdownIndicator }}
                        options={CategoriesForSelect()}
                        onChange={(e) => handleUpdateEventCategory(e)}
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col lg={4} xl={3}>
                  <Form.Group className="input-div">
                    <label>Location</label>
                    <InputField
                      name="event"
                      placeholder={"Location"}
                      type="text"
                      value={location}
                      classname="form-field"
                      onChange={(event) => {
                        setLocation(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                {type !== undefined && type === "properties" && (
                  <Col lg={4} xl={3}>
                    <Form.Group className="input-div">
                      <label>No. of Bedrooms</label>

                      <Select
                        placeholder={"No. of Bedrooms"}
                        styles={customStyles}
                        value={noBedrooms}
                        components={{ DropdownIndicator }}
                        options={numOptionsObj}
                        onChange={(e) => {
                          setNoBedrooms(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                {type !== undefined && type === "properties" && (
                  <Col lg={4} xl={3}>
                    <Form.Group className="input-div">
                      <label>No. of Bathrooms</label>
                      <Select
                        placeholder={"No. of Bathrooms"}
                        styles={customStyles}
                        value={noBathrooms}
                        components={{ DropdownIndicator }}
                        options={numOptionsObj}
                        onChange={(e) => {
                          setNoBathrooms(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                {type !== undefined && type === "properties" && (
                  <Col lg={4} xl={3}>
                    <Form.Group className="input-div">
                      <label>No. of Beds</label>

                      <Select
                        placeholder={"No. of Beds"}
                        styles={customStyles}
                        value={noBeds}
                        components={{ DropdownIndicator }}
                        options={numOptionsObj}
                        onChange={(e) => {
                          setNoBeds(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                {type !== undefined && type === "properties" && (
                  <Col lg={4} xl={3}>
                    <Form.Group className="input-div">
                      <label>No. of Guests</label>
                      <Select
                        placeholder={"No. of Guests"}
                        styles={customStyles}
                        value={noGuests}
                        components={{ DropdownIndicator }}
                        options={numOptionsObj}
                        onChange={(e) => {
                          setNoGuests(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col lg={4} xl={3}>
                  <Form.Group className="input-div">
                    <label>Price Range</label>
                    <Slider
                      range
                      min={0}
                      max={mp}
                      value={priceRange}
                      onChange={handlePriceRangeChange}
                      marks={marks}
                      step={20}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xl={4} xxl={3}>
              <div className="btn-section">
                <CustomButton
                  classname="srch-btn"
                  type="button"
                  name="Search"
                  filled={true}
                  primary={true}
                  onClick={() => handleSearch()}
                />

                <CustomButton
                  classname="srch-btn"
                  type="button"
                  name="Clear"
                  filled={true}
                  primary={true}
                  onClick={() => handleClear()}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = ({ app = {}, event }) => {
  const eventCategoryLoading = _.get(app, "getEventCategoriesLoading", false);
  const eventCategorties = _.get(app, "getEventCategoriesSuccess", undefined);
  const eventCategoryFail = _.get(app, "getEventCategoriesFailure", undefined);
  const locationsLoading = _.get(app, "getLocationsLoading", false);
  const allLocations = _.get(app, "getLocationsSuccess", undefined);
  const locationError = _.get(app, "getLocationsFailure", undefined);
  const searchTitleAsync = _.get(event, "titleForSearch", undefined);
  const searchLocationAsync = _.get(event, "locationForSearch", undefined);
  const searchCategoryAsync = _.get(event, "categoryForSearch", undefined);
  return {
    eventCategoryLoading,
    eventCategorties,
    eventCategoryFail,
    locationsLoading,
    allLocations,
    locationError,
    searchTitleAsync,
    searchLocationAsync,
    searchCategoryAsync,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllEventCategories: () => dispatch({ type: "getEventCategorySagaCalled" }),
  getLocations: () => dispatch({ type: "getLocationSagaCalled" }),
  callSearchSaga: (data) =>
    dispatch({ type: "searchPageSagaCalled", payload: data }),
  getCountAsync: (data) =>
    dispatch({ type: "getCountForSearchSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
