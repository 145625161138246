import React from 'react';
import WhatsAppSupport from '@components/whatsapp-support/WhatsappSupport';

const WhatsAppLayout = ({ children }) => {
  return (
    <div>
      {children}
      <WhatsAppSupport />
    </div>
  );
};

export default WhatsAppLayout;
