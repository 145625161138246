import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "@components/navbar/Navbar";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import CustomButton from "@components/custom-button/CustomButton";
import logo from "@images/logo.svg";
import "@features/email-verification/EmailVerificationStyles.scss";

function EmailVerification() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const [confirmEmailLoaded, setConfirmEmailLoaded] = useState(false);
  const [confirmEmailMessage, setConfirmEmailMessage] = useState();
  const [status, setStatus] = useState();

  const getConfirmEmail = async () => {
    setConfirmEmailLoaded(true);
    try {
      let result = await axios.get(
        `${process.env.RAZZLE_APP_APILINK}/auth/confirm?email=${query.get(
          "email"
        )}&token=${query.get("token")}`
      );
      setStatus(true);
      setConfirmEmailMessage(result.data.message);
      setConfirmEmailLoaded(false);
    } catch (err) {
      setStatus(false);
      setConfirmEmailMessage(err.response.data.message);
      setConfirmEmailLoaded(false);
    }
  };

  useEffect(() => {
    getConfirmEmail();
  }, []);


  const redirectHandler =() =>{
    if ((query.get("userRole") === "user")){
      location.href =`${process.env.RAZZLE_APP_APPLINK}/login`;
    }else{
      location.href =  `${process.env.RAZZLE_APP_APPOPTLINK}/#/signin`;
    }
  }

  return (
    <div id="emailverification">
      <Navbar />
      {confirmEmailLoaded && <Loader />}

      <Container>
        <div className="title">
          <span>Email Verification</span>
        </div>
        <img src={logo} className="brandlogo" />
        {status !== undefined && status === true ? (
          <>
            <div className="msg">
              <span>{confirmEmailMessage}</span>
            </div>
            <div className="buttonstyle">
              <CustomButton
                type="button"
                name="Click Here to Login"
                filled={true}
                primary={true}
                classname="login-btn"
                onClick={() =>
                  redirectHandler()
                }
              />
            </div>
          </>
        ) : (
          <div className="msg">
            <span>{confirmEmailMessage}</span>
          </div>
        )}
      </Container>
    </div>
  );
}

export default EmailVerification;
