import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  eventDetailsFail,
  eventDetailsStart,
  eventDetailsSuccess,
  eventFaqsFail,
  eventFaqsStart,
  eventFaqsSuccess,
  bookEventLoadingStart,
  bookEventSuccess,
  bookEventFailure,
  bookingHistoryLoadingStart,
  bookinhHistorySuccess,
  bookingHistoryFailure,
  bookingHistoryDetailLoadingStart,
  bookingHistoryDetailFailure,
  bookinhHistoryDetailSuccess,
  getCountForSearchLoadingStart,
  getCountForSearchSuccess,
  getCountForSearchFailure,
  eventItinerariesStart,
  eventItinerariesSuccess,
  eventItinerariesFailure,
  getCountForBookingLoadingStart,
  getCountForBookingSuccess,
  getCountForBookingFailure,
  getBookingEventDetailsLoadingStart,
  getBookingEventDetailSuccss,
  getBookingEventDetailsError,
  eventTicketStart,
  eventTicketSuccess,
  eventTicketFailure,
  villDetailsLoadingStart,
  villaDetailsSuccess,
  villaDetailsFailure,
  villaFacilityLoadingStart,
  villaFacilitySuccess,
  villaFacilityFailure,
  villaBookingLoadingStart,
  villaBookingSuccess,
  villaBookingFailure,
  afterVillaBookingDetailsLoadingStart,
  afterVillaBookingDetailsSuccess,
  afterVillaBookingDetailsFailure,
  tourBookingLoadingstart,
  tourBookingSuccess,
  tourBookingFailure,
  validateCouponCodeStart,
  validateCouponCodeSuccess,
  validateCouponCodeFailure,
  bookingDetailsForGuestLoadingStart,
  bookingDetailsForGuestSuccess,
  bookingDetailsForGuestFailure,
  alreadyBookedDatesLoadingStart,
  alreadyBookedDatesSuccess,
  alreadyBookedDatesFailure,
} from "./eventredux";
import { getAccessToken } from "@store/utilities";

export function* getBookingDetailsForGuestSaga(api, { payload }) {
  yield put(bookingDetailsForGuestLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.BookingDetailsForGuest,
      payload,
      true
    );
    yield put(bookingDetailsForGuestSuccess(result));
  } catch (e) {
    yield put(bookingDetailsForGuestFailure(e.message));
  }
}

export function* getBookedDatesSaga(api, { payload }) {
  yield put(alreadyBookedDatesLoadingStart());
  try {
    let token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.getBookedDates,
      { payload, token },
      true
    );

    yield put(alreadyBookedDatesSuccess(result));
  } catch (e) {
    yield put(alreadyBookedDatesFailure(e.message));
  }
}

export function* getEventDetails(api, { payload }) {
  yield put(eventDetailsStart(true));
  // console.log("called saga");

  try {
    let data;
    if (payload.urlSlug && payload.urlSlug === true) {
      // console.log("inside url saga");
      delete payload["urlSlug"];
      data = yield call(
        Api.callServer,
        api.getEventDetailsFromSlug,
        payload,
        true
      );
    } else {
      const { eventId } = payload;
      data = yield call(Api.callServer, api.getEventDetails, { eventId }, true);
    }

    yield put(eventDetailsSuccess(data));
  } catch (e) {
    yield put(eventDetailsFail(e.message));
  }
}

export function* getFaqs(api, { payload }) {
  yield put(eventFaqsStart(true));
  const { eventId } = payload;
  try {
    const result = yield call(Api.callServer, api.getFaqs, { eventId }, true);
    yield put(eventFaqsSuccess(result));
  } catch (e) {
    yield put(eventFaqsFail(e.message));
  }
}
export function* bookEventSaga(api, { payload }) {
  yield put(bookEventLoadingStart());

  try {
    let result;
    let { guest } = payload;
    delete payload["guest"];
    if (guest === false) {
      result = yield call(
        Api.callServer,
        api.bookEventsTakeAttendees,
        payload,
        true
      );
    } else {
      result = yield call(Api.callServer, api.BookEventForGuest, payload, true);
    }
    // if(guest === false){

    yield put(bookEventSuccess(result));
    // }else{
    //   const formatedResult = {
    //     booking : result.booking.booking,
    //     guest : result.guest,
    //   }
    //   yield put(bookEventSuccess(formatedResult))
    // }

    // yield put(bookEventSuccess(result));
  } catch (e) {
    yield put(bookEventFailure(e.message));
  }
}

export function* BookingHistoryDetailsSaga(api, { payload }) {
  yield put(bookingHistoryLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getBookingHistoryDetails,
      payload,
      true
    );

    yield put(bookinhHistorySuccess(result));
  } catch (e) {
    yield put(bookingHistoryFailure(e.message));
  }
}

export function* BookingHistoryIndividualDetailsSaga(api, { payload }) {
  yield put(bookingHistoryDetailLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.bookedHistoryDetails,
      payload,
      true
    );

    yield put(bookinhHistoryDetailSuccess(result));
  } catch (e) {
    yield put(bookingHistoryDetailFailure(e.message));
  }
}

export function* GetCountSearchSaga(api, { payload }) {
  yield put(getCountForSearchLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getCountForsearchPage,
      payload,
      true
    );

    yield put(getCountForSearchSuccess(result));
  } catch (e) {
    console.log(e);
    yield put(getCountForSearchFailure(e.message));
  }
}

export function* GetCountBookingSaga(api, { payload }) {
  yield put(getCountForBookingLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.bookingHistoryCount,
      payload,
      true
    );

    yield put(getCountForBookingSuccess(result));
  } catch (e) {
    yield put(getCountForBookingFailure(e.message));
  }
}
export function* eventItinerariesSaga(api, { payload }) {
  yield put(eventItinerariesStart());
  try {
    const result = yield call(
      Api.callServer,
      api.eventItineraries,
      payload,
      true
    );
    yield put(eventItinerariesSuccess(result));
  } catch (err) {
    yield put(eventItinerariesFailure(err.message));
  }
}

export function* GetBookingEventDetails(api, { payload }) {
  yield put(getBookingEventDetailsLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getEventDetails,
      payload,
      true
    );
    yield put(getBookingEventDetailSuccss(result));
  } catch (e) {
    yield put(getBookingEventDetailsError(e.message));
  }
}

export function* GetEventTickets(api, { payload }) {
  yield put(eventTicketStart());
  try {
    const result = yield call(Api.callServer, api.eventTickets, payload, true);
    yield put(eventTicketSuccess(result));
  } catch (err) {
    yield put(eventTicketFailure(err.message));
  }
}

export function* VillaDetailsSaga(api, { payload }) {
  yield put(villDetailsLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getVillaDetails,
      payload,
      true
    );
    yield put(villaDetailsSuccess(result));
  } catch (e) {
    yield put(villaDetailsFailure(e.message));
  }
}

export function* VillaFacilitySaga(api, { payload }) {
  yield put(villaFacilityLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getVillaFacilities,
      payload,
      true
    );
    yield put(villaFacilitySuccess(result));
  } catch (e) {
    yield put(villaFacilityFailure(e.message));
  }
}

export function* BookVillaSaga(api, { payload }) {
  yield put(villaBookingLoadingStart());
  try {
    let result;
    let { guest } = payload;
    delete payload["guest"];
    if (guest === false) {
      result = yield call(Api.callServer, api.bookVillaApi, payload, true);
    } else {
      result = yield call(Api.callServer, api.BookVillaForGuest, payload, true);
    }
    // if(guest === false){

    yield put(villaBookingSuccess(result));
    // }else{
    //   const formatedResult = {
    //     booking : result.booking.booking,
    //     guest : result.guest,
    //   }
    //   yield put(villaBookingSuccess(formatedResult))
    // }
    // yield put(villaBookingSuccess(result));
  } catch (e) {
    yield put(villaBookingFailure(e.message));
  }
}

export function* GetAfterBookingVillaDetailsSaga(api, { payload }) {
  yield put(afterVillaBookingDetailsLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetUserVillaBookingDetails,
      payload,
      true
    );
    yield put(afterVillaBookingDetailsSuccess(result));
  } catch (e) {
    yield put(afterVillaBookingDetailsFailure(e.message));
  }
}

export function* TourBookingSaga(api, { payload }) {
  yield put(tourBookingLoadingstart());
  //console.log("payloadddddd", payload);
  try {
    let result;
    let { guest } = payload;
    delete payload["guest"];
    if (guest === false) {
      result = yield call(Api.callServer, api.TourBooking, payload, true);
    } else {
      result = yield call(Api.callServer, api.BookTourForGuest, payload, true);
    }
    // if(guest === false){

    yield put(tourBookingSuccess(result));
    // }else{
    //   const formatedResult = {
    //     booking : result.booking.booking,
    //     guest : result.guest,
    //   }
    //   yield put(tourBookingSuccess(formatedResult))
    // }
  } catch (e) {
    yield put(tourBookingFailure(e.message));
  }
}

export function* ValidateCouponCodeSaga(api, { payload }) {
  yield put(validateCouponCodeStart());
  try {
    let result;
    if (payload.guestCalling === false) {
      delete payload["guestCalling"];
      const { eventId, couponCode } = payload;
      const accessToken = yield getAccessToken();
      result = yield call(
        Api.callServer,
        api.ValidateCouponCode,
        { eventId, couponCode, accessToken },
        true
      );
    } else {
      delete payload["guestCalling"];
      result = yield call(
        Api.callServer,
        api.CouponSupportForGuest,
        payload,
        true
      );
    }
    // console.log("COUPON CODE VALIDATION RESULT -> ", result);
    yield put(validateCouponCodeSuccess(result));
  } catch (err) {
    yield put(validateCouponCodeFailure(err.message));
  }
}
