import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import Hide from "@images/hide.png";
import IconPassword from "@images/IconPassword.png";
import "@components/input-field/InputFieldStyle.scss";

const InputField = ({
  isInvalid,
  required,
  disabled,
  value,
  type,
  placeholder,
  error,
  minlength,
  onChange,
  onFocus,
  onBlur,
  as,
  rows,
  onClick,
  classname,
  min,
  link,
  max,
  onPress,
  linktext,
  normtext,
  text,
  eye,
  showeye,
  setshow,
  name,
}) => {
  return (
    <div className="InputField">
      <Form.Group className="area">
        <Form.Control
          as={as}
          rows={rows}
          className={`input ${classname}`}
          value={value}
          autoCorrect={false}
          type={type}
          onWheel={(e) => e.target.blur()}
          maxLength={minlength}
          required={required}
          isInvalid={isInvalid}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="nope"
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          onClick={onClick}
          min={min}
          max={max}
          name={name}
        />
        {link && (
          <span onClick={onPress} className="linktxt" target="_blank">
            {linktext}
          </span>
        )}
        {text && (
          <span className="txtclass" target="_blank">
            {normtext}
          </span>
        )}
        {eye && (
          <img
            onClick={setshow}
            src={showeye ? Hide : IconPassword}
            className="passwordIcon"
            alt=""
          />
        )}
        <Form.Control.Feedback type="invalid">
          {error === undefined ? "Invalid" : `${error}`}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default InputField;
