import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { clearBackendError } from "@store/eventredux";
import Navbar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import BookingHistoryCard from "@components/booking-history-card/BookingHistoryCard";
import Historytab from "@components/tab/Tab";
import Loaders from "@components/full-screen-loader/FullScreenLoader";
import { ReactComponent as NoData } from "@images/nodata.svg";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import "@features/booking-history/BookingHistoryStyles.scss";
import * as _ from "lodash";

function BookingHistory({
  bookingHistory,
  bookhistoryLoading,
  bookingHistortDetails,
  bookingHistoryBackendError,
  userLoggedIn,
  clearBackend,
  countLoading,
  counts,
  countError,
  getCountsForBooking,
}) {
  const hasWindow = typeof window !== "undefined";

  const [tableno, settableno] = useState("first");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [details, setDetails] = useState([]);
  const [pages, setpages] = useState(0);
  useEffect(() => {
    if (tableno === "first" && userLoggedIn !== undefined) {
      bookingHistory({
        bookingStatus: "successful",
        skip,
        limit,
        token: userLoggedIn.accessToken,
      });
      getCountsForBooking({
        bookingStatus: "successful",

        token: userLoggedIn.accessToken,
      });
    }
  }, []);
  useEffect(() => {
    if (tableno === "second" && userLoggedIn !== undefined) {
      bookingHistory({
        bookingStatus: "pending",
        skip: 0,
        limit,
        token: userLoggedIn.accessToken,
      });
      getCountsForBooking({
        bookingStatus: "pending",

        token: userLoggedIn.accessToken,
      });
    } else if (tableno === "first" && userLoggedIn !== undefined) {
      bookingHistory({
        bookingStatus: "successful",
        skip: 0,
        limit,
        token: userLoggedIn.accessToken,
      });
      getCountsForBooking({
        bookingStatus: "successful",

        token: userLoggedIn.accessToken,
      });
    } else if (tableno === "third" && userLoggedIn !== undefined) {
      bookingHistory({
        bookingStatus: "failed",
        skip: 0,
        limit,
        token: userLoggedIn.accessToken,
      });
      getCountsForBooking({
        bookingStatus: "failed",

        token: userLoggedIn.accessToken,
      });
    }
    setpages(0);
  }, [tableno]);

  useEffect(() => {
    if (bookingHistortDetails !== undefined) {
      setDetails(bookingHistortDetails);
    }
  }, [bookingHistortDetails]);

  useEffect(() => {
    if (bookingHistoryBackendError !== undefined) {
      if (alert(`Error occured -> ${bookingHistoryBackendError}`)) {
        clearBackend();
      } else {
        clearBackend();
      }
    }
  }, [bookingHistoryBackendError]);

  return (
    <div id="bookinghistory">
      <Navbar />
      <Container>
        {bookhistoryLoading && <Loaders />}
        <div className="mb-5">
          <div className="leftalign">
            <span className="title">Booking History</span>
          </div>
          <div className="tabcontainer">
            <Historytab
              page={"history"}
              tableno={tableno}
              changeTab={(e) => settableno(e)}
            />
          </div>
          <Row>
            {details !== undefined && details.length > 0 ? (
              details.map((item) => (
                <Col lg={6}>
                  <BookingHistoryCard data={item} />
                </Col>
              ))
            ) : (
              <div>{hasWindow && <NoData className="no-data" />}</div>
            )}
          </Row>
          <Row>
            <Col>
              {counts !== undefined &&
                counts.count !== undefined &&
                counts.count > 10 && (
                  <div className="paginationsection">
                    <span className="noofpagetxt">
                      Showing {pages + 1} From {Math.ceil(counts.count / limit)}
                    </span>
                    <div className="paginationallignment">
                      <ReactPaginate
                        previousLabel={
                          <PreviousIcon className="paginationicon" />
                        }
                        nextLabel={<NextIcon className="paginationicon" />}
                        breakLabel={"..."}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        breakClassName={"break-me"}
                        pageCount={counts.count / limit}
                        forcePage={pages}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={(e) => {
                          setSkip(e.selected * limit);

                          if (
                            tableno === "second" &&
                            userLoggedIn !== undefined
                          ) {
                            bookingHistory({
                              bookingStatus: "pending",
                              skip: e.selected * limit,
                              limit,
                              token: userLoggedIn.accessToken,
                            });
                          } else if (
                            tableno === "first" &&
                            userLoggedIn !== undefined
                          ) {
                            bookingHistory({
                              bookingStatus: "successful",
                              skip: e.selected * limit,
                              limit,
                              token: userLoggedIn.accessToken,
                            });
                          } else if (
                            tableno === "third" &&
                            userLoggedIn !== undefined
                          ) {
                            bookingHistory({
                              bookingStatus: "failed",
                              skip: e.selected * limit,
                              limit,
                              token: userLoggedIn.accessToken,
                            });
                          }
                          setpages(e.selected);
                        }}
                      />
                    </div>
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </Container>
      <MainFooter />
    </div>
  );
}
const mapStateToProps = ({ event, user }) => {
  const bookhistoryLoading = _.get(event, "bookingHistoryLoading", false);
  const bookingHistortDetails = _.get(event, "bookingHistory", undefined);
  const bookingHistoryBackendError = _.get(
    event,
    "bookingHistoryError",
    undefined
  );
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const countLoading = _.get(event, "getCountForBookingLoading", false);
  const counts = _.get(event, "getCountBooking", undefined);
  const countError = _.get(event, "getCountForBookingError", undefined);
  return {
    bookhistoryLoading,
    bookingHistortDetails,
    bookingHistoryBackendError,
    userLoggedIn,
    countLoading,
    counts,
    countError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  bookingHistory: (data) =>
    dispatch({ type: "bookingHistoryDetailsSagaCalled", payload: data }),
  getCountsForBooking: (data) =>
    dispatch({ type: "getCountForBookingSagaCalled", payload: data }),
  clearBackend: () => dispatch(clearBackendError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
