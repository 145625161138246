import React, { useEffect } from "react";
import * as _ from "lodash";
import { Container } from "react-bootstrap";
import Navbar from "@components/navbar/Navbar";
import CustomButton from "@components/custom-button/CustomButton";
import logo from "@images/logo.svg";
import "@features/reset-password-success/ResetPasswordSuccessStyles.scss";
import { useLocation } from "react-router-dom";


function ResetPasswordSuccess() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  
  const redirectHandler =() =>{
    if ((query.get("userRole") === "user")){
      location.href = `${process.env.RAZZLE_APP_APPLINK}/login`;
    }else if ((query.get("userRole") === "operator")){
      location.href = `${process.env.RAZZLE_APP_APPOPTLINK}/#/signin`;
    }else{
      location.href = `${process.env.RAZZLE_APP_APPADMINLINK}/#/signin`;
    }
  }
  return (
    <div id="resetpasswordsuccess">
      <Navbar />
      <Container>
        <div className="title">
          <span>Reset Password</span>
        </div>
        <img src={logo} className="brandlogo" />

        <div className="msg">
          <span>Password Updated Successfully!</span>
        </div>

        <div className="buttonstyle">
          <CustomButton
            type="button"
            name="Click Here to Login"
            filled={true}
            primary={true}
            classname="login-btn"
            onClick={() => {
              redirectHandler()
              
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default ResetPasswordSuccess;
