import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "@components/explore-experiences/ExploreExperiencesStyle.scss";
import { connect } from "react-redux";
import {
  locationForSearchLoading,
  titleForSearchLoading,
  clearLoacationTitleForSearch,
  categoryForSearchLoading,
  clearLocationForSearch,
  triedForCategorySearch,
  triedForLocationSearch,
} from "@store/eventredux";
import * as _ from "lodash";

const ExploreExperiences = ({
  categories,
  categoryForSearchAsync,
  categorySearchTrialAsync,
}) => {
  const history = useHistory();

  return (
    <div className="ExploreExperiences">
      <div className="heading">
        <span>Explore Experiences</span>
      </div>
      <Row>
        {_.isArray(categories) &&
          categories !== undefined &&
          categories.map((data, ind) => (
            <Col lg={3} md={4} sm={6} key={`explore${data.id}`}>
              <div
                className="exp-div"
                key={ind}
                onClick={() => {
                  if (data.id == 8) {
                    history.push("/searchLuxuryCars");
                  } else if (data.id == 2) {
                    history.push("/searchPropertiesTours");
                  } else {
                    history.push("/allevents");
                    categoryForSearchAsync(data.title);
                    categorySearchTrialAsync(true);
                  }
                }}
              >
                <span className="exp-text">{data.title}</span>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  categoryForSearchAsync: (data) => dispatch(categoryForSearchLoading(data)),
  categorySearchTrialAsync: (data) => dispatch(triedForCategorySearch(data)),
});

export default connect(null, mapDispatchToProps)(ExploreExperiences);
