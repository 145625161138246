import apisauce from "apisauce";

const baseUrl = process.env.RAZZLE_APP_APILINK;

const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  });

  const getLocationForHomePageApi = () => {
    return apis.get("/events/destinations");
  };
  const adminEventcategory = () => {
    return apis.get("/admin/event-categories");
  };
  const getAllCategoryEvents = (data) => {
    const { skip, limit, category } = data;
    let categoryId = category.id;
    if (categoryId === 2) {
      return apis.get(
        `/events?where={ "status": "active","featured":true, "adminApproved":true , "type" : "villa"}&order={ "id": "DESC"}&skip=${skip}&limit=${limit}&eventCategoryId=${categoryId}&relations=[ "availabilities"  ]`
      );
    } else if (categoryId === 8) {
      return apis.get(
        `/events?where={ "status": "active","featured":true, "adminApproved":true , "type" : "luxuryCar"}&order={ "id": "DESC"}&skip=${skip}&limit=${limit}&eventCategoryId=${categoryId}&relations=[ "availabilities"  ]`
      );
    }
    return apis.get(
      `/events?where={ "status": "active","featured":true, "adminApproved":true}&order={ "id": "DESC"}&skip=${skip}&limit=${limit}&eventCategoryId=${categoryId}&relations=[ "availabilities" ]`
    );
  };

  const becomePartnerCall = ({ email }) => {
    return apis.post("/becomePartner", { email: email });
  };

  const login = ({ email, password }) => {
    return apis.post("/auth/signin", { email: email, password: password });
  };

  const getMyProfile = ({ accessToken }) => {
    return apis.get(
      "/users/my-profile",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };
  const signUpUser = (data) => {
    return apis.post("/auth/signup", data);
  };

  const requestPasswordReset = ({ email }) => {
    return apis.post("/auth/request-password-reset", { email });
  };

  const operatorSignUp = ({ email, mobile, name, password }) => {
    return apis.post("/auth/register-as-operator", {
      email: email,
      mobile: mobile,
      name: name,
      password: password,
    });
  };
  const searchEvents = ({
    skip,
    limit,
    category,
    title,
    destination,
    specialCase,
    scopeType,
    categoryId,
    destinationId,
    type,
    location,
    maxPrice,
    minPrice,

    noOfBedrooms,
    noOfBathrooms,
    noOfBeds,
    guestsNumber,
  }) => {
    let link = "",
      Like = "";
    if (destinationId) link += `destinationId=${destinationId}&`;
    if (categoryId) link += `eventCategoryId=${categoryId}&`;
    if (title != "") Like += `&Like={"title":"${title}"}`;
    if (location) link += `location=${location}&`;
    if (maxPrice) link += `maxPrice=${Number(maxPrice)}&`;
    if (minPrice) link += `minPrice=${Number(minPrice)}&`;

    let where = { status: "active", adminApproved: true };
    if (noOfBedrooms && noOfBedrooms != "")
      link += `noOfBedrooms=${Number(noOfBedrooms)}&`;
    if (noOfBathrooms && noOfBathrooms != "")
      link += `noOfBathrooms=${Number(noOfBathrooms)}&`;
    if (guestsNumber && guestsNumber != "")
      link += `guestsNumber=${Number(guestsNumber)}&`;
    if (noOfBeds && noOfBeds != "") link += `noOfBeds=${Number(noOfBeds)}&`;
    if (type) where.type = type;
    return apis.get(
      `events?where=${JSON.stringify(
        where
      )}&${link}skip=${skip}&limit=${limit}&relations=["availabilities"]&order={"createdOn": "DESC"}${Like}`
    );
  };

  const getEventDetails = ({ eventId }) => {
    return apis.get(
      `/events/${eventId}?relations=["user", "eventImages", "tags" , "eventDates" , "ticketTypes"  ,"availabilities" , "destinations"]`
    );
  };
  const getEventDetailsFromSlug = (data) => {
    //console.log("data for urlslug api", data);
    return apis.get(
      `/events/slug/urlSlug?slug=${data.slugVal}&relations=["user", "eventImages", "tags" , "eventDates" , "ticketTypes"  ,"availabilities"  ,"destinations"]`
    );
  };

  const getFaqs = ({ eventId }) => {
    return apis.get(`/events/${eventId}/faqs`);
  };

  const userProfile = ({ token }) => {
    return apis.get(
      `/users/my-profile`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const joinAsOperator = ({ token }) => {
    return apis.post(
      `/users/operator/join`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const resendEmailVerification = ({ accessToken }) => {
    return apis.get(
      "/auth/resendverificationemail",
      {},
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  };

  const changePassword = ({ token, data }) => {
    return apis.post(`/auth/change-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const bookEventsTakeAttendees = (data) => {
    const { tokenId } = data;
    delete data["tokenId"];
    return apis.post("/bookings", data, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
  };

  const changeProfileImage = ({ token, data }) => {
    const formData = new FormData();
    formData.append("image", data.raw);
    return apis.post(`/users/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const editProfileData = ({ token, data }) => {
    // const formData = new FormData()
    // formData.append("name" , data.name)
    return apis.patch(`/users/profile`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getBookingHistoryDetails = ({ bookingStatus, skip, limit, token }) => {
    return apis.get(
      `/users/bookings?where={"bookingStatus": "${bookingStatus}"}&relations=["user","bmtEvent", "travellers", "payment"]&order={ "updatedOn": "DESC"}&skip=${skip}&limit=${limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const bookedHistoryDetails = ({ id, token }) => {
    return apis.get(
      `/bookings/${id}?relations=["luxuryCarBookings" , "villaBookings"]`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getCountForsearchPage = ({
    title,
    categoryId,
    destinationId,
    type,
    location,
    maxPrice,
    minPrice,
    noOfBedrooms,
    noOfBathrooms,
    noOfBeds,
    guestsNumber,
  }) => {
    let link = "",
      Like = "";
    if (destinationId) link += `destinationId=${destinationId}&`;
    if (categoryId) link += `eventCategoryId=${categoryId}&`;
    if (location) link += `location=${location}&`;
    if (maxPrice) link += `maxPrice=${Number(maxPrice)}&`;
    if (minPrice) link += `minPrice=${Number(minPrice)}&`;
    if (noOfBedrooms && noOfBedrooms != "")
      link += `noOfBedrooms=${Number(noOfBedrooms)}&`;
    if (noOfBathrooms && noOfBathrooms != "")
      link += `noOfBathrooms=${Number(noOfBathrooms)}&`;
    if (guestsNumber && guestsNumber != "")
      link += `guestsNumber=${Number(guestsNumber)}&`;
    if (noOfBeds && noOfBeds != "") link += `noOfBeds=${Number(noOfBeds)}&`;

    if (title != "") Like += `&Like={"title":"${title}"}`;
    let where = { status: "active", adminApproved: true };
    if (type) where.type = type;
    return apis.get(
      `events/count?where=${JSON.stringify(
        where
      )}&${link}relations=["availabilities"]&order={"createdOn": "DESC"}${Like}`
    );
  };
  const eventItineraries = ({ eventId }) => {
    return apis.get(`events/${eventId}/itineraries`);
  };
  const bookingHistoryCount = ({ bookingStatus, token }) => {
    return apis.get(
      `/users/bookings/count?relations=["user","bmtEvent", "travellers", "payment"]&order={ "updatedOn": "DESC"}&where={"bookingStatus":"${bookingStatus}"}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const eventTickets = ({ id }) => {
    return apis.get(`/ticket/${id}`);
  };

  const getVillaDetails = ({ id, type }) => {
    if (type !== undefined && type == "luxuryCar") {
      return apis.get(`/luxuryCar/luxuryCarDetails/${id}`);
    } else {
      return apis.get(`/villa/villaDetails/${id}`);
    }
  };
  const getVillaFacilities = ({ id, type }) => {
    if (type !== undefined && type == "luxuryCar") {
      return apis.get(`/luxuryCar//luxuryCarFacilities/${id}`);
    } else {
      return apis.get(`/villa//villaFacilities/${id}`);
    }
  };

  const bookVillaApi = (data) => {
    const { id, token, type } = data;
    // console.log("token", token);
    delete data["id"];
    delete data["token"];

    if (type !== undefined) {
      delete data["type"];
      return apis.post(`/luxuryCar/luxuryCarBooking/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return apis.post(`/villa/villaBooking/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  const GetUserVillaBookingDetails = ({ token, id }) => {
    return apis.get(
      `/villa/villaBookings/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const TourBooking = (data) => {
    const { id, token } = data;
    delete data["id"];
    delete data["token"];
    return apis.post(`/tour/booking/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const ValidateCouponCode = ({ eventId, couponCode, accessToken }) => {
    return apis.post(
      `/couponController/validateAndGetCouponCodeDetails/${eventId}`,
      { couponCode: couponCode },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  const GetHomeBannerImages = () => {
    return apis.get(`/homeSiteBannerImages`);
  };
  const GoogleAuthApi = (data) => {
    return apis.post(`/google/checkUserDetails`, data);
  };
  const GuestForm = (data) => {
    return apis.post(`/auth/guestSignUp`, data);
  };
  const BookEventForGuest = (data) => {
    //   console.log("data for tour api", data);
    delete data["data.guest"];
    return apis.post(`/bookings/createBookingForGuest`, data);
  };
  const BookTourForGuest = (data) => {
    //console.log("data for tour api", data);
    const { id, bookingDetails } = data;

    delete bookingDetails["bookingDetails.id"];
    delete bookingDetails["bookingDetails.guest"];
    delete data["id"];

    return apis.post(`/tour/createTourBookingForGuest/${id}`, data);
  };
  const BookVillaForGuest = (data) => {
    //console.log("data for tour api", data);
    const { id, bookingDetails, type } = data;

    delete bookingDetails["bookingDetails.id"];
    delete bookingDetails["bookingDetails.guest"];
    delete data["id"];
    delete data["type"];
    if (type !== undefined && type == "luxuryCar") {
      return apis.post(`/luxuryCar/createLuxuryCarBookingForGuest/${id}`, data);
    } else {
      return apis.post(`/villa/createVillaBookingForGuest/${id}`, data);
    }
  };

  const BookingDetailsForGuest = (data) => {
    return apis.get(
      `/bookings/guestBooking/${data.id}?relations=["villaBookings" , "user" ,"bmtEvent","luxuryCarBookings"]`
    );
  };

  const CouponSupportForGuest = (data) => {
    const { eventId } = data;
    delete data["eventId"];
    return apis.post(
      `/couponController/validateAndGetCouponCodeDetailsForGuest/${eventId}`,
      data
    );
  };

  const getBookedDates = (data) => {
    return apis.get(
      `/${data.payload.type}/bookedDates/${data.payload.id}?startDate=${data.payload.startDate}&endDate=${data.payload.endDate}`,
      {},
      {
        headers: {
          authorization: `Bearer ${data.accessToken}`,
        },
      }
    );
  };

  return {
    BookingDetailsForGuest,
    BookEventForGuest,
    BookTourForGuest,
    BookVillaForGuest,
    GuestForm,
    getLocationForHomePageApi,
    adminEventcategory,
    getAllCategoryEvents,
    becomePartnerCall,
    login,
    getMyProfile,
    signUpUser,
    requestPasswordReset,
    operatorSignUp,
    searchEvents,
    getEventDetails,
    getEventDetailsFromSlug,
    getFaqs,
    userProfile,
    joinAsOperator,
    resendEmailVerification,
    changePassword,
    bookEventsTakeAttendees,
    changeProfileImage,
    editProfileData,
    getBookingHistoryDetails,
    bookedHistoryDetails,
    getCountForsearchPage,
    eventItineraries,
    bookingHistoryCount,
    eventTickets,
    getVillaDetails,
    getVillaFacilities,
    bookVillaApi,
    GetUserVillaBookingDetails,
    TourBooking,
    GetHomeBannerImages,
    GoogleAuthApi,
    ValidateCouponCode,
    CouponSupportForGuest,
    getBookedDates,
    //searchEventsBasedOnTitleAndLocation,
  };
};

export default {
  create,
};
