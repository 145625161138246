import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ReactComponent as NoData } from "@images/nodata.svg";
import "@components/searchpage-cards/SearchPageCardsStyle.scss";

const SearchPageCards = ({ dataItem }) => {
  const history = useHistory();
  const hasWindow = typeof window !== "undefined";

  return (
    <>
      <div className="SearchPageCards">
        <Row>
          {dataItem !== undefined && dataItem.length > 0 ? (
            dataItem.map((item) => {
              if (item.deletedOn === null) {
                return (
                  <Col xl={3} lg={4} md={6} key={`cardsearch${item.id}`}>
                    <div
                      className="card-div"
                      onClick={() => {
                        if (item.type === "event") {
                          if (item.urlSlug === null) {
                            history.push(`/events/${item.id}`);
                          } else {
                            history.push(
                              `/events/${encodeURIComponent(item.urlSlug)}`
                            );
                          }
                        } else if (item.type === "tour") {
                          if (item.urlSlug === null) {
                            history.push(`/tourdetails/${item.id}`);
                          } else {
                            history.push(
                              `/tourdetails/${encodeURIComponent(item.urlSlug)}`
                            );
                          }
                        } else if (item.type === "luxuryCar") {
                          console.log("clicked inside luxurycar");
                          if (item.urlSlug === null) {
                            console.log("clicked inside null slug");
                            history.push(`/luxurycar/${item.id}`);
                          } else {
                            console.log("inside url slug");
                            history.push(
                              `/luxurycar/${encodeURIComponent(item.urlSlug)}`
                            );
                          }
                        } else {
                          if (item.urlSlug === null) {
                            history.push(`/properties/${item.id}`);
                          } else {
                            history.push(
                              `/properties/${encodeURIComponent(item.urlSlug)}`
                            );
                          }
                        }
                      }}
                    >
                      <Card>
                        <div className="card-img-block">
                          <img
                            className="img-fluid cover"
                            src={item.banner}
                            alt="Card image cap"
                          />
                        </div>
                        <Card.Body>
                          <div className="card-name">{item.title}</div>
                          <div className="location">{item.location}</div>
                          {item.type === "event" ? (
                            <div className="date">
                              {/* {new Date(item.startDate).toDateString()} */}
                              {moment(item.startDate).format(
                                "ddd, MMM Do"
                              )} - {moment(item.endDate).format("ddd, MMM Do")}
                            </div>
                          ) : (
                            item.type === "villa" &&
                            item.villaDetails !== undefined &&
                            item.villaDetails !== null &&
                            item.villaDetails.max_no_of_guests !== undefined &&
                            item.villaDetails.max_no_of_guests !== null && (
                              <div className="date">
                                {item.villaDetails.max_no_of_guests} Guests
                              </div>
                            )
                          )}
                          {item.type === "event" ? (
                            <div className="amount">
                              ₹ {item.amount} onwards
                            </div>
                          ) : item.type === "villa" &&
                            item.villaDetails !== undefined &&
                            item.villaDetails !== null &&
                            item.villaDetails.price_per_night !== null &&
                            item.villaDetails.price_per_night !== undefined ? (
                            <div className="amount">
                              ₹ {item.villaDetails.price_per_night} /night
                            </div>
                          ) : item.type === "luxuryCar" &&
                            item.luxuryCarDetails !== undefined &&
                            item.luxuryCarDetails !== null &&
                            item.luxuryCarDetails.price !== null &&
                            item.luxuryCarDetails.price !== undefined ? (
                            <div className="amount">
                              ₹ {item.luxuryCarDetails.price} /day
                            </div>
                          ) : (
                            <></>
                          )}
                          {item.type === "tour" && (
                            <div>
                              {/* <div className="date">
                                Travellers - {item.total}
                              </div> */}
                              <div className="amount">
                                ₹ {item.amount} onwards
                              </div>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              }
            })
          ) : (
            <div>{hasWindow && <NoData className="no-data" />}</div>
          )}
        </Row>
        {/* <Row>
          <Col>
            <div className="paginationsection">
              <div className="paginationallignment">
                <ReactPaginate
                  previousLabel={<PreviousIcon className="paginationicon" />}
                  nextLabel={<NextIcon className="paginationicon" />}
                  breakLabel={"..."}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  breakClassName={"break-me"}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default SearchPageCards;
