import React from "react";

const Loaders = () => (
  <div className="preloader" id="preloader">
    <div className="loader loader-1">
      <div className="loader-outter" />
      <div className="loader-inner" />
    </div>
  </div>
);

export default Loaders;
