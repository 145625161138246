import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { useHistory } from "react-router-dom";

import { getAccessToken } from "@store/utilities";
import {
  changePasswordFailure,
  changePasswordStart,
  changePasswordSuccess,
  changeProfileImageFailure,
  changeProfileImageStart,
  changeProfileImageSuccess,
  editProfileDataFailure,
  editProfileDataStart,
  editProfileDataSuccess,
  forgotPasswordFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  joinAsOperatorFailure,
  joinAsOperatorStart,
  joinAsOperatorSuccess,
  operatorSignUpFailure,
  operatorSignUpStart,
  operatorSignUpSuccess,
  profileDataFailure,
  profileDataStart,
  profileDataSuccess,
  resendVerificationFailure,
  resendVerificationStart,
  resendVerificationSuccess,
  searchLoadingStart,
  searchResultError,
  searchSuccess,
  setUserDetailsFailure,
  setUserDetailsStart,
  setUserDetailsSuccess,
  signInFailure,
  signInStart,
  signInSuccess,
  signUpFailure,
  signUpStart,
  signUpSuccess,
  passGoogleAccessTokenLoadingStart,
  passGoogleAccessTokenSuccess,
  passGoogleAccessTokenFailure,
  guestFormLoadingStart,
  guestFormSuccess,
  guestFormFailure,
  userLogout,
  clearUserDetails,
} from "./userredux";

export function* guestFormSaga(api, { payload }) {
  yield put(guestFormLoadingStart());
  try {
    const result = yield call(Api.callServer, api.GuestForm, payload, true);
    yield put(guestFormSuccess(result));
  } catch (e) {
    yield put(guestFormFailure(e.message));
  }
}

export function* googleAccessTokenSaga(api, { payload }) {
  // console.log("called");
  yield put(passGoogleAccessTokenLoadingStart());
  const { link, redirectTo } = payload;
  delete payload["link"];
  delete payload["redirectTo"];
  try {
    const result = yield call(Api.callServer, api.GoogleAuthApi, payload, true);

    yield put(passGoogleAccessTokenSuccess(result));
    yield call(setUserProfileInfoSaga, api, {
      accessToken: result.accessToken,
      link: link,
      redirectTo,
      loginResult: result,
    });
  } catch (e) {
    yield put(passGoogleAccessTokenFailure(e.message));
    alert(e.message);
    localStorage.clear();
  }
}

export function* setUserProfileInfoSaga(
  api,
  { accessToken, link, loginResult, redirectTo }
) {
  yield put(setUserDetailsStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getMyProfile,
      { accessToken: accessToken },
      true
    );

    if (result.emailVerified === true) {
      if (redirectTo === "bookscreen") {
        yield put(signInSuccess(loginResult));
        yield put(setUserDetailsSuccess(result));
        yield put(profileDataSuccess(result));
        link.goBack();
      } else {
        yield put(signInSuccess(loginResult));
        yield put(setUserDetailsSuccess(result));
        yield put(profileDataSuccess(result));
        link.push("/");
      }
    } else {
      yield put(signInSuccess(loginResult));
      yield put(setUserDetailsSuccess());
      link.push("/user-verification");
    }
  } catch (err) {
    yield put(setUserDetailsFailure());
  }
}

export function* userLoginSaga(api, { payload }) {
  yield put(signInStart());
  try {
    const { email, password, link, redirectTo } = payload;
    const result = yield call(
      Api.callServer,
      api.login,
      { email: email, password: password },
      true
    );

    yield call(setUserProfileInfoSaga, api, {
      accessToken: result.accessToken,
      link: link,
      redirectTo,
      loginResult: result,
    });
  } catch (err) {
    yield put(signInFailure(err.message));
  }
}

export function* userSignUpSaga(api, { payload }) {
  yield put(signUpStart());
  const { link, openSuccessPopup } = payload;
  delete payload["link"];
  delete payload["openSuccessPopup"];

  try {
    const result = yield call(Api.callServer, api.signUpUser, payload, true);
    yield put(signUpSuccess(result));
    openSuccessPopup();
    // link.push("/login");
  } catch (e) {
    yield put(signUpFailure(e.message));
  }
}

export function* requestPasswordResetSaga(api, { payload }) {
  yield put(forgotPasswordStart());
  try {
    const { email } = payload;
    const result = yield call(
      Api.callServer,
      api.requestPasswordReset,
      { email: email },
      true
    );
    yield put(forgotPasswordSuccess(result.message));
  } catch (err) {
    yield put(forgotPasswordFailure(err.message));
  }
}

export function* operatorSignUpRequestSaga(api, { payload }) {
  yield put(operatorSignUpStart());
  const { openSuccessPopup } = payload;
  delete payload["openSuccessPopup"];
  try {
    const result = yield call(
      Api.callServer,
      api.operatorSignUp,
      { ...payload },
      true
    );
    yield put(operatorSignUpSuccess(result));
    openSuccessPopup();
  } catch (err) {
    yield put(operatorSignUpFailure(err.message));
  }
}

export function* SearchPageSaga(api, { payload }) {
  yield put(searchLoadingStart());
  try {
    const result = yield call(Api.callServer, api.searchEvents, payload, true);

    yield put(searchSuccess(result));
  } catch (e) {
    yield put(searchResultError(e.error));
  }
}

export function* GetUserProfileData(api, { payload }) {
  yield put(profileDataStart(true));
  const token = yield getAccessToken();
  try {
    const result = yield call(Api.callServer, api.userProfile, { token }, true);

    yield put(profileDataSuccess(result));
  } catch (e) {
    yield put(profileDataFailure(e.error));
  } finally {
    yield put(joinAsOperatorStart(false));
  }
}

export function* joinAsOperator(api, { payload }) {
  yield put(joinAsOperatorStart(true));
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.joinAsOperator,
      { token },
      true
    );

    yield put(joinAsOperatorSuccess(result));

    const result2 = yield call(
      Api.callServer,
      api.userProfile,
      { token },
      true
    );

    yield put(profileDataSuccess(result2));
  } catch (e) {
    yield put(joinAsOperatorFailure(e.error));
  } finally {
    yield put(joinAsOperatorStart(false));
  }
}

export function* resendEmailVerificationSaga(api) {
  yield put(resendVerificationStart());
  try {
    const accessToken = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.resendEmailVerification,
      { accessToken: accessToken },
      true
    );
    yield put(resendVerificationSuccess(result));
  } catch (err) {
    yield put(resendVerificationFailure());
  }
}

export function* changePassword(api, { payload }) {
  yield put(changePasswordStart(true));
  const token = yield getAccessToken();
  let { data } = payload;
  let { onSuccess } = data;
  delete data["onSuccess"];
  try {
    const result = yield call(
      Api.callServer,
      api.changePassword,
      { token, data },
      true
    );

    yield put(changePasswordSuccess(result));
    onSuccess();
  } catch (e) {
    yield put(changePasswordFailure(e));
  } finally {
    yield put(changePasswordStart(false));
  }
}

// export function* changeProfileImage(api, { payload }) {
//   yield put(changeProfileImageStart(true));
//   const token = yield getAccessToken();
//   const { banner, history } = payload;
//   try {
//     let data = banner;
//     const result = yield call(
//       Api.callServer,
//       api.changeProfileImage,
//       { token, data },
//       true
//     );
//     yield put(changeProfileImageSuccess(result));

//     yield call(GetUserProfileData, api, { token });
//     // const result2 = yield call(Api.callServer,api.userProfile,{ token },true);
//     // yield put(profileDataSuccess(result2));
//     // console.log("result==========================", result2);
//     history.push("/myprofile");
//   }
//   catch (e) {
//     console.log(e, "================== error");
//     yield put(changeProfileImageFailure(e));
//   }
//   finally {
//     yield put(changeProfileImageStart(false));
//   }
// }

export function* editProfileData(api, { payload }) {
  const { info, banner, history } = payload;

  const token = yield getAccessToken();
  if (banner && banner.raw !== "") {
    yield put(changeProfileImageStart(true));

    try {
      let data = banner;
      const result = yield call(
        Api.callServer,
        api.changeProfileImage,
        { token, data },
        true
      );
      yield put(changeProfileImageSuccess(result));
    } catch (e) {
      yield put(changeProfileImageFailure(e.message));
    } finally {
      yield put(changeProfileImageStart(false));
    }
  }
  yield put(editProfileDataStart(true));
  const data = Object.fromEntries(
    Object.entries(info).filter(([_, v]) => v != null)
  );
  const data1 = Object.fromEntries(
    Object.entries(data).filter(([_, v]) => v != "")
  );

  try {
    let data = data1;
    const result = yield call(
      Api.callServer,
      api.editProfileData,
      { token, data },
      true
    );

    yield put(editProfileDataSuccess(result));

    const result1 = yield call(
      Api.callServer,
      api.userProfile,
      { token },
      true
    );

    yield put(profileDataSuccess(result));
    history.push("/myprofile");
  } catch (e) {
    yield put(editProfileDataFailure(e.message));
  } finally {
    yield put(editProfileDataStart(false));
  }
}

export function* logout(api, { payload = {} }) {
  try {
    //let history = useHistory()
    yield put(userLogout());
    yield put(clearUserDetails());
    window.location.href = "/login";
  } catch (error) {
    console.log(error);
  }
}
