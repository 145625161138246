import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  clearBackendError,
  resetBookingEvent,
  clearEventTickets,
  clearCouponResult,
} from "@store/eventredux";
import * as _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import BookingForm from "@components/booking-form/BookingForm";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import MainFooter from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import BookingSuccessPopup from "@components/booking-success-popup/BookingSuccessPopup";
import Navbar from "@components/navbar/Navbar";
import { ReactComponent as CalendarIcon } from "@images/calender.svg";
import { ReactComponent as ClockIcon } from "@images/clock.svg";
import { ReactComponent as LocationIcon } from "@images/location.svg";
import "@features/booking-page/BookingPageStyle.scss";
import BigNumber from "bignumber.js";
import { isNumber } from "lodash";
import { resetOperatorSignUpState } from "store/userredux";
const BN = require("bn.js");

function BookingPage({
  GetEventDetail,
  eventLoading,
  bookEventLoad,
  eventDetails,
  eventError,
  bookingAsyncCalled,
  userLoggedIn,
  userDet,
  bookEventDetails,
  refreshBookingState,
  clearBackend,
  bookEventError,
  getEventTickets,
  eventTicketsLoading,
  eventTickets,
  eventTicketsError,
  clearEventTicketsCall,
  ValidateCouponCodeAsync,
  validateCouponCodeLoading,
  validateCouponCodeResult,
  validateCouponCodeError,
  clearCouponCodeAsync,
}) {
  let { id } = useParams();
  let history = useHistory();
  const [counter, setCounter] = useState(1);
  const [eventDet, setEventDet] = useState();
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [attendeeName, setAttendeeName] = useState("");

  const [bmtId, setBmtId] = useState();
  const [eventDateId, setEventDateId] = useState();
  const [accessToken, setAccessToken] = useState("");
  const [bookingDetails, setBookingDetails] = useState();
  const [checked, setChecked] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [maxNumOfAttendee, setMaxNumOfAttendees] = useState();
  const [ticketType, setTicketTypes] = useState();
  const [numOfCounts, setNumOfCounts] = useState();
  const [ticketTypeCountMapper, setTicketTypeCountMapper] = useState({});
  const [maximumTickets, setMaximumTickets] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [ticketSaleStartdate, setTicketSaleStartDate] = useState();
  const [ticketSaleEndDate, setTicketSaleEndDate] = useState();
  const [filteredTicketTypes, setFilteredTicketTypes] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [codeEntered, setCodeEntered] = useState(false);
  const [myRedirect, setMyRedirect] = useState("");
  const [eveId, setEveId] = useState();
  const [success, setSuccess] = useState(false);
  // const params = new URLSearchParams(window.location.search);
  const [popupForGuest, setPopupForGuest] = useState(false);
  var para;
  const location2 = useLocation();

  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };
  let emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const [person, setPerson] = useState({
    userName: "",
    email: "",
    mobile: "",
  });

  const handleGuest = (e) => {
    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   if (userLoggedIn === undefined && id) {
  //     history.push(`/events/${id}`);
  //   }
  // }, []);
  useEffect(() => {
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);
  useEffect(() => {
    if (para !== undefined) {
      setMyRedirect(para);
    }
  }, [para]);
  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({
        urlSlug: true,
        slugVal: myRedirect,
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetEventDetail({ urlSlug: false, eventId: id });
      }
    }
  }, [myRedirect, id]);
  useEffect(() => {
    handleBackendCallsBasedOnIdChange();
  }, [id, eveId]);
  useEffect(() => {
    handleBackendCallsBasedOnIdChange();
    refreshBookingState();
    return () => {
      clearEventTicketsCall();
      refreshBookingState();
    };
  }, []);
  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      getEventTickets({ id: eveId });
    } else {
      if (id !== undefined) {
        getEventTickets({ id: id });
      }
    }
  };

  useEffect(() => {
    if (eventDetails !== undefined) {
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      setStartDate(eventDetails.startDate);
      setEndDate(eventDetails.endDate);
      setEveId(eventDetails.id);
      setTotalAmount(eventDetails.amount);
      setAmountDue(eventDetails.amount);
      setdescription(eventDetails.description);
      setBmtId(eventDetails.id);
      if (eventDetails.eventDates !== undefined) {
        setEventDateId(eventDetails.eventDates[0]?.id);
      }
      setMaxNumOfAttendees(eventDetails.maxGroupSize);
      setTicketTypes(eventTickets);
      setTicketSaleStartDate(eventDetails.ticketSaleStartDate);
      setTicketSaleEndDate(eventDetails.ticketSaleEndDate);
      console.log("eventDetails", eventDetails);
      console.log("eventTickets", eventTickets);
      console.log("====================================");
      console.log(maximumTickets, maxAmount);
      console.log("====================================");
    }
  }, [eventDetails, id, eveId, eventTickets]);

  // const filterTickets = () => {
  //   if (eventTickets !== undefined) {
  //     for (let i = 0; i < eventTickets.length; i++) {
  //       if (eventTickets[i].totalTicketsAvailable > 0) {
  //         if(){

  //         }
  //         setFilteredTicketTypes((prevState) => [
  //           ...prevState,
  //           eventTickets[i],
  //         ]);
  //       }
  //     }
  //   }
  // };
  useEffect(() => {
    if (eventTickets !== undefined && eventTickets.length > 0) {
      for (let i = 0; i < eventTickets.length; i++) {
        if (eventTickets[i].totalTicketsAvailable > 0) {
          setTicketTypeCountMapper((prevState) => {
            return {
              ...prevState,
              [eventTickets[i].id]: {
                count: 0,
                price: 0,
              },
            };
          });
        }
      }
    }
    //filterTickets();
    // console.log("evvnv", eventTickets);
  }, [eventTickets]);

  useEffect(() => {
    if (ticketTypeCountMapper !== undefined) {
      let arrayOfObject = Object.values(ticketTypeCountMapper);
      let totalCountForTickets = 0;
      let totalAmountOfThoseTickets = 0;
      let myValuesForBackend = [];
      for (let i = 0; i < arrayOfObject.length; i++) {
        totalCountForTickets += arrayOfObject[i].count;
        totalAmountOfThoseTickets += arrayOfObject[i].price;
      }
      setMaximumTickets(totalCountForTickets);
      setMaxAmount(totalAmountOfThoseTickets);
    }
  }, [ticketTypeCountMapper]);
  const increase = (itm) => {
    if (
      ticketTypeCountMapper !== undefined &&
      ticketTypeCountMapper[itm.id].count < itm.totalTicketsAvailable
    ) {
      if (eventTickets !== undefined && eventTickets.length > 0) {
        setTicketTypeCountMapper((prevState) => {
          return {
            ...prevState,
            [itm.id]: {
              ...ticketTypeCountMapper[itm.id],
              count: Number(ticketTypeCountMapper[itm.id].count) + 1,
              price: Number(ticketTypeCountMapper[itm.id].price) + itm.cost,
            },
          };
        });
      }
    }
    // }
  };

  const decrease = (itm) => {
    if (ticketTypeCountMapper[itm.id].count > 0) {
      if (eventTickets !== undefined && eventTickets.length > 0) {
        setTicketTypeCountMapper((prevState) => {
          return {
            ...prevState,
            [itm.id]: {
              ...ticketTypeCountMapper[itm.id],
              count: Number(ticketTypeCountMapper[itm.id].count) - 1,
              price: Number(ticketTypeCountMapper[itm.id].price) - itm.cost,
            },
          };
        });
      }

      // setTotalAmount(increaseTotal(amountDue));
    }
  };

  const totalEventBookingAmount = () => {
    if (
      maxAmount !== undefined &&
      maxAmount > 0 &&
      eventDetails !== undefined &&
      eventDetails.gstPercentage !== null &&
      eventDetails.gstPercentage !== "0.00"
    ) {
      return (
        (maxAmount * Number(eventDetails.gstPercentage)) / 100 +
        maxAmount
      ).toFixed(2);
    } else {
      if (
        maxAmount !== undefined &&
        maxAmount > 0 &&
        eventDetails !== undefined
      ) {
        return maxAmount;
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (userLoggedIn !== undefined) {
      setAccessToken(userLoggedIn.accessToken);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (
      bookEventDetails !== undefined &&
      bookEventDetails.booking !== undefined
    ) {
      if (Number(bookEventDetails.booking.totalAmount) > 0) {
        setBookingDetails(bookEventDetails);
        razorPayCall(bookEventDetails);
      } else if (Number(bookEventDetails.booking.totalAmount) == 0) {
        if (bookEventDetails.guest === null) {
          setBookingDetails(bookEventDetails);
          setShowSuccessPopup(true);
        } else {
          setBookingDetails(bookEventDetails);
          setPopupForGuest(true);
          setShowSuccessPopup(true);
        }
      }
    }
    // console.log("bookingeventdetails", bookEventDetails);
  }, [bookEventDetails]);

  const handlePaymentButtonCall = (guestCalling) => {
    if (checked === false) {
      alert("Please agree to our terms and conditions !");
    } else {
      let objectTicketIds = Object.keys(ticketTypeCountMapper);

      let finalObject = [];
      for (let i = 0; i < objectTicketIds.length; i++) {
        if (Number(ticketTypeCountMapper[objectTicketIds[i]].count) !== 0) {
          let data = {
            ticketTypeId: Number(objectTicketIds[i]),
            count: Number(ticketTypeCountMapper[objectTicketIds[i]].count),
          };
          finalObject.push(data);
        }
      }
      if (
        finalObject.length > 0 &&
        (maxAmount !== 0 ||
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalEventBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalEventBookingAmount() -
                        (
                          (totalEventBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalEventBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalEventBookingAmount() -
                      (
                        (totalEventBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalEventBookingAmount())
                ? maxAmount !== undefined
                  ? (
                      (maxAmount * Number(eventDetails.gstPercentage)) / 100 +
                      maxAmount
                    ).toFixed(2) - maxAmount
                  : 0
                : (
                    totalEventBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalEventBookingAmount()
          ) !== 0)
      ) {
        let finalDataForBackend;
        if (
          validateCouponCodeResult !== undefined &&
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalEventBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalEventBookingAmount() -
                        (
                          (totalEventBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalEventBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalEventBookingAmount() -
                      (
                        (totalEventBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalEventBookingAmount())
                ? maxAmount !== undefined
                  ? (
                      (maxAmount * Number(eventDetails.gstPercentage)) / 100 +
                      maxAmount
                    ).toFixed(2) - maxAmount
                  : 0
                : (
                    totalEventBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalEventBookingAmount()
          ) !== 0
        ) {
          // console.log("1", id !== undefined ? Number(id) : eveId);
          finalDataForBackend = {
            ticketTypes: finalObject,
            //eventDateId: eventDateId,
            eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
            guest: false,
            tokenId: accessToken,
            couponId: validateCouponCodeResult.id,
            //totalAttendees: Number(counter),
          };
        } else if (
          validateCouponCodeResult !== undefined &&
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalEventBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalEventBookingAmount() -
                        (
                          (totalEventBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalEventBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalEventBookingAmount() -
                      (
                        (totalEventBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalEventBookingAmount())
                ? maxAmount !== undefined
                  ? (
                      (maxAmount * Number(eventDetails.gstPercentage)) / 100 +
                      maxAmount
                    ).toFixed(2) - maxAmount
                  : 0
                : (
                    totalEventBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalEventBookingAmount()
          ) === 0
        ) {
          // console.log("2", id !== undefined && Number(id), eveId);
          finalDataForBackend = {
            ticketTypes: finalObject,
            //eventDateId: eventDateId,
            eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
            eveId,
            tokenId: accessToken,
            couponId: validateCouponCodeResult.id,
            freeEvent: true,
            guest: false,
            //totalAttendees: Number(counter),
          };
        } else {
          // console.log("3", id !== undefined && Number(id), eveId);
          finalDataForBackend = {
            ticketTypes: finalObject,
            //eventDateId: eventDateId,
            eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
            guest: false,
            tokenId: accessToken,
            //totalAttendees: Number(counter),
          };
        }
        if (guestCalling === true) {
          let withGuestData = {
            bookingDetails: finalDataForBackend,
            userName: person.userName,
            email: person.email,
            mobile: person.mobile,
            guest: true,
          };
          bookingAsyncCalled(withGuestData);
        } else {
          // console.log("final", finalDataForBackend);
          bookingAsyncCalled(finalDataForBackend);
        }
      } else if (
        finalObject.length > 0 &&
        (maxAmount === 0 ||
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalEventBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalEventBookingAmount() -
                        (
                          (totalEventBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalEventBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalEventBookingAmount() -
                      (
                        (totalEventBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalEventBookingAmount())
                ? maxAmount !== undefined
                  ? (
                      (maxAmount * Number(eventDetails.gstPercentage)) / 100 +
                      maxAmount
                    ).toFixed(2) - maxAmount
                  : 0
                : (
                    totalEventBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalEventBookingAmount()
          )) === 0
      ) {
        let finalDataForBackend;
        finalDataForBackend = {
          ticketTypes: finalObject,
          //eventDateId: eventDateId,
          eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
          guest: false,
          tokenId: accessToken,
          freeEvent: true,
          //totalAttendees: Number(counter),
        };
        if (guestCalling === true) {
          let withGuestData = {
            bookingDetails: finalDataForBackend,
            userName: person.userName,
            email: person.email,
            mobile: person.mobile,
            guest: true,
          };
          bookingAsyncCalled(withGuestData);
        } else {
          // console.log("final 2", finalDataForBackend);
          bookingAsyncCalled(finalDataForBackend);
        }
      } else if (finalObject.length > 0 && Number(maxAmount) === 0) {
        let finalDataForBackend;
        finalDataForBackend = {
          ticketTypes: finalObject,
          //eventDateId: eventDateId,
          eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
          tokenId: accessToken,
          freeEvent: true,
          guest: false,
          //totalAttendees: Number(counter),
        };
        if (guestCalling === true) {
          let withGuestData = {
            bookingDetails: finalDataForBackend,
            userName: person.userName,
            email: person.email,
            mobile: person.mobile,
            eventId: id !== undefined && isNumber(id) ? Number(id) : eveId,
            guest: true,
          };
          // console.log("final5", withGuestData);
          bookingAsyncCalled(withGuestData);
        } else {
          // console.log("final3", finalDataForBackend);
          bookingAsyncCalled(finalDataForBackend);
        }
      } else {
        alert("Please select number of tickets to continue booking!");
      }
    }
  };

  const handleCouponCodeClear = () => {
    clearCouponCodeAsync();
    setCoupon("");
  };

  const razorPayCall = (res) => {
    const hasWindow = typeof window !== "undefined";

    let options;
    if (hasWindow) {
      try {
        if (userDet !== undefined && res.guest === null) {
          options = {
            // key: "rzp_live_6i9IJT8pW0sluE",
            key: process.env.RAZZLE_APP_RAZORPAYKEY,
            name: "Followgoa",
            description: "Unique journey",
            order_id: res.booking.razorPayOrderId,
            amount: res.booking.totalAmount * 100,
            handler: async (response) => {
              try {
                // console.log("res -> ", response);
                if (
                  response &&
                  response.razorpay_payment_id !== undefined &&
                  response.razorpay_payment_id !== null
                ) {
                  setSuccess(true);
                }
                //alert("Payment succesfull!");
                setShowSuccessPopup(true);
                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Success",
                // });
                //alert(`Payment Success. Payment Id ${paymentId}`);
              } catch (err) {
                alert("Error --->", err);
                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Failure",
                // });
                ////console.log("err-res", err);
                // alert(err);
              }
            },

            prefill: {
              //   name: user.displayName,
              email: userDet.email,
              contact: userDet.mobile,
            },
            theme: {
              color: "#FDD72A",
            },
            modal: {
              ondismiss: function () {
                // console.log("Checkout form closed");
                setChecked(false);
                refreshBookingState();
              },
            },
          };
        } else {
          if (userDet === undefined && res.guest !== null) {
            options = {
              // key: "rzp_live_6i9IJT8pW0sluE",
              key: process.env.RAZZLE_APP_RAZORPAYKEY,
              name: "Followgoa",
              description: "Unique journey",
              order_id: res.booking.razorPayOrderId,
              amount: res.booking.totalAmount * 100,
              handler: async (response) => {
                try {
                  // console.log("res -> ", response);
                  //alert("Payment succesfull!");

                  setPopupForGuest(true);
                  setShowSuccessPopup(true);

                  setChecked(false);
                  refreshBookingState();
                  // this.props.history.push(`/redirect/${res.data.id}`, {
                  //   status: "Success",
                  // });
                  //alert(`Payment Success. Payment Id ${paymentId}`);
                } catch (err) {
                  alert("Error --->", err);
                  setChecked(false);
                  refreshBookingState();
                  // this.props.history.push(`/redirect/${res.data.id}`, {
                  //   status: "Failure",
                  // });
                  ////console.log("err-res", err);
                  // alert(err);
                }
              },

              prefill: {
                //   name: user.displayName,
                email: res.guest.email,
                contact: res.guest.mobile,
              },
              theme: {
                color: "#FDD72A",
              },
              modal: {
                ondismiss: function () {
                  // console.log("Checkout form closed");
                  setChecked(false);
                  refreshBookingState();
                },
              },
            };
          }
        }

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } catch (e) {
        console.log("err for razor", e);
      }
    }
  };

  useEffect(() => {
    if (bookEventError !== undefined) {
      if (alert(`${bookEventError}`)) {
        clearBackend();
      } else {
        clearBackend();
      }
    }
  }, [bookEventError]);
  const handleCheckButton = (e) => {
    setChecked(!checked);
  };
  useEffect(() => {
    if (validateCouponCodeError) setCodeEntered(true);
  }, [validateCouponCodeError]);

  function checkProperties(o) {
    const allTrue = Object.values(o).some((value) => value === "");
    return allTrue;
  }

  const makeAbsolute = (num) => {
    let number = Number(num);
    // let amount = new BN(number);
    let fn = new BigNumber(number);
    let rounded = fn.dp(0, BigNumber.ROUND_HALF_UP);

    return rounded.toNumber();
  };
  return (
    <div id="BookingPage">
      <Navbar />
      <div className="banner-section">
        <img src={banner} className="bannerbgimg" />
      </div>
      <Container>
        {/*  */}
        {(eventLoading || bookEventLoad || validateCouponCodeLoading) && (
          <Loader />
        )}
        <Row>
          <Col lg={8} className="details-div">
            <div className="booking-details">
              <div className="details">
                <div className="top">
                  <div className="event-name">
                    <span>{title}</span>
                  </div>
                  <div className="reference-id">
                    <span>Reference ID: {tourRef}</span>
                  </div>
                </div>
                <hr />
                <div className="event-block">
                  <p className="loc-text">
                    <LocationIcon className="loc-icon" /> {location}
                  </p>
                  {/* <div className="leftalign">
                    <span className="viewlocation">View Location</span>
                  </div> */}
                  {eventDetails !== undefined &&
                    eventDetails.googleMapLink !== null && (
                      <div className="leftalign">
                        <a
                          className="viewlocation"
                          href={`${eventDetails.googleMapLink}`}
                          target="_blank"
                        >
                          View Location
                        </a>
                      </div>
                    )}
                  <span>
                    <span className="details-text">
                      <CalendarIcon className="calendar-icon" />{" "}
                      {moment(startDate).format("dddd, MMMM Do YYYY")}{" "}
                      <ClockIcon className="clock-icon" />{" "}
                      {moment(startDate).format("h:mm A")}{" "}
                    </span>
                    <span className="details-text-to">To</span>
                    <span className="details-text">
                      <CalendarIcon className="calendar-icon" />{" "}
                      {moment(endDate).format("dddd, MMMM Do YYYY")}{" "}
                      <ClockIcon className="clock-icon" />{" "}
                      {moment(endDate).format("h:mm A")}{" "}
                    </span>
                  </span>
                </div>
                <hr />

                <div className="ticket-block">
                  {ticketSaleStartdate !== undefined &&
                    ticketSaleStartdate !== null && (
                      <span className="ticket-date start-date">
                        <span className="title">Ticket Sale Start Date :</span>
                        <span className="date">
                          <CalendarIcon className="iconn" />{" "}
                          {moment(ticketSaleStartdate).format(
                            "dddd, MMMM Do YYYY"
                          )}
                          <ClockIcon className="clock-icon" />{" "}
                          {moment(ticketSaleStartdate).format("h:mm A")}{" "}
                        </span>
                      </span>
                    )}
                  {ticketSaleEndDate !== undefined &&
                    ticketSaleEndDate !== null && (
                      <span className="ticket-date">
                        <span className="title">Ticket Sale End Date :</span>
                        <span className="date">
                          <CalendarIcon className="iconn" />{" "}
                          {moment(ticketSaleEndDate).format(
                            "dddd, MMMM Do YYYY"
                          )}
                          <ClockIcon className="clock-icon" />{" "}
                          {moment(ticketSaleEndDate).format("h:mm A")}{" "}
                        </span>
                      </span>
                    )}
                </div>
              </div>
            </div>
          </Col>
          {/* {console.log(
            eventTickets,
            "eventTickets",
            eventTickets?.filter((itm) => itm.totalTicketsAvailable > 0).length
          )} */}
          <Col lg={4} className="d-none d-lg-block details-div">
            {eventTickets !== undefined &&
            eventTickets.filter((itm) => itm.totalTicketsAvailable > 0).length >
              0 &&
            moment(ticketSaleEndDate).isBefore(new Date()) === false ? (
              <div className="payment-div">
                <div className="detail">
                  {maximumTickets !== undefined ? (
                    <span className="maintxt1">
                      Number of Person(s) : {maximumTickets}
                    </span>
                  ) : (
                    <span className="maintxt1">Number of Person(s) : 0</span>
                  )}
                </div>
                {eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="gst">
                        GST: {eventDetails.gstPercentage}%
                      </span>
                    </div>
                  )}
                <div className="detail">
                  {maxAmount !== undefined && maxAmount > 0 ? (
                    <span className="maintxt1">Amount Due : ₹{maxAmount}</span>
                  ) : (
                    <span className="maintxt1">Amount Due : ₹0</span>
                  )}
                </div>
                {maxAmount !== undefined &&
                  maxAmount > 0 &&
                  eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span>
                        Total Amount{" "}
                        <span className="addition">(GST + Amount due)</span>: ₹
                        {makeAbsolute(
                          (
                            (maxAmount * Number(eventDetails.gstPercentage)) /
                              100 +
                            maxAmount
                          ).toFixed(2)
                        )}
                      </span>
                    </div>
                  )}

                <div className="coupon-section">
                  <div className="coupon-div">
                    <InputField
                      placeholder={"Enter Coupon code"}
                      type="text"
                      classname="coupon-field"
                      value={coupon}
                      onChange={(event) => setCoupon(event.target.value)}
                    />

                    <div className="apply-btn">
                      <CustomButton
                        type="button"
                        name={codeEntered ? "Clear" : "Apply"}
                        filled={true}
                        primary={true}
                        classname="pay-btn"
                        onClick={() => {
                          console.log(userLoggedIn, !codeEntered);
                          if (userLoggedIn !== undefined) {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: false,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          } else {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: true,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p className="errorText">
                    {validateCouponCodeError !== undefined
                      ? validateCouponCodeError
                      : ""}
                  </p>
                  <div className="amount-div">
                    <div className="amount-text">
                      <span className="discount-text">
                        Discount: ₹{" "}
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (totalEventBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    (totalEventBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                : validateCouponCodeResult.maximumDiscount
                              : (
                                  (totalEventBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(totalEventBookingAmount())
                            ? maxAmount !== undefined
                              ? maxAmount
                              : 0
                            : validateCouponCodeResult.value
                          : 0}
                      </span>
                    </div>
                    <div className="amount-text">
                      <span>
                        Total Payable: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? makeAbsolute(
                                  (
                                    (totalEventBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ) <= validateCouponCodeResult.maximumDiscount
                                ? makeAbsolute(
                                    (
                                      totalEventBookingAmount() -
                                      (
                                        (totalEventBookingAmount() *
                                          validateCouponCodeResult.value) /
                                        100
                                      ).toFixed(2)
                                    ).toFixed(2)
                                  )
                                : makeAbsolute(
                                    (
                                      totalEventBookingAmount() -
                                      validateCouponCodeResult.maximumDiscount
                                    ).toFixed(2)
                                  )
                              : makeAbsolute(
                                  (
                                    totalEventBookingAmount() -
                                    (
                                      (totalEventBookingAmount() *
                                        validateCouponCodeResult.value) /
                                      100
                                    ).toFixed(2)
                                  ).toFixed(2)
                                )
                            : Number(validateCouponCodeResult.value) >
                              Number(totalEventBookingAmount())
                            ? maxAmount !== undefined
                              ? makeAbsolute(
                                  (
                                    (maxAmount *
                                      Number(eventDetails.gstPercentage)) /
                                      100 +
                                    maxAmount
                                  ).toFixed(2) - maxAmount
                                )
                              : 0
                            : makeAbsolute(
                                (
                                  totalEventBookingAmount() -
                                  Number(validateCouponCodeResult.value)
                                ).toFixed(2)
                              )
                          : makeAbsolute(totalEventBookingAmount())}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="check-div">
                  <Form.Check
                    label={
                      <Link
                        to="/terms-conditions"
                        onClick={() => history.push("/terms-conditions")}
                      >
                        Agree to terms and conditions
                      </Link>
                    }
                    name="category"
                    checked={checked}
                    onClick={(e) => handleCheckButton(e)}
                  />
                </div>
                <div className="btn-div">
                  <CustomButton
                    type="button"
                    name={
                      maximumTickets !== 0 && maxAmount === 0
                        ? "Register"
                        : maximumTickets == 0
                        ? "Select Ticket"
                        : "Continue to Payment"
                    }
                    filled={true}
                    primary={true}
                    classname="pay-btn"
                    // disable={
                    //   userLoggedIn !== undefined ||
                    //   (_.isEmpty(person) === false &&
                    //     Object.keys(person).length > 2)
                    // }
                    onClick={() => {
                      // console.log("checkk", checkProperties(person));

                      if (userLoggedIn !== undefined) {
                        // console.log("clicked1");
                        handlePaymentButtonCall(false);
                      } else if (
                        userLoggedIn === undefined &&
                        _.isEmpty(person) === false &&
                        checkProperties(person) === false &&
                        person.email !== "" &&
                        emailPattern.test(person.email) === true &&
                        person.mobile !== "" &&
                        re.test(person.mobile) === true
                      ) {
                        // console.log("clicked2");
                        // console.log("inside else if 1");
                        handlePaymentButtonCall(true);
                      } else {
                        if (
                          person.email === "" ||
                          emailPattern.test(person.email) === false
                        ) {
                          // console.log("clicked3");
                          alert("Please enter valid email");
                        } else if (
                          person.mobile === "" ||
                          re.test(person.mobile) === false
                        ) {
                          // console.log("clicked4");
                          alert("Please enter valid mobile number !");
                        } else if (person.userName === "") {
                          alert("Please enter you name !");
                        }
                        // else {

                        //   alert("Please fill all the fields to continue !");
                        // }
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="payment-div no-ticket">
                <div className="detail">
                  {moment(ticketSaleEndDate).isBefore(new Date()) === true ? (
                    <span>Bookings are closed for this event!</span>
                  ) : eventTickets?.length == 0 ? (
                    <span>
                      Sorry currently no tickets are available ! Check after
                      some days !
                    </span>
                  ) : (
                    <span>
                      {eventTickets?.filter(
                        (itm) => itm.totalTicketsAvailable > 0
                      ).length == 0
                        ? "Tickets Sold Out"
                        : ""}
                    </span>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
        {/* commented */}
        {/* {userLoggedIn === undefined &&
          moment(ticketSaleEndDate).isBefore(new Date()) === false &&
          eventTickets?.length !== 0 && (
            <Row>
              <Col lg={6}>
                <BookingForm guestDet={person} setGuestDet={handleGuest} />
              </Col>
            </Row>
          )} */}
        {/* commenteddddddd */}
        <Row>
          {userLoggedIn === undefined && (
            <Col lg={6}>
              <BookingForm guestDet={person} setGuestDet={handleGuest} />
            </Col>
          )}
          {eventTickets !== undefined &&
            eventTickets.filter((itm) => itm.totalTicketsAvailable > 0).length >
              0 &&
            moment(ticketSaleEndDate).isBefore(new Date()) === false && (
              <Col lg={6}>
                <div className="ticket-div">
                  {eventTickets.map((item) => {
                    // if (item.totalTicketsAvailable > 0) {
                    return (
                      <>
                        <div className="ticket-title">
                          <span>Ticket</span>
                        </div>
                        <div className="ticket-details">
                          <div className="line1">
                            <div className="name">
                              <span>{item.name}</span>
                            </div>
                            <div className="amount">
                              <span>₹{item.cost}</span>
                            </div>
                          </div>
                          <p className="description">{item.description}</p>
                          <div className="line3">
                            {/* <div className="available">
                          <span>
                            {item.totalTicketsAvailable} Tickets Available
                          </span>
                        </div> */}
                            {item.totalTicketsAvailable > 0 ? (
                              <div className="counter">
                                <CustomButton
                                  type="button"
                                  name="-"
                                  filled={true}
                                  primary={true}
                                  onClick={() => decrease(item)}
                                  classname={`counter-btn ${
                                    ticketTypeCountMapper !== undefined &&
                                    ticketTypeCountMapper[item.id] !==
                                      undefined &&
                                    ticketTypeCountMapper[item.id].count === 0
                                      ? "btndisable"
                                      : ""
                                  }`}
                                />
                                <div className="counter_output">
                                  <span>
                                    {ticketTypeCountMapper !== undefined &&
                                      ticketTypeCountMapper[item.id] !==
                                        undefined &&
                                      ticketTypeCountMapper[item.id].count}
                                  </span>
                                </div>
                                <CustomButton
                                  type="button"
                                  name="+"
                                  filled={true}
                                  primary={true}
                                  onClick={() => increase(item)}
                                  classname={`counter-btn ${
                                    ticketTypeCountMapper !== undefined &&
                                    ticketTypeCountMapper[item.id] !==
                                      undefined &&
                                    ticketTypeCountMapper[item.id].count ===
                                      item.totalTicketsAvailable
                                      ? "btndisable"
                                      : ""
                                  }`}
                                />
                              </div>
                            ) : (
                              <div className="counter2">Sold out </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                    // }
                    // else {
                    //   return (
                    //     <>
                    //       <div className="ticket-title">
                    //         <span>Ticket</span>
                    //       </div>
                    //       <div className="no-ticket">
                    //         <span>Sorry ! All tickets sold out</span>
                    //       </div>
                    //     </>
                    //   );
                    // }
                  })}
                </div>
              </Col>
            )}
        </Row>
        <Row>
          <Col lg={4} className="d-lg-none">
            {eventTickets !== undefined &&
            eventTickets.filter((itm) => itm.totalTicketsAvailable > 0).length >
              0 &&
            moment(ticketSaleEndDate).isBefore(new Date()) === false ? (
              <div className="payment-div">
                <div className="detail">
                  {maximumTickets !== undefined ? (
                    <span className="maintxt1">
                      Number of Person(s) : {maximumTickets}
                    </span>
                  ) : (
                    <span className="maintxt1">Number of Person(s) : 0</span>
                  )}
                </div>
                {eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="gst">
                        GST: {eventDetails.gstPercentage}%
                      </span>
                    </div>
                  )}
                <div className="detail">
                  {maxAmount !== undefined && maxAmount > 0 ? (
                    <span className="maintxt1">Amount Due : ₹{maxAmount}</span>
                  ) : (
                    <span className="maintxt1">Amount Due : ₹0</span>
                  )}
                </div>
                {maxAmount !== undefined &&
                  maxAmount > 0 &&
                  eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span>
                        Total Amount{" "}
                        <span className="addition">(GST + Amount due)</span>: ₹
                        {(
                          (maxAmount * Number(eventDetails.gstPercentage)) /
                            100 +
                          maxAmount
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}

                <div className="coupon-section">
                  <div className="coupon-div">
                    <InputField
                      placeholder={"Enter Coupon code"}
                      type="text"
                      classname="coupon-field"
                      value={coupon}
                      onChange={(event) => setCoupon(event.target.value)}
                    />
                    <div className="apply-btn">
                      <CustomButton
                        type="button"
                        name={codeEntered ? "Clear" : "Apply"}
                        filled={true}
                        primary={true}
                        classname="pay-btn"
                        onClick={() => {
                          if (userLoggedIn !== undefined) {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: false,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          } else {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: true,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <p className="errorText">
                    {validateCouponCodeError !== undefined
                      ? validateCouponCodeError
                      : ""}
                  </p>
                  <div className="amount-div">
                    <div className="amount-text">
                      <span className="discount-text">
                        Discount: ₹{" "}
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (totalEventBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    (totalEventBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                : validateCouponCodeResult.maximumDiscount
                              : (
                                  (totalEventBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(totalEventBookingAmount())
                            ? maxAmount !== undefined
                              ? maxAmount
                              : 0
                            : validateCouponCodeResult.value
                          : 0}
                      </span>
                    </div>
                    <div className="amount-text">
                      <span>
                        Total Payable: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (totalEventBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    totalEventBookingAmount() -
                                    (
                                      (totalEventBookingAmount() *
                                        validateCouponCodeResult.value) /
                                      100
                                    ).toFixed(2)
                                  ).toFixed(2)
                                : (
                                    totalEventBookingAmount() -
                                    validateCouponCodeResult.maximumDiscount
                                  ).toFixed(2)
                              : (
                                  totalEventBookingAmount() -
                                  (
                                    (totalEventBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(totalEventBookingAmount())
                            ? maxAmount !== undefined
                              ? (
                                  (maxAmount *
                                    Number(eventDetails.gstPercentage)) /
                                    100 +
                                  maxAmount
                                ).toFixed(2) - maxAmount
                              : 0
                            : (
                                totalEventBookingAmount() -
                                Number(validateCouponCodeResult.value)
                              ).toFixed(2)
                          : totalEventBookingAmount()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="check-div">
                  <Form.Check
                    // label={"Agree to terms and conditions"}
                    label={
                      <Link
                        to="/terms-conditions"
                        onClick={() => history.push("/terms-conditions")}
                      >
                        Agree to terms and conditions
                      </Link>
                    }
                    name="category"
                    checked={checked}
                    onClick={(e) => handleCheckButton(e)}
                  />
                </div>
                <div className="btn-div">
                  <CustomButton
                    type="button"
                    name={
                      maximumTickets !== 0 && maxAmount === 0
                        ? "Register"
                        : "Continue to Payment"
                    }
                    filled={true}
                    primary={true}
                    classname="pay-btn"
                    onClick={() => {
                      if (userLoggedIn !== undefined) {
                        handlePaymentButtonCall(false);
                      } else if (
                        _.isEmpty(person) === false &&
                        Object.keys(person).length > 2
                      ) {
                        handlePaymentButtonCall(true);
                      } else {
                        if (
                          person.email === "" &&
                          emailPattern.test(person.email) === false
                        ) {
                          alert("Please enter valid email");
                        } else if (
                          person.mobile === "" &&
                          re.test(person.mobile) === false
                        ) {
                          alert("Please enter valid mobile number !");
                        } else {
                          alert("Please fill all the fields to continue !");
                        }
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="payment-div no-ticket">
                <div className="detail">
                  {moment(ticketSaleEndDate).isBefore(new Date()) === true ? (
                    <span>Bookings are closed for this event!</span>
                  ) : eventTickets?.length == 0 ? (
                    <span>
                      Sorry currently no tickets are available ! Check after
                      some days !
                    </span>
                  ) : (
                    <span>
                      {eventTickets?.filter(
                        (itm) => itm.totalTicketsAvailable > 0
                      ).length == 0
                        ? "Tickets Sold Out"
                        : ""}
                    </span>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>

        <BookingSuccessPopup
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          show={showSuccessPopup}
          setShowModal={setShowSuccessPopup}
          forGuest={popupForGuest}
          successOrFail={success}
          setSucc={setSuccess}
          closeForGuest={(e) => setPopupForGuest(e)}
        />
      </Container>
      <div className="footer-div">
        <MainFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ event = {}, user = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", undefined);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const userDet = _.get(user, "userDetails", undefined);
  const bookEventDetails = _.get(event, "bookEventDetails", undefined);
  const bookEventError = _.get(event, "bookEventError", undefined);
  const bookEventLoad = _.get(event, "bookEventLoading", false);
  const eventTicketsLoading = _.get(event, "eventTicketsLoading", false);
  const eventTickets = _.get(event, "eventTickets", undefined);
  const eventTicketsError = _.get(event, "eventTicketsError", undefined);
  const validateCouponCodeLoading = _.get(
    event,
    "validateCouponCodeLoading",
    false
  );
  const validateCouponCodeResult = _.get(
    event,
    "validateCouponCode",
    undefined
  );
  const validateCouponCodeError = _.get(
    event,
    "validateCouponCodeError",
    undefined
  );
  return {
    eventLoading,
    bookEventLoad,
    eventDetails,
    eventError,
    userLoggedIn,
    userDet,
    bookEventDetails,
    bookEventError,
    eventTicketsLoading,
    eventTickets,
    eventTicketsError,
    validateCouponCodeLoading,
    validateCouponCodeResult,
    validateCouponCodeError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  bookingAsyncCalled: (data) =>
    dispatch({ type: "bookingsWithAttendeeNameSagaCalled", payload: data }),
  refreshBookingState: () => dispatch(resetBookingEvent()),
  clearBackend: () => dispatch(clearBackendError()),
  getEventTickets: (data) =>
    dispatch({ type: "getEventTickets", payload: data }),
  clearEventTicketsCall: () => dispatch(clearEventTickets()),
  ValidateCouponCodeAsync: (data) =>
    dispatch({ type: "validateCouponCodeSagaCalled", payload: data }),
  clearCouponCodeAsync: () => dispatch(clearCouponResult()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
