import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "AppSlice",
  initialState: {
    getEventsCardsLoading: false,
    getEventCardsSuccess: [],
    getEventCardFailure: undefined,

    getEventCategoriesLoading: false,
    getEventCategoriesSuccess: undefined,
    getEventCategoriesFailure: undefined,

    getAllCategoryEventsLoading: false,
    getAllCategoryEventsSuccess: undefined,
    getAllCategoryEventsFailure: undefined,

    getLocationsLoading: false,
    getLocationsSuccess: undefined,
    getLocationsFailure: undefined,

    becomePartnerLoading: false,
    becomePartnerSuccess: undefined,
    becomePartnerFailure: undefined,

    getBannerImageLoading: false,
    getBannerImages: undefined,
    getBannerImageError: undefined,
  },
  reducers: {
    resetAppReduxOnMount: (state, action) => {
      return {
        ...state,
        getEventsCardsLoading: false,
        getEventCardFailure: undefined,
        getEventCategoriesLoading: false,
        getEventCategoriesFailure: undefined,
        getAllCategoryEventsLoading: false,
        getAllCategoryEventsFailure: undefined,
        getLocationsLoading: false,
        getLocationsFailure: undefined,
        becomePartnerLoading: false,
        becomePartnerFailure: undefined,
        getBannerImageLoading: false,
        getBannerImageError: undefined,
      };
    },
    eventsCardLoadingStart: (state, action) => {
      return {
        ...state,
        getEventsCardsLoading: true,
        getEventCardsSuccess: [],
        getEventCardFailure: undefined,
      };
    },
    eventsCardSuccess: (state, action) => {
      return {
        ...state,
        getEventsCardsLoading: false,
        getEventCardsSuccess: action.payload,
        getEventCardFailure: undefined,
      };
    },
    eventsCardFail: (state, action) => {
      return {
        ...state,
        getEventsCardsLoading: false,
        getEventCardsSuccess: [],
        getEventCardFailure: action.payload,
      };
    },

    eventCategoryLoadingStart: (state, action) => {
      return {
        ...state,
        getEventCategoriesLoading: true,
        getEventCategoriesSuccess: undefined,
        getEventCategoriesFailure: undefined,
      };
    },
    eventCategorySuccess: (state, action) => {
      return {
        ...state,
        getEventCategoriesLoading: false,
        getEventCategoriesSuccess: action.payload,
        getEventCategoriesFailure: undefined,
      };
    },
    eventCategoryFail: (state, action) => {
      return {
        ...state,
        getEventCategoriesLoading: false,
        getEventCategoriesSuccess: undefined,
        getEventCategoriesFailure: action.payload,
      };
    },

    allCategoryLoadingStart: (state, action) => {
      return {
        ...state,
        getAllCategoryEventsLoading: true,
        getAllCategoryEventsSuccess: undefined,
        getAllCategoryEventsFailure: undefined,
      };
    },

    allCategorySuccess: (state, action) => {
      return {
        ...state,
        getAllCategoryEventsLoading: false,
        getAllCategoryEventsSuccess: action.payload,
        getAllCategoryEventsFailure: undefined,
      };
    },
    allCategoryFail: (state, action) => {
      return {
        ...state,
        getAllCategoryEventsLoading: false,
        getAllCategoryEventsSuccess: undefined,
        getAllCategoryEventsFailure: action.payload,
      };
    },

    allLocationLoadingStart: (state, action) => {
      return {
        ...state,
        getLocationsLoading: true,
        getLocationsSuccess: undefined,
        getLocationsFailure: undefined,
      };
    },
    allLocationSuccess: (state, action) => {
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsSuccess: action.payload,
        getLocationsFailure: undefined,
      };
    },
    allLocationFail: (state, action) => {
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsSuccess: undefined,
        getLocationsFailure: action.payload,
      };
    },
    becomePartnerCalled: (state, action) => {
      return {
        ...state,
        becomePartnerLoading: true,
        becomePartnerSuccess: undefined,
        becomePartnerFailure: undefined,
      };
    },
    becomePartnerSuccess: (state, action) => {
      return {
        ...state,
        becomePartnerLoading: false,
        becomePartnerSuccess: action.payload,
        becomePartnerFailure: undefined,
      };
    },
    becomepartnerFailure: (state, action) => {
      return {
        ...state,
        becomePartnerLoading: false,
        becomePartnerSuccess: undefined,
        becomePartnerFailure: action.payload,
      };
    },

    resetBecomepartnerStates: (state, action) => {
      return {
        ...state,
        becomePartnerLoading: false,
        becomePartnerSuccess: undefined,
        becomePartnerFailure: undefined,
      };
    },

    getBannerImageLoadingStart: (state, action) => {
      return {
        ...state,
        getBannerImageLoading: true,
        getBannerImages: undefined,
        getBannerImageError: undefined,
      };
    },
    getBannerImageSuccess: (state, action) => {
      return {
        ...state,
        getBannerImageLoading: false,
        getBannerImages: action.payload,
        getBannerImageError: undefined,
      };
    },
    getBannerImageFailure: (state, action) => {
      return {
        ...state,
        getBannerImageLoading: false,
        getBannerImages: undefined,
        getBannerImageError: action.payload,
      };
    },

    clearAppRedux: (state, action) => {
      return {};
    },
  },
});

export default appSlice.reducer;
export const {
  eventsCardLoadingStart,
  eventsCardSuccess,
  eventsCardFail,
  eventCategoryLoadingStart,
  eventCategorySuccess,
  eventCategoryFail,
  allCategoryLoadingStart,
  allCategorySuccess,
  allCategoryFail,
  allLocationLoadingStart,
  allLocationSuccess,
  allLocationFail,
  becomePartnerCalled,
  becomePartnerSuccess,
  becomepartnerFailure,
  resetBecomepartnerStates,
  getBannerImageLoadingStart,
  getBannerImageSuccess,
  getBannerImageFailure,
  clearAppRedux,
  resetAppReduxOnMount,
} = appSlice.actions;
