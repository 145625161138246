import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import "@components/homepage-cards/HomepageCardsStyle.scss";
import {
  locationForSearchLoading,
  titleForSearchLoading,
  clearLoacationTitleForSearch,
  categoryForSearchLoading,
  clearLocationForSearch,
  triedForCategorySearch,
  triedForLocationSearch,
} from "@store/eventredux";

const HomepageCards = ({
  cardType,
  cName,
  data,
  clearLocationAndTitleAsync,
  categoryForSearchAsync,
  clearLocationAsync,
  categorySearchTrialAsync,
}) => {
  const history = useHistory();

  const isNotExpired = (itm) => {
    //console.log("Item inside IsNotExpired -> ", itm);
    if (itm.type === "villa") {
      return true;
    } else if (itm.type === "luxuryCar") {
      return true;
    } else {
      var given = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
      var current = moment(itm.endDate, "YYYY-MM-DD HH:mm:ss");
      //Difference in number of hours
      let difTime = moment.duration(current.diff(given)).asHours();
      if (difTime >= 0) {
        return true;
      } else {
        return false;
      }
    }
    //console.log("Details ->>>>> ", bookingDet);
  };

  return (
    <div className="HomepageCards">
      <div className={`title ${cName}`}>
        <span className="heading">{cardType}</span>
        <span
          className="view-all"
          onClick={() => {
            if (cardType === "Stay and Leisure") {
              // console.log('cardtype' , cardType)
              history.push("/searchPropertiesTours");
            } else if (cardType === "Luxury car rentals") {
              // console.log('cardtype' , cardType)
              history.push("/searchLuxuryCars");
            } else {
              history.push("/allevents");
            }
            categoryForSearchAsync(cardType);
            categorySearchTrialAsync(true);
            clearLocationAndTitleAsync();
            clearLocationAsync();
          }}
        >
          View All
        </span>
      </div>
      <Row>
        {data !== undefined &&
          data.length !== 0 &&
          data.slice(0, 4).map((itm, ind) => {
            if (isNotExpired(itm) && itm.deletedOn === null) {
              return (
                <Col lg={3} md={4} sm={6} key={`homecards${itm.id}`}>
                  <div
                    className="card-div"
                    key={ind}
                    onClick={() => {
                      if (itm.type === "villa") {
                        if (itm.urlSlug === null) {
                          history.push(`/properties/${itm.id}`);
                        } else {
                          history.push(
                            `/properties/${encodeURIComponent(itm.urlSlug)}`
                          );
                        }
                      } else if (itm.type === "luxuryCar") {
                        if (itm.urlSlug === null) {
                          history.push(`/luxurycar/${itm.id}`);
                        } else {
                          history.push(
                            `/luxurycar/${encodeURIComponent(itm.urlSlug)}`
                          );
                        }
                      } else {
                        if (itm.type === "event") {
                          if (itm.urlSlug === null) {
                            history.push(`/events/${itm.id}`);
                          } else {
                            history.push(
                              `/events/${encodeURIComponent(itm.urlSlug)}`
                            );
                          }
                        } else {
                          if (itm.urlSlug === null) {
                            history.push(`/tourdetails/${itm.id}`);
                          } else {
                            history.push(
                              `/tourdetails/${encodeURIComponent(itm.urlSlug)}`
                            );
                          }
                        }
                      }
                    }}
                  >
                    <Card>
                      <div className="card-img-block">
                        <img
                          className="img-fluid cover"
                          src={itm.banner}
                          alt="Card image cap"
                        />
                      </div>
                      <Card.Body>
                        <div className="card-name">{itm.title}</div>
                        {/* <div className="location">{itm.location}</div> */}
                        {itm.type === "luxuryCar" && (
                          <div className="date">
                            {itm.luxuryCarDetails !== undefined &&
                              itm.luxuryCarDetails !== null &&
                              `${itm.luxuryCarDetails.no_of_seaters} Seaters`}{" "}
                          </div>
                        )}
                        {itm.type === "event" ? (
                          itm.startDate !== undefined &&
                          itm.startDate !== null &&
                          itm.endDate !== undefined &&
                          itm.endDate !== null && (
                            <div className="date">
                              {/* {new Date(itm.startDate).toDateString()} */}
                              {moment(itm.startDate).format(
                                "ddd, MMM Do"
                              )} - {moment(itm.endDate).format("ddd, MMM Do")}
                            </div>
                          )
                        ) : itm.type === "villa" ? (
                          <div className="date">
                            {itm.villaDetails !== undefined &&
                              itm.villaDetails !== null &&
                              `${itm.villaDetails.max_no_of_guests} Guests`}{" "}
                          </div>
                        ) : (
                          <></>
                        )}
                        {itm.type === "tour" && itm.destination !== null && (
                          <div className="date">{itm.destination}</div>
                        )}
                        {itm.type === "villa" &&
                        itm.villaDetails !== null &&
                        itm.villaDetails !== undefined &&
                        itm.villaDetails.price_per_night !== undefined &&
                        itm.villaDetails.price_per_night !== null ? (
                          <div className="amount">
                            ₹ {itm.villaDetails.price_per_night} /night
                          </div>
                        ) : itm.type === "luxuryCar" ? (
                          <div className="amount">
                            ₹ {itm?.luxuryCarDetails?.price || 0} /day
                          </div>
                        ) : (
                          itm.amount !== undefined &&
                          itm.amount !== null && (
                            <div className="amount">₹ {itm.amount} onwards</div>
                          )
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            }
          })}
      </Row>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  clearLocationAndTitleAsync: () => dispatch(clearLoacationTitleForSearch()),
  clearLocationAsync: () => dispatch(clearLocationForSearch()),
  categoryForSearchAsync: (data) => dispatch(categoryForSearchLoading(data)),
  categorySearchTrialAsync: (data) => dispatch(triedForCategorySearch(data)),
});

export default connect(null, mapDispatchToProps)(HomepageCards);
