import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import "@features/refund-policy/RefundPolicyStyles.scss";
const RefundPolicy = () => {
  return (
    <div id="refundpolicy">
      <NavBar />
      <Container>
        <h2>Cancellation/Refund Policy</h2>
        <p>
          Refund policy is specific to event/villa/trek/tour. It will be set by
          organizer and appear on event details page. Platform will follow the
          same policy.
        </p>
        <p>
          In case You have made the payment but failed to receive any email
          and/or SMS confirmation, then chances are that such a payment has
          failed. Once we have confirmed a failed order, we process refunds on
          our own. While we process such refunds within 3-5 days, it may take up
          to 7-10 working days (and sometimes more) for your financial
          institution to credit the amount back to your account.
        </p>
        <p>
          {" "}
          Followgoa reserves the right to deny refunds for tickets purchased
          outside our Platform. Any decisions taken by the event organiser and
          Followgoa management will be final and binding in this regard. If you
          find that you have been issued fake tickets not authorized by us, then
          you may be a victim of fraud, for which you may consider filing
          appropriate police complaints/first information report at the nearest
          police station having jurisdiction over the area of your residence. We
          bear no responsibility or liability, under any law or circumstances,
          for such frauds committed by unauthorized third parties.
        </p>
        <h3>Payment Terms</h3>
        <p>
          The Website is provided to users free of charge. We will not collect
          any charges for browsing or otherwise accessing the Website. You are
          advised that your mobile carrier’s standard data charges will still
          apply if you access the Website through a mobile phone. Followgoa,
          will not be liable for any data charges that you incur while using the
          Website.
        </p>
        <h3>Refunds and Cancellations</h3>
        <p>
          All purchases for Followgoa are final unless a refund is offered
          pursuant to these Terms and Conditions.Followgoa does not guarantee
          specific results through our Services. Every business is different.
          Every technology is different. Every audience is different. Followgoa
          assumes no liability or responsibility for any information, advice, or
          lack thereof from consultation and co-incubation services provided.
        </p>
        <p>
          However, we are dedicated to delivering you the highest quality
          services at affordable prices and want you to be fully satisfied with
          every purchase. If you are not satisfied with any of the services we
          provide, please contact us at booking@followgoa.com so we may
          accommodate your request for a refund and/or cancellation. Refunds
          and/or cancellations will be granted on a case-by-case basis.
        </p>
        <h3>Currencies</h3>
        <p>
          All fees for Followgoa will be quoted in Rupees (₹). We will not held
          responsible for any currency fluctuations incurred when receiving or
          sending payments in foreign currencies.
        </p>
      </Container>
      <MainFooter />
    </div>
  );
};

export default RefundPolicy;
