import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Carousel } from "react-bootstrap";
import { Container } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import * as _ from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { clearBackendError } from "@store/eventredux";
import MainFooter from "@components/footer/MainFooter";
import Navbar from "@components/navbar/Navbar";
import CustomButton from "@components/custom-button/CustomButton";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import CalendarIcon from "@images/calender.svg";
import ClockIcon from "@images/clock.svg";
import LocationIcon from "@images/location.svg";
import FoodIcon from "@images/food.svg";
import insta from "@images/insta.svg";
import fb from "@images/fb.svg";
import twittericon from "@images/twittericon.svg";
import whatsapp from "@images/whatsapp.svg";
import { ReactComponent as NoData } from "@images/nodata.svg";
import "@features/event-detail/EventDetailsStyle.scss";
import {
  FacebookShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

function EventDetails({
  GetEventDetail,
  eventDetails,
  eventLoading,
  faqs,
  faqsLoading,
  faqsError,
  GetFaqs,
  userLoggedIn,
  eventError,
  clearBackendErrorAsync,
  eventItinerariesCalled,
  eventItinerariesLoading,
  eventItineraries,
  eventItinerariesError,
}) {
  let { id } = useParams();
  // const params = new URLSearchParams(window.location.search);
  let history = useHistory();
  const [readMore, setReadMore] = useState(false);
  const [Description, setDescription] = useState();
  const [backendError, setBackendError] = useState();
  const [dateExceeded, setDateExceeded] = useState(0);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [ticketSaleStartdate, setTicketSaleStartDate] = useState();
  const [ticketSaleEndDate, setTicketSaleEndDate] = useState();
  const [myRedirect, setMyRedirect] = useState("");
  const [eveId, setEveId] = useState();
  const [rsvp, setRsvp] = useState(true);
  var para;
  const location2 = useLocation();
  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };

  useEffect(() => {
    clearBackendErrorAsync();
    setBackendError();
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);

  useEffect(() => {
    if (para !== undefined) {
      // GetEventDetail({ urlSlug: true, slugVal: params.get("event") });
      setMyRedirect(para);
    }
  }, [para]);

  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({ urlSlug: true, slugVal: myRedirect });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetEventDetail({ urlSlug: false, eventId: id });
      }
    }
  }, [myRedirect, id]);

  useEffect(() => {
    // clearBackendErrorAsync();
    if (id !== undefined || eveId !== undefined) {
      handleBackendCallsBasedOnIdChange();
    }
  }, [id, eveId]);

  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      // GetFaqs({ eventId: eveId });
      eventItinerariesCalled({ eventId: eveId });
    } else {
      if (id !== undefined) {
        // GetFaqs({ eventId: id });
        eventItinerariesCalled({ eventId: eveId });
      }
    }
  };

  useEffect(() => {
    if (eventDetails !== undefined) {
      // console.log("event", eventDetails);
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      setStartDate(eventDetails.startDate);
      setEndDate(eventDetails.endDate);
      setEveId(eventDetails.id);
      setdescription(eventDetails.description);
      checkExceedDataTime(eventDetails.endDate);
      setTicketSaleStartDate(eventDetails.ticketSaleStartDate);
      setTicketSaleEndDate(eventDetails.ticketSaleEndDate);
      setRsvp(eventDetailsRSVP());
    }
  }, [eventDetails, id, eveId]);
  useEffect(() => {
    if (eventError !== undefined) {
      setBackendError(eventError);
    }
  }, [eventError]);

  const isBookingAvailable = (event) => {
    if (
      event.ticketSaleStartDate !== undefined &&
      new Date() < new Date(event.ticketSaleStartDate)
    ) {
      return 0;
    }
    if (
      event.ticketSaleEndDate !== undefined &&
      +new Date() === +new Date(event.ticketSaleEndDate)
    ) {
      return 1;
    }

    return 2;
  };
  function checkExceedDataTime(dt) {
    let exceedLimit = 0;

    const dateLimit = moment(dt, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const now = moment();
    if (dateLimit.isValid() && now.isAfter(dateLimit)) {
      exceedLimit++;
      setDateExceeded(exceedLimit);
    }
  }

  const isNotExpired = (dat) => {
    //console.log("Details ->>>>> ", bookingDet);
    var given = moment(new Date(), "YYYY-MM-DD");
    var current = moment(dat, "YYYY-MM-DD");

    //Difference in number of days
    let difTime = moment.duration(current.diff(given)).asDays();

    if (difTime >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const eventData = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: eventDetails?.title,
    image: eventDetails?.banner,
    description: `${eventDetails?.title} at ${eventDetails?.location}. ${
      eventDetails?.description?.replace(/\s+/g, " ").trim().substr(0, 120) +
      (eventDetails?.description?.length > 120 ? "..." : "")
    }`,
    location: {
      "@type": "Place",
      name: eventDetails?.location,
      address: {
        "@type": "PostalAddress",
        streetAddress: eventDetails?.location,
        addressLocality: eventDetails?.destination,
        addressRegion: "Goa",
        addressCountry: "India",
      },
    },
    startDate: eventDetails?.startDate,
    endDate: eventDetails?.endDate,
    eventStatus:
      eventDetails?.status === "active" ? "EventScheduled" : "EventCancelled",
    eventAttendanceMode: "OfflineEventAttendanceMode",
    organizer: {
      "@type": "Organization",
      name: eventDetails?.user?.name,
      email: eventDetails?.user?.email,
      // logo: "https://followgoa.com/images/logo.png",
    },
  };

  const eventDetailsRSVP = () => {
    let decide = 0;
    if (
      eventDetails !== undefined &&
      eventDetails.ticketTypes !== undefined &&
      eventDetails.ticketTypes !== null
    ) {
      for (let i = 0; i < eventDetails.ticketTypes.length; i++) {
        if (eventDetails.ticketTypes[i].cost > 0) {
          decide++;
        }
      }
    }
    if (decide > 0) {
      return true;
    } else {
      return false;
    }
  };
  const hasWindow = typeof window !== "undefined";

  return (
    <div id="eventdetails">
      {!_.isUndefined(eventDetails?.title) && (
        <Helmet>
          <title>{`${eventDetails?.title} | Followgoa`}</title>
          <script type="application/ld+json">
            {JSON.stringify(eventData)}
          </script>
        </Helmet>
      )}
      <Navbar />
      {eventLoading && <Loader />}
      {eventDetails !== undefined && (
        <div className="topbannerwrapper">
          <img src={banner} className="topbanner" />
        </div>
      )}
      {eventDetails !== undefined && backendError === undefined ? (
        <Container>
          <div className="outerwrapper">
            <div className="titleblock">
              <div className="titlewrapper">
                <div className="title">
                  <span> {title}</span>
                </div>
                {eventDetails !== undefined &&
                  eventDetails.tourRef !== null && (
                    <div className="referencetext">
                      <span>Reference ID: {tourRef}</span>
                    </div>
                  )}
              </div>

              {isBookingAvailable(eventDetails) === 0 ? (
                <div className="buttonwrapper">
                  <span>Booking has not started yet for this event!</span>
                </div>
              ) : isBookingAvailable(eventDetails) === 1 ? (
                <div className="buttonwrapper">
                  <span>Booking has closed for this event</span>
                </div>
              ) : (
                <div className="buttonwrapper">
                  {" "}
                  <CustomButton
                    className="all-events-btn1"
                    name={
                      eventDetails !== undefined && rsvp === true
                        ? "Book"
                        : "RSVP HERE"
                    }
                    onClick={() => {
                      if (
                        eventDetails !== undefined &&
                        eventDetails.type === "event"
                      ) {
                        if (myRedirect !== "" && eveId !== undefined) {
                          history.push(`/bookevent/${myRedirect}`);
                        } else {
                          history.push(`/bookevent/${id}`);
                        }
                      } else if (
                        eventDetails !== undefined &&
                        eventDetails.type === "villa"
                      ) {
                        if (myRedirect !== "" && eveId !== undefined) {
                          history.push(`/bookproperty/${myRedirect}`);
                        } else {
                          history.push(`/bookproperty/${id}`);
                        }
                      } else if (
                        eventDetails !== undefined &&
                        eventDetails.type === "tour"
                      ) {
                        if (myRedirect !== "" && eveId !== undefined) {
                          history.push(`/booktour/${myRedirect}`);
                        } else {
                          history.push(`/booktour/${id}`);
                        }
                      } else {
                        history.push(`/login?name=${"bookscreen"}`);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div className="event-block">
              <div className="leftalign">
                <span className="location">
                  <img src={LocationIcon} className="leftsideimg" /> {location}
                </span>
              </div>
              {/* <div className="leftalign">
                <span className="viewlocation">View Location</span>
              </div> */}
              {eventDetails !== undefined &&
                eventDetails.googleMapLink !== null && (
                  <div className="leftalign">
                    <a
                      className="viewlocation"
                      href={`${eventDetails.googleMapLink}`}
                      target="_blank"
                    >
                      View Location
                    </a>
                  </div>
                )}
              {eventDetails !== undefined &&
                eventDetails.startDate !== null &&
                eventDetails.endDate !== null && (
                  <div className="leftalign">
                    <img src={CalendarIcon} className="leftsideimg" />
                    <span className="locationtext">
                      {" "}
                      {moment(startDate).format("dddd, MMMM Do YYYY")}{" "}
                    </span>
                    <img src={ClockIcon} className="smallicon" />
                    <span className="locationtext">
                      {" "}
                      {moment(startDate).format("h:mm A")}{" "}
                    </span>
                    <span className="details-text-to">To</span>
                    <img src={CalendarIcon} className="smallicon" />
                    <span className="locationtext">
                      {" "}
                      {moment(endDate).format("dddd, MMMM Do YYYY")}{" "}
                    </span>
                    <img src={ClockIcon} className="smallicon" />
                    <span className="locationtext">
                      {" "}
                      {moment(endDate).format("h:mm A")}{" "}
                    </span>
                  </div>
                )}
            </div>
            <div className="ticket-block">
              <span className="ticket-date startdate">
                {ticketSaleStartdate !== undefined &&
                  ticketSaleStartdate !== null && (
                    <>
                      <span className="locationtextbold">
                        Ticket Sale Start Date :
                      </span>
                      <img src={CalendarIcon} className="leftsideimg" />
                      <span className="locationtext">
                        {" "}
                        {moment(ticketSaleStartdate).format(
                          "dddd, MMMM Do YYYY"
                        )}{" "}
                        <img src={ClockIcon} className="smallicon" />
                        <span className="locationtext">
                          {" "}
                          {moment(ticketSaleStartdate).format("h:mm A")}{" "}
                        </span>
                      </span>
                    </>
                  )}
              </span>
              <span className="ticket-date">
                {ticketSaleEndDate !== undefined &&
                  ticketSaleEndDate !== null && (
                    <>
                      <span className="locationtextbold ">
                        Ticket Sale End Date :
                      </span>
                      <img src={CalendarIcon} className="smallicon" />
                      <span className="locationtext">
                        {" "}
                        {moment(ticketSaleEndDate).format(
                          "dddd, MMMM Do YYYY"
                        )}{" "}
                        <img src={ClockIcon} className="smallicon" />
                        <span className="locationtext">
                          {" "}
                          {moment(ticketSaleEndDate).format("h:mm A")}{" "}
                        </span>
                      </span>
                    </>
                  )}
              </span>
            </div>
          </div>
          <Row className="mb-5">
            <Col lg={12}>
              <div className="outerwrapper">
                <h2 className="about ">About</h2>
                <span className="aboutdes">
                  {Description === undefined
                    ? eventDetails !== undefined &&
                      eventDetails.description !== undefined
                      ? String(description).slice(0, 370)
                      : ""
                    : Description}
                </span>{" "}
                {String(description).length > 370 ? (
                  readMore === false ? (
                    <span
                      className="readmore"
                      onClick={() => {
                        setReadMore(true);
                        setDescription(
                          String(description).slice(0, description.length)
                        );
                      }}
                    >
                      Read More
                    </span>
                  ) : (
                    <span
                      className="readmore"
                      onClick={() => {
                        setReadMore(false);
                        setDescription(String(description).slice(0, 370));
                      }}
                    >
                      Show Less
                    </span>
                  )
                ) : (
                  <></>
                )}
                <Accordion flush>
                  {/* {eventDetails !== undefined && eventDetails.type === "event" && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="about">
                        Event Highlights
                      </Accordion.Header>
                      <Accordion.Body>
                        {eventItineraries !== undefined &&
                        eventItineraries.length !== 0 ? (
                          eventItineraries.map((itm) => (
                            <div className="highlight">
                              <h4 className="days">
                                Day {itm.day !== undefined ? itm.day : ""}
                              </h4>
                              <h5 className="eventtitle">
                                {itm.title !== undefined ? itm.title : ""}
                              </h5>

                              <Row className="d-flex ">
                                <Col lg={12}>
                                  <p className="toptitle"> Event Description</p>
                                  <p className="eventcontent">
                                    {itm?.description !== undefined
                                      ? itm.description
                                      : ""}
                                  </p>
                                </Col>
                                <Col lg={12}>
                                  {itm.accommodation !== undefined &&
                                    itm.accommodation !== "" && (
                                      <p className="toptitle">
                                        {" "}
                                        Accommodation Description
                                      </p>
                                    )}
                                  <p className="eventcontent">
                                    {itm?.accommodation !== undefined
                                      ? itm.accommodation
                                      : ""}
                                  </p>
                                </Col>
                                <Col lg={12}>
                                  {itm.food !== undefined &&
                                    itm.food !== "" && (
                                      <p className="toptitle">
                                        Food Description
                                      </p>
                                    )}
                                  <h6 className="eventcontent">
                                    {itm.food !== undefined ? itm.food : ""}
                                  </h6>
                                </Col>
                              </Row>

                              <h6 className="eventcontent">
                                {itm.location !== undefined &&
                                  itm.location !== "" && (
                                    <img
                                      src={LocationIcon}
                                      className="leftsideimg"
                                    />
                                  )}{" "}
                                {itm.location !== undefined ? itm.location : ""}
                              </h6>
                            </div>
                          ))
                        ) : (
                          <div className="nothing-found">
                            <NoData className="no-data" />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )} */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      className="about"
                      onClick={() => {
                        if (myRedirect !== "" && eveId !== undefined) {
                          // console.log("faqs 3");
                          GetFaqs({ eventId: eveId });
                          // eventItinerariesCalled({ eventId: eveId });
                        } else {
                          if (id !== undefined) {
                            // console.log("getfaqs 4");
                            GetFaqs({ eventId: id });
                            // eventItinerariesCalled({ eventId: eveId });
                          }
                        }
                      }}
                    >
                      FAQs
                    </Accordion.Header>
                    <Accordion.Body>
                      {faqsLoading && (
                        <Spinner
                          className=""
                          animation="border"
                          variant="dark"
                          role="status"
                          aria-hidden="true"
                        ></Spinner>
                      )}
                      <ol>
                        {faqs !== undefined && faqs.length > 0 ? (
                          faqs.map((item) => {
                            return (
                              <div
                                className="leftalign mb-3"
                                key={`villfq${item.id}`}
                              >
                                <li>
                                  <h3 className="question">{item.question}</h3>
                                </li>
                                <p className="answer">{item.answer}</p>
                              </div>
                            );
                          })
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <div className="nothing-found">
                                {hasWindow && (
                                  <NoData className="no-data faq-no-data" />
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            {eventDetails !== undefined &&
              eventDetails.eventImages !== undefined &&
              eventDetails.eventImages.length > 2 && (
                <Col lg={7}>
                  <div className="slider-div">
                    <div className="title">
                      <span>Gallery</span>
                    </div>
                    <Carousel interval={null} indicators={false}>
                      {eventDetails.eventImages.map((item) => (
                        <Carousel.Item key={`vill2fq${item.id}`}>
                          <div className="slider">
                            <img src={item.lg} className="slider-img" />
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </Col>
              )}
            {eventDetails !== undefined &&
              eventDetails.eventImages !== undefined &&
              eventDetails.eventImages.length > 0 &&
              eventDetails.eventImages.length < 1 && (
                <Col lg={7}>
                  <div className="slider-div">
                    <div className="title">
                      <span>Gallery</span>
                    </div>
                    {/* <Carousel interval={null} indicators={false}> */}
                    {eventDetails.eventImages.map((item) => (
                      // <Carousel.Item>
                      <div className="slider" key={`gallert${item.id}`}>
                        <img src={item.lg} className="slider-img" />
                      </div>
                      // </Carousel.Item>
                    ))}
                    {/* </Carousel> */}
                  </div>
                </Col>
              )}
            <Col lg={5}>
              <div
                className={
                  eventDetails.eventImages !== undefined &&
                  eventDetails.eventImages.length !== 0
                    ? "outerwrappershare"
                    : "outerwrappershare outersharediv"
                }
              >
                <div className="share-event">
                  <span>Share this Event</span>
                </div>
                <div className="d-flex">
                  <WhatsappShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={whatsapp} className="sharewhatsappimg" />
                  </WhatsappShareButton>

                  <FacebookShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={fb} className="shareimg" />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`${process.env.RAZZLE_APP_APPLINK}/events/${id}`}
                    hashtag="#programing joke"
                  >
                    <img src={twittericon} className="shareimg" />
                  </TwitterShareButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div>{hasWindow && <NoData className="no-data2" />}</div>
      )}
      <MainFooter />
    </div>
  );
}

const mapStateToProps = ({ user = {}, event = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", undefined);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const faqsLoading = _.get(event, "faqsLoading", false);
  const faqs = _.get(event, "faqs", []);
  const faqsError = _.get(event, "faqsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const eventItinerariesLoading = _.get(
    event,
    "eventItinerariesLoading",
    false
  );
  const eventItineraries = _.get(event, "eventItineraries", undefined);
  const eventItinerariesError = _.get(
    event,
    "eventItinerariesError",
    undefined
  );

  return {
    eventLoading,
    eventDetails,
    eventError,
    faqsLoading,
    faqs,
    faqsError,
    userLoggedIn,
    eventItinerariesLoading,
    eventItineraries,
    eventItinerariesError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  GetFaqs: (data) => dispatch({ type: "getFaqsCalled", payload: data }),
  clearBackendErrorAsync: () => dispatch(clearBackendError()),
  eventItinerariesCalled: (data) =>
    dispatch({ type: "eventItinerariesCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
