import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "UserSlice",
  initialState: {
    signInLoading: false,
    signedIn: undefined,
    signInStatus: false,
    signInError: undefined,
    userDetailsLoading: false,
    userDetails: undefined,
    userDetailsError: undefined,
    signUpLoading: undefined,
    signedUp: undefined,
    signUpError: undefined,
    forgotPasswordLoading: false,
    forgotPassword: undefined,
    forgotPasswordError: undefined,

    operatorSignUpLoading: false,
    operatorSignUp: undefined,
    operatorSignUpError: undefined,
    searchLoading: undefined,
    searchResults: [],
    searchError: undefined,
    searchBaseOnTitle: [],

    userProfileLoading: false,
    userProfile: null,
    userProfileError: undefined,

    joinOperatorLoading: false,
    joinOperator: null,
    joinOperatorError: undefined,

    resendEmailVerificationLoading: false,
    resendEmailVerificationSuc: false,
    resendEmailVerificationError: undefined,

    changePasswordLoading: false,
    changePassword: null,
    changePasswordError: undefined,
    changePasswordComplete: false,

    changeProfileImageLoading: false,
    changeProfileImage: null,
    changeProfileImageError: undefined,

    editProfiledataLoading: false,
    editProfiledata: null,
    editProfiledataError: undefined,

    passGoogleAccessTokenLoading: false,
    passGoogleAccessToken: undefined,
    passGoogleAccessTokenError: undefined,

    guestFormSignupLoading: false,
    guestFormSignup: undefined,
    guestFormError: undefined,
    changeCounter: 0,
  },
  reducers: {
    resetUserReduxOnMount: (state, action) => {
      return {
        ...state,
        signInLoading: false,

        signInError: undefined,
        userDetailsLoading: false,

        userDetailsError: undefined,
        signUpLoading: undefined,

        signUpError: undefined,
        forgotPasswordLoading: false,

        forgotPasswordError: undefined,

        operatorSignUpLoading: false,

        operatorSignUpError: undefined,
        searchLoading: undefined,

        searchError: undefined,

        userProfileLoading: false,

        userProfileError: undefined,

        joinOperatorLoading: false,

        joinOperatorError: undefined,

        resendEmailVerificationLoading: false,

        resendEmailVerificationError: undefined,

        changePasswordLoading: false,

        changePasswordError: undefined,

        changeProfileImageLoading: false,

        changeProfileImageError: undefined,

        editProfiledataLoading: false,

        editProfiledataError: undefined,

        passGoogleAccessTokenLoading: false,

        passGoogleAccessTokenError: undefined,
        guestFormError: undefined,
      };
    },
    guestFormLoadingStart: (state, action) => {
      return {
        ...state,
        guestFormSignupLoading: true,
        guestFormSignup: undefined,
        guestFormError: undefined,
      };
    },
    guestFormSuccess: (state, action) => {
      return {
        ...state,
        guestFormSignupLoading: false,
        guestFormSignup: action.payload,
        guestFormError: undefined,
      };
    },
    guestFormFailure: (state, action) => {
      return {
        ...state,
        guestFormSignupLoading: false,
        guestFormSignup: undefined,
        guestFormError: undefined,
      };
    },
    resetChangeCounter: (state, action) => {
      return {
        ...state,
        changeCounter: 0,
      };
    },
    changeCounterSuccess: (state, action) => {
      return {
        ...state,
        changeCounter: action.payload,
      };
    },
    signInStart: (state, action) => {
      return {
        ...state,
        signInLoading: true,
        signedIn: undefined,
        signInStatus: false,
        signInError: undefined,
      };
    },
    signInSuccess: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signedIn: action.payload,
        signInStatus: true,
        signInError: undefined,
      };
    },
    signInFailure: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signedIn: undefined,
        signInStatus: false,
        signInError: "Invalid login credentials",
      };
    },

    resetSignInState: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signInError: undefined,
        // passGoogleAccessTokenSuccess: undefined,
        passGoogleAccessTokenError: undefined,
      };
    },

    setUserDetailsStart: (state, action) => {
      return {
        ...state,
        userDetailsLoading: true,
        userDetails: undefined,
        userDetailsError: undefined,
      };
    },

    setUserDetailsSuccess: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: action.payload,
        userDetailsError: undefined,
      };
    },

    setUserDetailsFailure: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: undefined,
        userDetailsError: action.payload,
      };
    },
    signUpStart: (state, action) => {
      return {
        ...state,
        signUpLoading: true,
        signedUp: undefined,
        signUpError: undefined,
      };
    },
    signUpSuccess: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: action.payload,
        signUpError: undefined,
      };
    },
    signUpFailure: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: undefined,
        signUpError: action.payload,
      };
    },
    resetSignUp: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: undefined,
        signUpError: undefined,
      };
    },
    forgotPasswordStart: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPassword: undefined,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: action.payload,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordFailure: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: undefined,
        forgotPasswordError: action.payload,
      };
    },

    resetForgotPasswordState: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: undefined,
        forgotPasswordError: undefined,
      };
    },

    operatorSignUpStart: (state, action) => {
      return {
        ...state,
        operatorSignUpLoading: true,
        operatorSignUp: undefined,
        operatorSignUpError: undefined,
      };
    },

    operatorSignUpSuccess: (state, action) => {
      return {
        ...state,
        operatorSignUpLoading: false,
        operatorSignUp: action.payload,
        operatorSignUpError: undefined,
      };
    },

    operatorSignUpFailure: (state, action) => {
      return {
        ...state,
        operatorSignUpLoading: false,
        operatorSignUp: undefined,
        operatorSignUpError: action.payload,
      };
    },

    resetOperatorSignUpState: (state, action) => {
      return {
        ...state,
        operatorSignUpLoading: false,
        operatorSignUp: undefined,
        operatorSignUpError: undefined,
      };
    },
    searchLoadingStart: (state, action) => {
      return {
        ...state,
        searchLoading: true,
        searchResults: [],
        searchError: undefined,
      };
    },
    searchSuccess: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchResults: action.payload,
        searchError: undefined,
      };
    },
    searchResultError: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchResults: [],
        searchError: action.payload,
      };
    },

    userLogout: (state, action) => {
      return {};
    },
    clearUserDetails: (state, action) => {
      return {
        ...state,
        userDetails: "",
      };
    },

    searchBasedOnTitleLoading: (state, action) => {
      return {
        ...state,
        searchLoading: true,
        searchBaseOnTitle: [],
        searchError: undefined,
      };
    },
    searchBasedOnTitleSuccess: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchBaseOnTitle: action.payload,
        searchError: undefined,
      };
    },
    searchBasedOnTitleFailure: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchBaseOnTitle: [],
        searchError: action.payload,
      };
    },

    profileDataStart: (state, action) => {
      return {
        ...state,
        userProfileLoading: action.payload,
      };
    },

    profileDataSuccess: (state, action) => {
      return {
        ...state,
        // userProfile: action.payload,
        userDetails: action.payload,
      };
    },

    profileDataFailure: (state, action) => {
      return {
        ...state,
        userProfileError: action.payload,
      };
    },

    joinAsOperatorStart: (state, action) => {
      return {
        ...state,
        joinOperatorLoading: action.payload,
      };
    },

    joinAsOperatorSuccess: (state, action) => {
      return {
        ...state,
        joinOperator: action.payload,
      };
    },

    joinAsOperatorFailure: (state, action) => {
      return {
        ...state,
        joinOperatorError: action.payload,
      };
    },

    clearSearchError: (state, action) => {
      return {
        ...state,
        searchError: undefined,
      };
    },

    resendVerificationStart: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: true,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: undefined,
      };
    },
    resendVerificationSuccess: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: true,
        resendEmailVerificationError: undefined,
      };
    },
    resendVerificationFailure: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: action.payload,
      };
    },

    resendEmailVerificationStateReset: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: undefined,
      };
    },

    changePasswordStart: (state, action) => {
      return {
        ...state,
        changePasswordLoading: action.payload,
      };
    },

    changePasswordSuccess: (state, action) => {
      return {
        ...state,
        changePassword: action.payload,
      };
    },

    changePasswordFailure: (state, action) => {
      return {
        ...state,
        changePasswordError: action.payload,
      };
    },

    clearPasswordError: (state, action) => {
      return {
        ...state,
        changePasswordError: undefined,
      };
    },

    changeProfileImageStart: (state, action) => {
      return {
        ...state,
        changeProfileImageLoading: action.payload,
      };
    },

    changeProfileImageSuccess: (state, action) => {
      return {
        ...state,
        changeProfileImage: action.payload,
      };
    },

    changeProfileImageFailure: (state, action) => {
      return {
        ...state,
        changeProfileImageError: action.payload,
      };
    },

    editProfileDataStart: (state, action) => {
      return {
        ...state,
        editProfileDataLoading: action.payload,
      };
    },

    editProfileDataSuccess: (state, action) => {
      return {
        ...state,
        editProfileData: action.payload,
      };
    },

    editProfileDataFailure: (state, action) => {
      return {
        ...state,
        editProfileDataError: action.payload,
      };
    },
    passGoogleAccessTokenLoadingStart: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: true,
        passGoogleAccessToken: undefined,
        passGoogleAccessTokenError: undefined,
      };
    },
    passGoogleAccessTokenSuccess: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: false,
        passGoogleAccessToken: action.payload,
        passGoogleAccessTokenError: undefined,
      };
    },
    passGoogleAccessTokenFailure: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: false,
        passGoogleAccessToken: undefined,
        passGoogleAccessTokenError: action.payload,
      };
    },
    clearEditProfileError: (state, action) => {
      return {
        ...state,
        editProfileDataError: undefined,
      };
    },
  },
});

export default userSlice.reducer;
export const {
  signInStart,
  signInSuccess,
  signInFailure,
  resetSignInState,
  setUserDetails,
  setUserDetailsStart,
  setUserDetailsSuccess,
  setUserDetailsFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
  resetSignUp,
  userLogout,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetForgotPasswordState,
  operatorSignUpStart,
  operatorSignUpSuccess,
  operatorSignUpFailure,
  resetOperatorSignUpState,
  searchLoadingStart,
  searchSuccess,
  searchResultError,

  profileDataStart,
  profileDataSuccess,
  profileDataFailure,

  joinAsOperatorStart,
  joinAsOperatorSuccess,
  joinAsOperatorFailure,
  clearSearchError,
  resendVerificationStart,
  resendVerificationSuccess,
  resendVerificationFailure,
  resendEmailVerificationStateReset,

  changePasswordSuccess,
  changePasswordStart,
  changePasswordFailure,

  changeProfileImageStart,
  changeProfileImageSuccess,
  changeProfileImageFailure,
  clearPasswordError,

  editProfileDataStart,
  editProfileDataSuccess,
  editProfileDataFailure,
  passGoogleAccessTokenLoadingStart,
  passGoogleAccessTokenSuccess,
  passGoogleAccessTokenFailure,
  clearEditProfileError,
  changeCounterSuccess,
  resetChangeCounter,
  resetUserReduxOnMount,
  guestFormLoadingStart,
  guestFormSuccess,
  guestFormFailure,
  clearUserDetails,
} = userSlice.actions;
