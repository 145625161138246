import { createSlice } from "@reduxjs/toolkit";

const eventSlice = createSlice({
  name: "event",
  initialState: {
    eventDetailsLoading: false,
    eventDetails: undefined,
    eventDetailsError: undefined,

    faqsLoading: false,
    faqs: [],
    faqsError: undefined,

    bookEventLoading: undefined,
    bookEventDetails: undefined,
    bookEventError: undefined,

    bookingHistoryLoading: false,
    bookingHistory: undefined,
    bookingHistoryError: undefined,

    bookingHistoryDetailLoading: false,
    bookingHistoryDetail: undefined,
    bookingHistoryDetailError: undefined,

    getCountForSearchLoading: false,
    getCount: undefined,
    getCountForSearchError: undefined,
    eventItinerariesLoading: false,
    eventItineraries: undefined,
    eventItinerariesError: undefined,

    getCountForBookingLoading: false,
    getCountBooking: undefined,
    getCountForBookingError: undefined,

    getBookingEventDetailsLoading: false,
    getBookingEventDetails: undefined,
    getBookingEventDetailsError: undefined,

    eventTicketsLoading: false,
    eventTickets: undefined,
    eventTicketsError: undefined,

    villaDetailLoading: false,
    villaDetails: undefined,
    villaDetailsError: undefined,

    villaFacilityLoading: false,
    villaFacility: undefined,
    villaFacilityError: undefined,

    villaBookLoading: false,
    villaBooking: undefined,
    villaBookingError: undefined,

    afterVillaBookingDetailsLoading: false,
    afterVillaBookingDetails: undefined,
    afterVillaBookingDetailsError: undefined,

    tourBookingLoading: false,
    tourBooking: undefined,
    tourBookingError: undefined,

    titleForSearch: undefined,
    locationForSearch: undefined,
    categoryForSearch: undefined,
    categoryIdForSearch: undefined,


    treidForCategory: false,
    triedForLocation: false,
    callFromEventNav: false,

    validateCouponCodeLoading: false,
    validateCouponCode: undefined,
    validateCouponCodeError: undefined,

    bookingDetailsForGuestLoading: false,
    bookingDetailsForGuest: undefined,
    bookingDetailsError: undefined,

    alreadyBookedDatesLoading:false,
    alreadyBookedDates : undefined,
    alreadyBookedDatesError : undefined,
  },

  reducers: {
    alreadyBookedDatesLoadingStart : (state,action)=>{
      return{
        ...state,
        alreadyBookedDatesLoading:true,
        alreadyBookedDates : undefined,
        alreadyBookedDatesError : undefined,
      }
    },
    alreadyBookedDatesSuccess : (state,action)=>{
      return{
        ...state,
        alreadyBookedDatesLoading:false,
        alreadyBookedDates : action.payload,
        alreadyBookedDatesError : undefined,
      }
    },
    alreadyBookedDatesFailure:(state,action)=>{
      return{
        ...state,
        alreadyBookedDatesLoading:false,
        alreadyBookedDates : undefined,
        alreadyBookedDatesError : action.payload,
      }
    },
    clearCouponResult : (state,action)=>{
      return{
        ...state,
        validateCouponCodeLoading: false,
        validateCouponCode: undefined,
        validateCouponCodeError: undefined,
      }
    },
    bookingDetailsForGuestLoadingStart: (state, action) => {
      return {
        ...state,
        bookingDetailsForGuestLoading: true,
        bookingDetailsForGuest: undefined,
        bookingDetailsError: undefined,
      };
    },
    bookingDetailsForGuestSuccess: (state, action) => {
      return {
        ...state,
        bookingDetailsForGuestLoading: false,
        bookingDetailsForGuest: action.payload,
        bookingDetailsError: undefined,
      };
    },
    bookingDetailsForGuestFailure: (state, action) => {
      return {
        ...state,
        bookingDetailsForGuestLoading: false,
        bookingDetailsForGuest: undefined,
        bookingDetailsError: action.payload,
      };
    },
    resetEventReduxOnMount: (state, action) => {
      return {
        ...state,
        eventDetailsLoading: false,

        eventDetailsError: undefined,

        faqsLoading: false,

        faqsError: undefined,

        bookEventLoading: undefined,

        bookEventError: undefined,

        bookingHistoryLoading: false,

        bookingHistoryError: undefined,

        bookingHistoryDetailLoading: false,

        bookingHistoryDetailError: undefined,

        getCountForSearchLoading: false,

        getCountForSearchError: undefined,
        eventItinerariesLoading: false,

        eventItinerariesError: undefined,

        getCountForBookingLoading: false,

        getCountForBookingError: undefined,

        getBookingEventDetailsLoading: false,

        getBookingEventDetailsError: undefined,

        eventTicketsLoading: false,

        eventTicketsError: undefined,

        villaDetailLoading: false,

        villaDetailsError: undefined,

        villaFacilityLoading: false,

        villaFacilityError: undefined,

        villaBookLoading: false,

        villaBookingError: undefined,

        afterVillaBookingDetailsLoading: false,

        afterVillaBookingDetailsError: undefined,

        tourBookingLoading: false,

        tourBookingError: undefined,
      };
    },
    callFromEventNavTrial: (state, action) => {
      return {
        ...state,
        callFromEventNav: action.payload,
      };
    },
    clearCallFromEventNav: (state, action) => {
      return {
        ...state,
        callFromEventNav: false,
      };
    },
    titleForSearchLoading: (state, action) => {
      return {
        ...state,
        titleForSearch: action.payload,
      };
    },
    categoryForSearchLoading: (state, action) => {
      return {
        ...state,
        categoryForSearch: action.payload,
      };
    },
    locationForSearchLoading: (state, action) => {
      return {
        ...state,

        locationForSearch: action.payload,
      };
    },
    clearLoacationTitleForSearch: (state, action) => {
      return {
        ...state,
        titleForSearch: undefined,
      };
    },
    clearCategoryForSearch: (state, action) => {
      return {
        ...state,
        categoryForSearch: undefined,
        treidForCategory: false,
      };
    },
    clearLocationForSearch: (state, action) => {
      return {
        ...state,
        locationForSearch: undefined,

        triedForLocation: false,
      };
    },
    triedForCategorySearch: (state, action) => {
      return {
        ...state,
        treidForCategory: action.payload,
      };
    },
    triedForLocationSearch: (state, action) => {
      return {
        ...state,

        triedForLocation: action.payload,
      };
    },
    eventDetailsStart: (state, action) => {
      return {
        ...state,
        eventDetailsLoading: action.payload,
        eventDetails: undefined,
        eventDetailsError: undefined,
      };
    },

    eventDetailsSuccess: (state, action) => {
      return {
        ...state,
        eventDetailsLoading: false,
        eventDetails: action.payload,
        eventDetailsError: undefined,
      };
    },

    eventDetailsFail: (state, action) => {
      return {
        ...state,
        eventDetailsLoading: false,
        eventDetails: undefined,
        eventDetailsError: action.payload,
      };
    },

    eventFaqsStart: (state, action) => {
      return {
        ...state,
        faqsLoading: action.payload,
        faqs: [],
        faqsError: undefined,
      };
    },

    eventFaqsSuccess: (state, action) => {
      return {
        ...state,
        faqsLoading: false,
        faqs: action.payload,
        faqsError: undefined,
      };
    },

    eventFaqsFail: (state, action) => {
      return {
        ...state,
        faqsLoading: false,
        faqs: [],
        faqsError: action.payload,
      };
    },
    bookEventLoadingStart: (state, action) => {
      return {
        ...state,
        bookEventLoading: true,
        bookEventDetails: undefined,
        bookEventError: undefined,
      };
    },
    bookEventSuccess: (state, action) => {
      return {
        ...state,
        bookEventLoading: false,
        bookEventDetails: action.payload,
        bookEventError: undefined,
      };
    },
    bookEventFailure: (state, action) => {
      return {
        ...state,
        bookEventLoading: false,
        bookEventDetails: undefined,
        bookEventError: action.payload,
      };
    },

    clearBackendError: (state, action) => {
      return {
        ...state,
        eventDetailsError: undefined,
        faqsError: undefined,
        bookEventError: undefined,
        bookingHistoryError: undefined,
        eventItinerariesError: undefined,
        getCountForBookingError: undefined,
        getBookingEventDetailsError: undefined,
        eventTicketsError: undefined,
        villaDetailsError: undefined,
        villaFacilityError: undefined,
        villaBookingError: undefined,
        bookingHistoryDetailError: undefined,
        getCountForSearchError: undefined,
        tourBookingError: undefined,
        validateCouponCodeError: undefined,
      };
    },
    clearEventRedux: (state, action) => {
      return {};
    },
    resetBookingEvent: (state, action) => {
      return {
        ...state,
        bookEventLoading: false,
        bookEventDetails: undefined,
        bookEventError: undefined,
        validateCouponCodeLoading: false,
        validateCouponCode: undefined,
        validateCouponCodeError: undefined,
      };
    },
    resetBookingVilla: (state, action) => {
      return {
        ...state,
        villaBookLoading: false,
        villaBooking: undefined,
        villaBookingError: undefined,
        validateCouponCodeLoading: false,
        validateCouponCode: undefined,
        validateCouponCodeError: undefined,
      };
    },
    resetTourBooking: (state, action) => {
      //console.log("UNMOUNT CALLED");
      return {
        ...state,
        tourBookingLoading: false,
        tourBooking: undefined,
        tourBookingError: undefined,
        validateCouponCodeLoading: false,
        validateCouponCode: undefined,
        validateCouponCodeError: undefined,
      };
    },
    bookingHistoryLoadingStart: (state, action) => {
      return {
        ...state,
        bookingHistoryLoading: true,
        bookingHistory: undefined,
        bookingHistoryError: undefined,
      };
    },
    bookinhHistorySuccess: (state, action) => {
      return {
        ...state,
        bookingHistoryLoading: false,
        bookingHistory: action.payload,
        bookingHistoryError: undefined,
      };
    },
    bookingHistoryFailure: (state, action) => {
      return {
        ...state,
        bookingHistoryLoading: false,
        bookingHistory: undefined,
        bookingHistoryError: action.payload,
      };
    },

    bookingHistoryDetailLoadingStart: (state, action) => {
      return {
        ...state,
        bookingHistoryDetailLoading: true,
        bookingHistoryDetail: undefined,
        bookingHistoryDetailError: undefined,
      };
    },
    bookinhHistoryDetailSuccess: (state, action) => {
      return {
        ...state,
        bookingHistoryDetailLoading: false,
        bookingHistoryDetail: action.payload,
        bookingHistoryDetailError: undefined,
      };
    },
    bookingHistoryDetailFailure: (state, action) => {
      return {
        ...state,
        bookingHistoryDetailLoading: false,
        bookingHistoryDetail: undefined,
        bookingHistoryDetailError: action.payload,
      };
    },
    getCountForSearchLoadingStart: (state, action) => {
      return {
        ...state,
        getCountForSearchLoading: true,
        getCount: undefined,
        getCountForSearchError: undefined,
      };
    },
    getCountForSearchSuccess: (state, action) => {
      return {
        ...state,
        getCountForSearchLoading: false,
        getCount: action.payload,
        getCountForSearchError: undefined,
      };
    },
    getCountForSearchFailure: (state, action) => {
      return {
        ...state,
        getCountForSearchLoading: false,
        getCount: undefined,
        getCountForSearchError: action.payload,
      };
    },
    getCountForBookingLoadingStart: (state, action) => {
      return {
        ...state,
        getCountForBookingLoading: true,
        getCountBooking: undefined,
        getCountForBookingError: undefined,
      };
    },
    getCountForBookingSuccess: (state, action) => {
      return {
        ...state,
        getCountForBookingLoading: false,
        getCountBooking: action.payload,
        getCountForBookingError: undefined,
      };
    },
    getCountForBookingFailure: (state, action) => {
      return {
        ...state,
        getCountForBookingLoading: false,
        getCountBooking: undefined,
        getCountForBookingError: action.payload,
      };
    },
    eventItinerariesStart: (state, action) => {
      return {
        ...state,
        eventItinerariesLoading: true,
        eventItineraries: undefined,
        eventItinerariesError: undefined,
      };
    },
    eventItinerariesSuccess: (state, action) => {
      return {
        ...state,
        eventItinerariesLoading: false,
        eventItineraries: action.payload,
        eventItinerariesError: undefined,
      };
    },
    eventItinerariesFailure: (state, action) => {
      return {
        ...state,
        eventItinerariesLoading: false,
        eventItineraries: undefined,
        eventItinerariesError: action.payload,
      };
    },
    getBookingEventDetailsLoadingStart: (state, action) => {
      return {
        ...state,
        getBookingEventDetailsLoading: true,
        getBookingEventDetails: undefined,
        getBookingEventDetailsError: undefined,
      };
    },
    getBookingEventDetailSuccss: (state, action) => {
      return {
        ...state,
        getBookingEventDetailsLoading: false,
        getBookingEventDetails: action.payload,
        getBookingEventDetailsError: undefined,
      };
    },
    getBookingEventError: (state, action) => {
      return {
        ...state,
        getBookingEventDetailsLoading: false,
        getBookingEventDetails: undefined,
        getBookingEventDetailsError: action.payload,
      };
    },
    clearBookingEventOnUnmount: (state, action) => {
      return {
        ...state,
        getBookingEventDetails: undefined,
      };
    },

    eventTicketStart: (state, action) => {
      return {
        ...state,
        eventTicketsLoading: true,
        eventTickets: undefined,
        eventTicketsError: undefined,
      };
    },

    eventTicketSuccess: (state, action) => {
      return {
        ...state,
        eventTicketsLoading: false,
        eventTickets: action.payload,
        eventTicketsError: undefined,
      };
    },

    eventTicketFailure: (state, action) => {
      return {
        ...state,
        eventTicketsLoading: false,
        eventTickets: undefined,
        eventTicketsError: action.payload,
      };
    },

    clearEventTickets: (state, action) => {
      return {
        ...state,
        eventTicketsLoading: false,
        eventTickets: undefined,
        eventTicketsError: undefined,
      };
    },
    villDetailsLoadingStart: (state, action) => {
      return {
        ...state,
        villaDetailLoading: true,
        villaDetails: undefined,
        villaDetailsError: undefined,
      };
    },
    villaDetailsSuccess: (state, action) => {
      return {
        ...state,
        villaDetailLoading: false,
        villaDetails: action.payload,
        villaDetailsError: undefined,
      };
    },
    villaDetailsFailure: (state, action) => {
      return {
        ...state,
        villaDetailLoading: false,
        villaDetails: undefined,
        villaDetailsError: action.payload,
      };
    },
    villaFacilityLoadingStart: (state, action) => {
      return {
        ...state,
        villaFacilityLoading: true,
        villaFacility: undefined,
        villaFacilityError: undefined,
      };
    },
    villaFacilitySuccess: (state, action) => {
      return {
        ...state,
        villaFacilityLoading: false,
        villaFacility: action.payload,
        villaFacilityError: undefined,
      };
    },
    villaFacilityFailure: (state, action) => {
      return {
        ...state,
        villaFacilityLoading: false,
        villaFacility: undefined,
        villaFacilityError: action.payload,
      };
    },

    villaBookingLoadingStart: (state, action) => {
      return {
        ...state,
        villaBookLoading: true,
        villaBooking: undefined,
        villaBookingError: undefined,
      };
    },
    villaBookingSuccess: (state, action) => {
      return {
        ...state,
        villaBookLoading: false,
        villaBooking: action.payload,
        villaBookingError: undefined,
      };
    },
    villaBookingFailure: (state, action) => {
      return {
        ...state,
        villaBookLoading: false,
        villaBooking: undefined,
        villaBookingError: action.payload,
      };
    },
    afterVillaBookingDetailsLoadingStart: (state, action) => {
      return {
        ...state,
        afterVillaBookingDetailsLoading: true,
        afterVillaBookingDetails: undefined,
        afterVillaBookingDetailsError: undefined,
      };
    },
    afterVillaBookingDetailsSuccess: (state, action) => {
      return {
        ...state,
        afterVillaBookingDetailsLoading: false,
        afterVillaBookingDetails: action.payload,
        afterVillaBookingDetailsError: undefined,
      };
    },
    afterVillaBookingDetailsFailure: (state, action) => {
      return {
        ...state,
        afterVillaBookingDetailsLoading: false,
        afterVillaBookingDetails: undefined,
        afterVillaBookingDetailsError: action.payload,
      };
    },

    tourBookingLoadingstart: (state, action) => {
      return {
        ...state,
        tourBookingLoading: true,
        tourBooking: undefined,
        tourBookingError: undefined,
      };
    },
    tourBookingSuccess: (state, action) => {
      return {
        ...state,
        tourBookingLoading: false,
        tourBooking: action.payload,
        tourBookingError: undefined,
      };
    },
    tourBookingFailure: (state, action) => {
      return {
        ...state,
        tourBookingLoading: false,
        tourBooking: undefined,
        tourBookingError: action.payload,
      };
    },

    validateCouponCodeStart: (state, action) => {
      return {
        ...state,
        validateCouponCodeLoading: true,
        validateCouponCode: undefined,
        validateCouponCodeError: undefined,
      };
    },

    validateCouponCodeSuccess: (state, action) => {
      return {
        ...state,
        validateCouponCodeLoading: false,
        validateCouponCode: action.payload,
        validateCouponCodeError: undefined,
      };
    },

    validateCouponCodeFailure: (state, action) => {
      return {
        ...state,
        validateCouponCodeLoading: false,
        validateCouponCode: undefined,
        validateCouponCodeError: action.payload,
      };
    },
  },
});

export default eventSlice.reducer;
export const {
  eventDetailsStart,
  eventDetailsSuccess,
  eventDetailsFail,

  eventFaqsStart,
  eventFaqsSuccess,
  eventFaqsFail,
  clearBackendError,
  bookEventLoadingStart,
  bookEventSuccess,
  bookEventFailure,
  resetBookingEvent,
  bookingHistoryLoadingStart,
  bookinhHistorySuccess,
  bookingHistoryFailure,
  bookingHistoryDetailLoadingStart,
  bookingHistoryDetailFailure,
  bookinhHistoryDetailSuccess,
  getCountForSearchLoadingStart,
  getCountForSearchSuccess,
  getCountForSearchFailure,
  eventItinerariesStart,
  eventItinerariesSuccess,
  eventItinerariesFailure,
  getCountForBookingLoadingStart,
  getCountForBookingSuccess,
  getCountForBookingFailure,
  getBookingEventDetailsLoadingStart,
  getBookingEventDetailSuccss,
  getBookingEventDetailsError,
  clearBookingEventOnUnmount,
  eventTicketStart,
  eventTicketSuccess,
  eventTicketFailure,
  clearEventTickets,
  villDetailsLoadingStart,
  villaDetailsSuccess,
  villaDetailsFailure,
  villaFacilityLoadingStart,
  villaFacilitySuccess,
  villaFacilityFailure,
  villaBookingLoadingStart,
  villaBookingSuccess,
  villaBookingFailure,
  resetBookingVilla,
  afterVillaBookingDetailsLoadingStart,
  afterVillaBookingDetailsSuccess,
  afterVillaBookingDetailsFailure,
  tourBookingLoadingstart,
  tourBookingSuccess,
  tourBookingFailure,
  resetTourBooking,
  locationForSearchLoading,
  titleForSearchLoading,
  clearLoacationTitleForSearch,
  categoryForSearchLoading,
  clearCategoryForSearch,
  clearLocationForSearch,
  triedForCategorySearch,
  triedForLocationSearch,
  callFromEventNavTrial,
  clearCallFromEventNav,
  resetEventReduxOnMount,
  validateCouponCodeStart,
  validateCouponCodeSuccess,
  validateCouponCodeFailure,
  bookingDetailsForGuestLoadingStart,
  bookingDetailsForGuestSuccess,
  bookingDetailsForGuestFailure,
  clearCouponResult,
  alreadyBookedDatesLoadingStart,
  alreadyBookedDatesSuccess,
  alreadyBookedDatesFailure,
} = eventSlice.actions;
