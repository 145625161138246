import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { clearBackendError, clearCouponResult } from "@store/eventredux";
import { resetBookingVilla } from "@store/eventredux";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import MainFooter from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import Navbar from "@components/navbar/Navbar";
import BookingForm from "@components/booking-form/BookingForm";
import BookingSuccessPopup from "@components/booking-success-popup/BookingSuccessPopup";
import { ReactComponent as NoData } from "@images/nodata.svg";
import { ReactComponent as LocationIcon } from "@images/location.svg";
import banner from "@images/banner.png";
import facility from "@images/facility.svg";
import { ReactComponent as Dropdown } from "@images/dropdown-icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import "@features/luxuryCars-booking/LuxuryCarsBookingStyle.scss";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import BigNumber from "bignumber.js";
import { isNumber } from "lodash";
import * as _ from "lodash";
import { isSameDay } from "date-fns";

const BN = require("bn.js");

function LuxuryCarsBooking({
  GetEventDetail,
  eventDetails,
  eventLoading,
  faqs,
  faqsLoading,
  faqsError,
  GetFaqs,
  userLoggedIn,
  eventError,
  clearBackendErrorAsync,
  clearCouponCodeAsync,
  villaDet,
  villaFaq,
  GetVillaDetails,
  GetVillaFacilities,
  villaBookingLoading,
  villaBookingSucc,
  villaBookError,
  BookVillaAsync,
  clearBackend,
  userDet,
  refreshBookingState,
  ValidateCouponCodeAsync,
  validateCouponCodeLoading,
  validateCouponCodeResult,
  validateCouponCodeError,
  GetBookedDatesAsync,
  bookedDatesDetails,
  bookedDateLoading,
}) {
  let history = useHistory();

  let date = new Date();

  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [arrivalTime, setArrivalTime] = useState(
    setHours(setMinutes(new Date(), 0), 11)
  );
  const [departureTime, setDepartureTime] = useState(
    setHours(setMinutes(new Date(), 0), 11)
  );
  const [checkInDate, setCheckInDate] = useState(
    new Date(date.setDate(date.getDate()))
  );
  const [checkOutDate, setCheckOutDate] = useState(
    new Date(date.setDate(date.getDate() + 1))
  );
  const [finalCheckInPeriod, setFinalCheckInPeriod] = useState("");
  const [finalCheckOutPeriod, setFinalCheckoutPeriod] = useState("");
  let { id } = useParams();

  const [readMore, setReadMore] = useState(false);
  const [Description, setDescription] = useState();
  const [backendError, setBackendError] = useState();
  const [dateExceeded, setDateExceeded] = useState(0);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [ticketSaleStartdate, setTicketSaleStartDate] = useState();
  const [MaxLimitdone, setMaxLimitdone] = useState(false);
  const [maxGuest, setMaxGuest] = useState();
  const [calTotal, setCalTotal] = useState();
  const [checked, setChecked] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [bookingDetails, setBookingDetails] = useState();
  const [totalDays, setTotalDays] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [codeEntered, setCodeEntered] = useState(false);
  const [myRedirect, setMyRedirect] = useState("");
  const [eveId, setEveId] = useState();
  const [disableButton, setDisableButton] = useState(true);
  // const params = new URLSearchParams(window.location.search);
  const [popupForGuest, setPopupForGuest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [addFacilityPrice, setAddFacilityPrice] = useState(0);
  var para;
  var click = 0;
  const location2 = useLocation();
  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };
  const [person, setPerson] = useState({
    userName: "",
    email: "",
    mobile: "",
  });
  let emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  useEffect(() => {
    refreshBookingState();
    return () => {
      refreshBookingState();
    };
  }, []);
  // useEffect(() => {
  //   if (userLoggedIn === undefined && id) {
  //     history.push(`/properties/${id}`);
  //   }
  // }, []);
  useEffect(() => {
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);
  useEffect(() => {
    if (para !== undefined) {
      setMyRedirect(para);
    }
  }, [para]);
  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({
        urlSlug: true,
        slugVal: myRedirect,
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetEventDetail({ urlSlug: false, eventId: id });
        GetFaqs({ eventId: id });
        GetVillaDetails({ id, type: "luxuryCar" });
        GetVillaFacilities({ id, type: "luxuryCar" });
      }
    }
    // console.log("meeeeeeee");
  }, [myRedirect, id]);
  const callBookedDatesApi = (
    startmonth = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    ).toISOString(),
    endOfMonth = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    ).toISOString()
  ) => {
    if (myRedirect !== "" && eveId !== undefined) {
      GetBookedDatesAsync({
        id: eveId,
        startDate: startmonth,
        endDate: endOfMonth,
        type: "luxuryCar",
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetBookedDatesAsync({
          id,
          startDate: startmonth,
          endDate: endOfMonth,
          type: "luxuryCar",
        });
      }
    }
  };
  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      GetVillaDetails({ id: eveId, type: "luxuryCar" });
      GetVillaFacilities({ id: eveId, type: "luxuryCar" });
      callBookedDatesApi();
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetVillaDetails({ id });
        GetVillaFacilities({ id });
        callBookedDatesApi();
      }
    }
  };

  const makeAbsolute = (num) => {
    let number = Number(num);
    // let amount = new BN(number);
    let fn = new BigNumber(number);
    let rounded = fn.dp(0, BigNumber.ROUND_HALF_UP);

    return rounded.toNumber();
  };
  // useEffect(() => {
  //   // clearBackendErrorAsync();
  //   GetEventDetail({ eventId: id });
  //   GetFaqs({ eventId: id });
  //   GetVillaDetails({ id });
  //   GetVillaFacilities({ id });
  // }, [id]);

  useEffect(() => {
    if (eventDetails !== undefined) {
      //console.log("ev", eventDetails);
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      // setStartDate(eventDetails.startDate);
      // setEndDate(eventDetails.endDate);
      setEveId(eventDetails.id);
      setdescription(eventDetails.description);
      // checkExceedDataTime(eventDetails.endDate);
      // setTicketSaleStartDate(eventDetails.ticketSaleStartDate);
      // setTicketSaleEndDate(eventDetails.ticketSaleEndDate);
    }
  }, [eventDetails, id, eveId]);
  useEffect(() => {
    if (id !== undefined || eveId !== undefined) {
      handleBackendCallsBasedOnIdChange();
    }
  }, [id, eveId]);
  useEffect(() => {
    if (eventError !== undefined) {
      setBackendError(eventError);
    }
  }, [eventError]);

  useEffect(() => {
    if (counter2 !== 0) {
      let total = counter;
      let totalCounted = maxGuest - total;
      setCalTotal(totalCounted);
    } else {
      setCalTotal(maxGuest);
    }
  }, [counter2]);

  const highlightDates = () => {
    let data = bookedDatesDetails?.map((date) => new Date(date.dateTime));

    return data;
  };

  const renderDayContents = (day, date) => {
    const availableDate = bookedDatesDetails?.find((dateObj) =>
      isSameDay(new Date(dateObj.dateTime), date)
    );

    if (availableDate !== undefined) {
      const tooltipText = `No tickets available on this date.`;
      return (
        <div title={tooltipText} className="booked-date">
          {day}
        </div>
      );
    }

    return day;
  };

  const totalVillaBookingAmount = () => {
    if (
      villaDet !== undefined &&
      villaDet.price !== null &&
      eventDetails !== undefined &&
      eventDetails.gstPercentage !== null
    ) {
      let totalAmount = villaDet.price * totalDays + addFacilityPrice;

      return (
        (totalAmount * Number(eventDetails.gstPercentage)) / 100 +
        totalAmount
      ).toFixed(2);
    } else {
      if (
        villaDet !== undefined &&
        villaDet.price !== null &&
        eventDetails !== undefined
      ) {
        return (villaDet.price * totalDays + addFacilityPrice).toFixed(2);
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (counter + counter2 == maxGuest) {
      setMaxLimitdone(true);
    } else {
      setMaxLimitdone(false);
    }
    if (counter === maxGuest) {
      setCounter2(0);
    } else if (counter2 === maxGuest) {
      setCounter(0);
    } else if (counter + counter2 > maxGuest) {
      alert(
        "You are not allowed to select more number of people than maximum number of guests"
      );
    }
  }, [counter, counter2]);

  useEffect(() => {
    //console.log("check in", checkInDate);
    if (checkInDate !== "") {
      let dateToBeCompared = moment(checkInDate).format("DD/MM/YYYY");

      let decidingParam = moment(dateToBeCompared, "DD/MM/YYYY").isBefore(
        moment(new Date(), "DD/MM/YYYY"),
        "day"
      );

      if (decidingParam === true) {
        alert("Past dates should not be selected");
        setCheckInDate("");
      }
      // moment(dateToBeCompared, "DD/MM/YYYY").isSame(
      //   moment(new Date(), "DD/MM/YYYY"),
      //   "day"
      // );
      // For future dates
    }
  }, [checkInDate]);
  useEffect(() => {
    //console.log("check in", checkOutDate);
    if (checkOutDate !== "") {
      let dateToBeCompared = moment(checkOutDate).format("DD/MM/YYYY");

      let decidingParam = moment(dateToBeCompared, "DD/MM/YYYY").isBefore(
        moment(new Date(), "DD/MM/YYYY"),
        "day"
      );
      let decidingParam2 = moment(dateToBeCompared, "DD/MM/YYYY").isSame(
        moment(checkInDate, "DD/MM/YYYY"),
        "day"
      );
      let decidingParam3 = moment(dateToBeCompared, "DD/MM/YYYY").isBefore(
        moment(checkInDate, "DD/MM/YYYY"),
        "day"
      );

      if (decidingParam === true) {
        alert("Past dates should not be selected");
        setCheckOutDate("");
      } else if (decidingParam2 === true) {
        alert("Pick-Up date and Return date should not be same");
        setCheckOutDate("");
      } else if (decidingParam3) {
        alert("Return date should not be before Pick-Up date");
        setCheckOutDate("");
      }
    }
  }, [checkOutDate, checkInDate]);
  useEffect(() => {
    if (arrivalTime !== "" && checkInDate !== "") {
      //console.log("set arrivale", arrivalTime);

      let checkInTime = `${new Date(arrivalTime).getHours()}:${
        (new Date(arrivalTime).getMinutes() < 10 ? "0" : "") +
        new Date(arrivalTime).getMinutes()
      }`;
      //console.log("check in time", checkInTime);

      let finalCheckInDate = moment(checkInDate).format("MM/DD/YYYY");
      //console.log("final from date", finalCheckInDate);
      // var dateString = '2019-12-01T10:10:10'.replace(/-/g, '/').replace('T', ' ');
      console.log("hello", `${finalCheckInDate} ${checkInTime}`);
      let checkInIsoString = new Date(
        `${finalCheckInDate} ${checkInTime}`
      ).toISOString();
      //console.log("check in", checkInIsoString);
      setFinalCheckInPeriod(checkInIsoString);
    }
  }, [arrivalTime, checkInDate]);
  useEffect(() => {
    if (departureTime !== "" && checkOutDate !== "") {
      //console.log("set arrivale", departureTime);

      let checkInTime = `${new Date(departureTime).getHours()}:${
        (new Date(departureTime).getMinutes() < 10 ? "0" : "") +
        new Date(departureTime).getMinutes()
      }`;
      //console.log("check in time", checkInTime);

      let finalCheckInDate = moment(checkOutDate).format("MM/DD/YYYY");
      //console.log("final from date", finalCheckInDate);
      let checkInIsoString = new Date(
        `${finalCheckInDate} ${checkInTime}`
      ).toISOString();

      setFinalCheckoutPeriod(checkInIsoString);
    }
  }, [departureTime, checkOutDate]);

  // useEffect(() => {
  //   //console.log("counter", counter);
  //   //console.log(maxGuest);
  //   setMaxGuest(maxGuest - (counter + counter2));
  // }, [counter, counter2]);

  const modifyDate = (date1, date2) => {
    let d1 = moment(date1).format("YYYY/MM/DD");
    let d2 = moment(date2).format("YYYY/MM/DD");
    let newD1 = new Date(d1);
    let newD2 = new Date(d2);
    let difference = newD2.getTime() - newD1.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays > 0) {
      setTotalDays(TotalDays);
    }
  };

  useEffect(() => {
    if (checkInDate !== "" && checkOutDate !== "") {
      modifyDate(checkInDate, checkOutDate);
    }
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    if (userLoggedIn !== undefined) {
      //console.log("user", userLoggedIn);
      setAccessToken(userLoggedIn.accessToken);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    //console.log("villa det", villaDet);
    if (villaDet !== undefined && villaDet.no_of_seaters !== null) {
      setMaxGuest(villaDet.no_of_seaters);
    }
  }, [villaDet]);

  const handlePaymentButtonCall = (guestCalling) => {
    //console.log("called");
    if (
      checkInDate === "" ||
      checkOutDate === "" ||
      arrivalTime === "" ||
      departureTime === "" ||
      checked === false
    ) {
      if (checked === false) {
        //console.log(" inside");
        alert("Please agree to our terms and conditions to continue booking!");
      } else if (checkInDate === "") {
        alert("Please provide Pick-Up date to continue booking!");
      } else if (checkOutDate === "") {
        alert("Please provide Return date to continue booking!");
      } else if (arrivalTime === "") {
        alert("Please provide arrival time to continue booking! ");
      } else if (departureTime === "") {
        alert("Please provide checkout time to continue booking!");
      }
    } else {
      let data;
      if (
        (villaDet !== undefined && Number(villaDet.price) !== 0) ||
        Number(
          validateCouponCodeResult !== undefined
            ? validateCouponCodeResult.type === "percent"
              ? validateCouponCodeResult.maximumDiscount !== undefined &&
                validateCouponCodeResult.maximumDiscount !== null &&
                validateCouponCodeResult.maximumDiscount !== 0
                ? (
                    (totalVillaBookingAmount() *
                      validateCouponCodeResult.value) /
                    100
                  ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                  ? (
                      totalVillaBookingAmount() -
                      (
                        (totalVillaBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  : (
                      totalVillaBookingAmount() -
                      validateCouponCodeResult.maximumDiscount
                    ).toFixed(2)
                : (
                    totalVillaBookingAmount() -
                    (
                      (totalVillaBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2)
                  ).toFixed(2)
              : Number(validateCouponCodeResult.value) >
                Number(totalVillaBookingAmount())
              ? villaDet.price !== null
                ? (
                    (villaDet.price *
                      totalDays *
                      Number(eventDetails.gstPercentage)) /
                      100 +
                    villaDet.price * totalDays -
                    villaDet.price * totalDays
                  ).toFixed(2)
                : 0
              : (
                  totalVillaBookingAmount() - validateCouponCodeResult.value
                ).toFixed(2)
            : totalVillaBookingAmount()
        ) !== 0
      ) {
        if (
          validateCouponCodeResult !== undefined &&
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalVillaBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalVillaBookingAmount() -
                        (
                          (totalVillaBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalVillaBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalVillaBookingAmount() -
                      (
                        (totalVillaBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalVillaBookingAmount())
                ? villaDet.price !== null
                  ? (
                      (villaDet.price *
                        totalDays *
                        Number(eventDetails.gstPercentage)) /
                        100 +
                      villaDet.price * totalDays -
                      villaDet.price * totalDays
                    ).toFixed(2)
                  : 0
                : (
                    totalVillaBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalVillaBookingAmount()
          ) !== 0
        ) {
          data = {
            checkInDateTime: finalCheckInPeriod,
            checkOutDateTime: finalCheckOutPeriod,
            // noOfChildren: counter2,
            // noOfAdults: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            couponId: validateCouponCodeResult.id,
            guest: false,
            type: "luxuryCar",
            facilityIds: selectedOptions,
          };
        } else if (
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (totalVillaBookingAmount() *
                        validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        totalVillaBookingAmount() -
                        (
                          (totalVillaBookingAmount() *
                            validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        totalVillaBookingAmount() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      totalVillaBookingAmount() -
                      (
                        (totalVillaBookingAmount() *
                          validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(totalVillaBookingAmount())
                ? villaDet.price !== null
                  ? (
                      (villaDet.price *
                        totalDays *
                        Number(eventDetails.gstPercentage)) /
                        100 +
                      villaDet.price * totalDays -
                      villaDet.price * totalDays
                    ).toFixed(2)
                  : 0
                : (
                    totalVillaBookingAmount() - validateCouponCodeResult.value
                  ).toFixed(2)
              : totalVillaBookingAmount()
          ) === 0
        ) {
          data = {
            checkInDateTime: finalCheckInPeriod,
            checkOutDateTime: finalCheckOutPeriod,
            // noOfChildren: counter2,
            // noOfAdults: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            couponId: validateCouponCodeResult.id,
            freeEvent: true,
            guest: false,
            type: "luxuryCar",
            facilityIds: selectedOptions,
          };
        } else {
          data = {
            checkInDateTime: finalCheckInPeriod,
            checkOutDateTime: finalCheckOutPeriod,
            // noOfChildren: counter2,
            // noOfAdults: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            guest: false,
            type: "luxuryCar",
            facilityIds: selectedOptions,
          };
        }
      } else {
        data = {
          checkInDateTime: finalCheckInPeriod,
          checkOutDateTime: finalCheckOutPeriod,
          // noOfChildren: counter2,
          // noOfAdults: counter,
          token: accessToken,
          id: id !== undefined && isNumber(id) ? Number(id) : eveId,
          freeEvent: true,
          guest: false,
          type: "luxuryCar",
          facilityIds: selectedOptions,
        };
      }
      if (guestCalling === true) {
        let withGuestData = {
          bookingDetails: data,
          userName: person.userName,
          email: person.email,
          mobile: person.mobile,
          id: id !== undefined && isNumber(id) ? Number(id) : eveId,
          guest: true,
          type: "luxuryCar",
          facilityIds: selectedOptions,
        };
        BookVillaAsync(withGuestData);
      } else {
        console.log("datafor backend", data);
        BookVillaAsync(data);
      }
    }

    // bookingAsyncCalled(finalDataForBackend);
  };

  const handleCheckButton = (e) => {
    setChecked(!checked);
  };

  const handleCouponCodeClear = () => {
    clearCouponCodeAsync();
    setCoupon("");
  };

  const disableDays = (date) => {
    const availableDate = bookedDatesDetails?.find((dateObj) =>
      isSameDay(new Date(dateObj.dateTime), date)
    );

    if (availableDate !== undefined && availableDate == true) date;
  };
  useEffect(() => {
    if (
      villaBookingSucc !== undefined &&
      villaBookingSucc.booking !== undefined
    ) {
      if (Number(villaBookingSucc.booking.totalAmount) > 0) {
        setBookingDetails(villaBookingSucc);
        razorPayCall(villaBookingSucc);
      } else if (Number(villaBookingSucc.booking.totalAmount) == 0) {
        if (villaBookingSucc.guest === null) {
          setBookingDetails(villaBookingSucc);
          setShowSuccessPopup(true);
        } else {
          setBookingDetails(villaBookingSucc);

          setPopupForGuest(true);
          setShowSuccessPopup(true);
        }
      }
      // else {

      // }
    }
    //console.log("villa book succ", villaBookingSucc);
  }, [villaBookingSucc]);

  useEffect(() => {
    if (villaBookError !== undefined) {
      if (alert(`${villaBookError}`)) {
        clearBackendErrorAsync();
      } else {
        clearBackendErrorAsync();
      }
    }
  }, [villaBookError]);

  const razorPayCall = (res) => {
    let options;
    try {
      if (userDet !== undefined && res.guest === null) {
        options = {
          // key: "rzp_live_6i9IJT8pW0sluE",
          key: process.env.RAZZLE_APP_RAZORPAYKEY,
          name: "Followgoa",
          description: "Unique journey",
          order_id: res.booking.razorPayOrderId,
          amount: res.booking.totalAmount * 100,
          handler: async (response) => {
            try {
              //alert("Payment succesfull!");
              if (
                response &&
                response.razorpay_payment_id !== undefined &&
                response.razorpay_payment_id !== null
              ) {
                setSuccess(true);
              }
              setShowSuccessPopup(true);
              setChecked(false);
              refreshBookingState();
              // this.props.history.push(`/redirect/${res.data.id}`, {
              //   status: "Success",
              // });
              //alert(`Payment Success. Payment Id ${paymentId}`);
            } catch (err) {
              alert("Error --->", err);
              setChecked(false);
              refreshBookingState();
              // this.props.history.push(`/redirect/${res.data.id}`, {
              //   status: "Failure",
              // });
              ////console.log("err-res", err);
              // alert(err);
            }
          },

          prefill: {
            //   name: user.displayName,
            email: userDet.email,
            contact: userDet.mobile,
          },
          theme: {
            color: "#FDD72A",
          },
          modal: {
            ondismiss: function () {
              // console.log("Checkout form closed");
              setChecked(false);
              refreshBookingState();
            },
          },
        };
      } else {
        if (userDet === undefined && res.guest !== null) {
          options = {
            // key: "rzp_live_6i9IJT8pW0sluE",
            key: process.env.RAZZLE_APP_RAZORPAYKEY,
            name: "Followgoa",
            description: "Unique journey",
            order_id: res.booking.razorPayOrderId,
            amount: res.booking.totalAmount * 100,
            handler: async (response) => {
              try {
                console.log("res -> ", response);
                //alert("Payment succesfull!");
                setPopupForGuest(true);
                setShowSuccessPopup(true);

                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Success",
                // });
                //alert(`Payment Success. Payment Id ${paymentId}`);
              } catch (err) {
                alert("Error --->", err);
                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Failure",
                // });
                ////console.log("err-res", err);
                // alert(err);
              }
            },

            prefill: {
              //   name: user.displayName,
              email: res.guest.email,
              contact: res.guest.mobile,
            },
            theme: {
              color: "#FDD72A",
            },
            modal: {
              ondismiss: function () {
                // console.log("Checkout form closed");
                setChecked(false);
                refreshBookingState();
              },
            },
          };
        }
      }

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (e) {
      console.log("err for razor", e);
    }
  };

  useEffect(() => {
    setDisableButton(coupon !== "");
  }, [coupon]);

  useEffect(() => {
    if (validateCouponCodeError) setCodeEntered(true);
  }, [validateCouponCodeError]);

  const handleGuest = (e) => {
    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });
  };
  function checkProperties(o) {
    const allTrue = Object.values(o).some((value) => value === "");
    return allTrue;
  }
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option, price) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
      setAddFacilityPrice(addFacilityPrice - price);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      setAddFacilityPrice(addFacilityPrice + price);
    }
  };

  useEffect(() => {
    if (villaFaq !== undefined && villaFaq.length > 0) {
      if (villaFaq.filter((item) => item.price === 0).length > 0) {
        const filteredItems = villaFaq.filter((item) => item.price === 0);

        let idArray = filteredItems.map((item) => item.id);
        const uniqueSelectedOptions = new Set([...selectedOptions, ...idArray]);
        setSelectedOptions([...uniqueSelectedOptions]);
      }
    }
  }, [villaFaq]);

  return (
    <div id="LuxuryCarsBooking">
      <Navbar />
      {(eventLoading || villaBookingLoading || validateCouponCodeLoading) && (
        <Loader />
      )}
      {banner !== "" && (
        <div className="banner-section">
          <img src={banner} className="bannerbgimg" loading="lazy" />
        </div>
      )}
      <Container>
        <Row>
          <Col lg={8} className="details-div">
            <div className="booking-details">
              <div className="titleblock">
                {eventDetails !== undefined &&
                  eventDetails.title !== undefined && (
                    <div className="title">
                      <span> {title}</span>
                    </div>
                  )}
                {eventDetails !== undefined &&
                  eventDetails.tourRef !== null && (
                    <div className="referencetext">
                      <span>Reference ID: {tourRef}</span>
                    </div>
                  )}
                {villaDet !== undefined && villaDet.no_of_seaters !== null && (
                  <div className="referencetext">
                    <span>
                      Maximum number of travellers: {villaDet.no_of_seaters}
                    </span>
                  </div>
                )}
              </div>
              <div className="property-block">
                <div className="leftalign">
                  {eventDetails !== undefined &&
                    eventDetails.location !== null && (
                      <span className="location">
                        <LocationIcon className="leftsideimg" /> {location}
                      </span>
                    )}
                </div>
              </div>
              {/* <div className="leftalign mb-2">
                <span className="viewlocation">View Location</span>
              </div> */}
              {eventDetails !== undefined &&
                eventDetails.googleMapLink !== null && (
                  <div className="leftalign mb-2">
                    <a
                      className="viewlocation"
                      href={`${eventDetails.googleMapLink}`}
                      target="_blank"
                    >
                      View Location
                    </a>
                  </div>
                )}
              <div className="input-block">
                <Form.Group className="input-div date-picker">
                  <label>Pick-Up date</label>
                  {
                    <DatePicker
                      placeholderText="DD-MM-YYYY"
                      dateFormat="dd/MM/yyyy"
                      // selected={new Date()}
                      // onChange={date => dateOnChange(date, field)}
                      highlightDates={highlightDates()}
                      excludeDates={highlightDates()}
                      renderDayContents={renderDayContents}
                      isDayDisabled={disableDays}
                      onMonthChange={(date) => {
                        const startOfMonth = new Date(
                          moment(date).startOf("month").format("YYYY-MM-DD")
                        ).toISOString();
                        const endOfMonth = new Date(
                          moment(date).endOf("month").format("YYYY-MM-DD")
                        ).toISOString();
                        callBookedDatesApi(startOfMonth, endOfMonth);
                      }}
                      minDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      name="InDate"
                      type="date"
                      classname="form-field"
                      selected={checkInDate || ""}
                      onChange={(date) => {
                        setCheckInDate(date);
                        // modifyDate(date);
                      }}
                    />
                  }
                </Form.Group>
                <Form.Group className="input-div date-picker">
                  <label>Return date</label>
                  <DatePicker
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="OutDate"
                    type="date"
                    highlightDates={highlightDates()}
                    excludeDates={highlightDates()}
                    renderDayContents={renderDayContents}
                    onMonthChange={(date) => {
                      const startOfMonth = new Date(
                        moment(date).startOf("month").format("YYYY-MM-DD")
                      ).toISOString();
                      const endOfMonth = new Date(
                        moment(date).endOf("month").format("YYYY-MM-DD")
                      ).toISOString();
                      callBookedDatesApi(startOfMonth, endOfMonth);
                    }}
                    isDayDisabled={disableDays}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    minDate={new Date()}
                    classname="form-field"
                    selected={checkOutDate}
                    onChange={(date) => {
                      setCheckOutDate(date);
                    }}
                  />
                </Form.Group>
                <Form.Group className="input-div date-picker time">
                  <label>Pick-Up Time</label>
                  <DatePicker
                    placeholderText="HH:MM"
                    selected={arrivalTime}
                    showTimeSelect
                    dateFormat="hh:mm a"
                    showTime={{ use12Hours: true }}
                    minTime={setHours(setMinutes(new Date(), 0), 11)}
                    maxTime={setHours(setMinutes(new Date(), 0), 12)}
                    onChange={(date) => {
                      setArrivalTime(date);
                    }}
                  />
                </Form.Group>
                <Form.Group className="input-div date-picker time">
                  <label>Return Time</label>
                  <DatePicker
                    placeholderText="HH:MM"
                    selected={departureTime}
                    showTimeSelect
                    dateFormat="hh:mm a"
                    minTime={setHours(setMinutes(new Date(), 0), 11)}
                    maxTime={setHours(setMinutes(new Date(), 0), 12)}
                    showTime={{ use12Hours: true }}
                    onChange={(date) => setDepartureTime(date)}
                  />
                </Form.Group>
              </div>

              <div className="input-block">
                {villaFaq !== undefined &&
                  villaFaq.length > 0 &&
                  villaFaq.filter((item) => item.price > 0).length > 0 && (
                    <div className="facility-block">
                      <h2 className="about "> Paid Facilities</h2>
                      <Form.Group>
                        {villaFaq !== undefined &&
                          villaFaq.length > 0 &&
                          villaFaq
                            .filter((item) => item.price > 0)
                            .map((item, index) => (
                              // <div className="d-flex">
                              //   <img src={facility} className="facilities-icon" />
                              //   <p className="facilities-text">{item.facility_name} - ₹{item.price} </p>
                              // </div>

                              <Form.Check
                                key={item.id}
                                type="checkbox"
                                id={`checkbox-${item.id}`}
                                label={`${item.facility_name} - ₹${item.price}`}
                                checked={selectedOptions.includes(item.id)}
                                onChange={() =>
                                  handleCheckboxChange(item.id, item.price)
                                }
                              />
                            ))}
                      </Form.Group>
                    </div>
                  )}
              </div>
              <div className="input-block">
                {villaFaq !== undefined &&
                  villaFaq.length > 0 &&
                  villaFaq.filter((item) => item.price == 0).length > 0 && (
                    <div className="facility-block">
                      <h2 className="about "> Complimentary Facilities</h2>
                      <Form.Group>
                        {villaFaq !== undefined &&
                          villaFaq.length > 0 &&
                          villaFaq
                            .filter((item) => item.price == 0)
                            .map((item, index) => (
                              <div className="d-flex">
                                <img
                                  src={facility}
                                  className="facilities-icon"
                                />
                                <p className="facilities-text">
                                  {item.facility_name}{" "}
                                </p>
                              </div>
                            ))}
                      </Form.Group>
                    </div>
                  )}
              </div>
            </div>
          </Col>
          {/* here aaded */}
          {/* <div className="input-block">
                <p>hello</p>
              </div> */}
          {/* ended here */}
          <Col lg={4} className="d-none d-lg-block">
            <div className="payment-div">
              {/* <div className="detail">
                <span className="noofguest">
                  Number of Person(s) : {counter + counter2}
                </span>
              </div> */}
              {/* <div className="sub-details">
                <span>Adults : {counter}</span>
                <span>Childrens : {counter2}</span>
              </div> */}
              {villaDet !== undefined && villaDet.price !== null && (
                <div className="detail">
                  <span className="noofguest">
                    Price per day : ₹{villaDet.price}
                  </span>
                </div>
              )}
              {eventDetails !== undefined &&
                eventDetails.gstPercentage !== null &&
                eventDetails.gstPercentage !== "0.00" && (
                  <div className="detail">
                    <span className="gst">
                      GST : {eventDetails.gstPercentage}%
                    </span>
                  </div>
                )}
              {villaDet !== undefined && villaDet.price !== null && (
                <div className="detail">
                  <span className="dueamt1">
                    Amount Due : ₹
                    {villaDet.price * totalDays + addFacilityPrice}
                  </span>
                </div>
              )}

              {villaDet !== undefined &&
                villaDet.price !== null &&
                eventDetails !== undefined &&
                eventDetails.gstPercentage !== null &&
                eventDetails.gstPercentage !== "0.00" && (
                  <div className="detail">
                    <span className="dueamt">
                      Total Amount{" "}
                      <span className="addition">(GST + Amount due)</span>: ₹
                      {makeAbsolute(
                        (
                          ((villaDet.price * totalDays + addFacilityPrice) *
                            Number(eventDetails.gstPercentage)) /
                            100 +
                          villaDet.price * totalDays +
                          addFacilityPrice
                        ).toFixed(2)
                      )}
                    </span>
                  </div>
                )}
              <div className="coupon-section">
                <div className="coupon-div">
                  <InputField
                    placeholder={"Enter Coupon code"}
                    type="text"
                    classname="coupon-field"
                    value={coupon}
                    onChange={(event) => {
                      setCoupon(event.target.value);
                      // setCodeEntered(true);
                    }}
                  />
                  <div className="apply-btn">
                    <CustomButton
                      type="button"
                      name={codeEntered ? "Clear" : "Apply"}
                      filled={true}
                      primary={true}
                      classname="pay-btn"
                      onClick={() => {
                        if (userLoggedIn !== undefined) {
                          if (
                            coupon !== undefined &&
                            coupon !== "" &&
                            !codeEntered
                          ) {
                            ValidateCouponCodeAsync({
                              eventId:
                                id !== undefined && isNumber(id)
                                  ? Number(id)
                                  : eveId,
                              couponCode: coupon,
                              guestCalling: false,
                            });
                            setCodeEntered(true);
                          } else {
                            handleCouponCodeClear();
                            setCodeEntered(false);
                          }
                        } else {
                          if (
                            coupon !== undefined &&
                            coupon !== "" &&
                            !codeEntered
                          ) {
                            ValidateCouponCodeAsync({
                              eventId:
                                id !== undefined && isNumber(id)
                                  ? Number(id)
                                  : eveId,
                              couponCode: coupon,
                              guestCalling: true,
                            });
                            setCodeEntered(true);
                          } else {
                            handleCouponCodeClear();
                            setCodeEntered(false);
                          }
                        }
                      }}
                      disable={!disableButton}
                    />
                  </div>
                </div>
                <p className="errorText">
                  {validateCouponCodeError !== undefined
                    ? validateCouponCodeError
                    : ""}
                </p>
                <div className="amount-div">
                  <div className="amount-text">
                    <span className="discount-text">
                      Discount: ₹
                      {validateCouponCodeResult !== undefined
                        ? validateCouponCodeResult.type === "percent"
                          ? validateCouponCodeResult.maximumDiscount !==
                              undefined &&
                            validateCouponCodeResult.maximumDiscount !== null &&
                            validateCouponCodeResult.maximumDiscount !== 0
                            ? (
                                (totalVillaBookingAmount() *
                                  validateCouponCodeResult.value) /
                                100
                              ).toFixed(2) <=
                              validateCouponCodeResult.maximumDiscount
                              ? (
                                  (totalVillaBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                              : validateCouponCodeResult.maximumDiscount
                            : (
                                (totalVillaBookingAmount() *
                                  validateCouponCodeResult.value) /
                                100
                              ).toFixed(2)
                          : Number(validateCouponCodeResult.value) >
                            Number(totalVillaBookingAmount())
                          ? villaDet.price !== null
                            ? villaDet.price * totalDays
                            : 0
                          : validateCouponCodeResult.value
                        : 0}
                    </span>
                  </div>
                  <div className="amount-text">
                    <span>
                      Total Payable: ₹
                      {validateCouponCodeResult !== undefined
                        ? validateCouponCodeResult.type === "percent"
                          ? validateCouponCodeResult.maximumDiscount !==
                              undefined &&
                            validateCouponCodeResult.maximumDiscount !== null &&
                            validateCouponCodeResult.maximumDiscount !== 0
                            ? makeAbsolute(
                                (
                                  (totalVillaBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                              ) <= validateCouponCodeResult.maximumDiscount
                              ? makeAbsolute(
                                  (
                                    totalVillaBookingAmount() -
                                    (
                                      (totalVillaBookingAmount() *
                                        validateCouponCodeResult.value) /
                                      100
                                    ).toFixed(2)
                                  ).toFixed(2)
                                )
                              : makeAbsolute(
                                  (
                                    totalVillaBookingAmount() -
                                    validateCouponCodeResult.maximumDiscount
                                  ).toFixed(2)
                                )
                            : makeAbsolute(
                                (
                                  totalVillaBookingAmount() -
                                  (
                                    (totalVillaBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ).toFixed(2)
                              )
                          : Number(validateCouponCodeResult.value) >
                            Number(totalVillaBookingAmount())
                          ? villaDet.price !== null
                            ? makeAbsolute(
                                (
                                  (villaDet.price *
                                    totalDays *
                                    Number(eventDetails.gstPercentage)) /
                                    100 +
                                  villaDet.price * totalDays -
                                  villaDet.price * totalDays
                                ).toFixed(2)
                              )
                            : 0
                          : makeAbsolute(
                              (
                                totalVillaBookingAmount() -
                                validateCouponCodeResult.value
                              ).toFixed(2)
                            )
                        : makeAbsolute(totalVillaBookingAmount())}
                    </span>
                  </div>
                </div>
              </div>

              <div className="check-div">
                <Form.Check
                  // label={"Agree to terms and conditions"}
                  label={
                    <Link
                      to="/terms-conditions"
                      onClick={() => history.push("/terms-conditions")}
                    >
                      Agree to terms and conditions
                    </Link>
                  }
                  name="category"
                  // checked={checked}
                  checked={checked}
                  onChange={handleCheckButton}
                />
              </div>
              <div className="btn-div">
                <CustomButton
                  type="button"
                  name={
                    villaDet?.price * totalDays + addFacilityPrice === 0
                      ? "Register"
                      : "Continue to Payment"
                  }
                  filled={true}
                  primary={true}
                  classname="pay-btn"
                  onClick={() => {
                    console.log("checkk", checkProperties(person));

                    if (userLoggedIn !== undefined) {
                      // console.log("clicked1");
                      handlePaymentButtonCall(false);
                    } else if (
                      userLoggedIn === undefined &&
                      _.isEmpty(person) === false &&
                      checkProperties(person) === false &&
                      person.email !== "" &&
                      emailPattern.test(person.email) === true &&
                      person.mobile !== "" &&
                      re.test(person.mobile) === true
                    ) {
                      // console.log("clicked2");
                      // console.log("inside else if 1");
                      handlePaymentButtonCall(true);
                    } else {
                      if (
                        person.email === "" ||
                        emailPattern.test(person.email) === false
                      ) {
                        //console.log("clicked3");
                        alert("Please enter valid email");
                      } else if (
                        person.mobile === "" ||
                        re.test(person.mobile) === false
                      ) {
                        //console.log("clicked4");
                        alert("Please enter valid mobile number !");
                      } else if (person.userName === "") {
                        alert("Please enter you name !");
                      }
                      // else {

                      //   alert("Please fill all the fields to continue !");
                      // }
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {userLoggedIn === undefined && (
          <Row>
            <Col lg={8}>
              <BookingForm guestDet={person} setGuestDet={handleGuest} />
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={4} className="d-lg-none">
            <div className="payment-div">
              {/* <div className="detail">
                <span className="noofguest">
                  Number of Person(s) : {counter + counter2}
                </span>
              </div>
              <div className="sub-details">
                <span>Adults : {counter}</span>
                <span>Childrens : {counter2}</span>
              </div> */}
              {villaDet !== undefined && villaDet.price !== null && (
                <div className="detail">
                  <span className="noofguest">
                    Price per day : ₹{villaDet.price}
                  </span>
                </div>
              )}
              {eventDetails !== undefined &&
                eventDetails.gstPercentage !== null &&
                eventDetails.gstPercentage !== "0.00" && (
                  <div className="detail">
                    <span className="gst">
                      GST : {eventDetails.gstPercentage}%
                    </span>
                  </div>
                )}
              {villaDet !== undefined && villaDet.price !== null && (
                <div className="detail">
                  <span className="dueamt1">
                    Amount Due : ₹
                    {villaDet.price * totalDays + addFacilityPrice}
                  </span>
                </div>
              )}

              {villaDet !== undefined &&
                villaDet.price !== null &&
                eventDetails !== undefined &&
                eventDetails.gstPercentage !== null &&
                eventDetails.gstPercentage !== "0.00" && (
                  <div className="detail">
                    <span className="dueamt">
                      Total Amount{" "}
                      <span className="addition">(GST + Amount due)</span>: ₹
                      {makeAbsolute(
                        (
                          ((villaDet.price * totalDays + addFacilityPrice) *
                            Number(eventDetails.gstPercentage)) /
                            100 +
                          villaDet.price * totalDays +
                          addFacilityPrice
                        ).toFixed(2)
                      )}
                    </span>
                  </div>
                )}
              <div className="coupon-section">
                <div className="coupon-div">
                  <InputField
                    placeholder={"Enter Coupon code"}
                    type="text"
                    classname="coupon-field"
                    value={coupon}
                    onChange={(event) => setCoupon(event.target.value)}
                  />
                  <div className="apply-btn">
                    <CustomButton
                      type="button"
                      name={codeEntered ? "Clear" : "Apply"}
                      filled={true}
                      primary={true}
                      classname="pay-btn"
                      onClick={() => {
                        if (userLoggedIn !== undefined) {
                          if (
                            coupon !== undefined &&
                            coupon !== "" &&
                            !codeEntered
                          ) {
                            ValidateCouponCodeAsync({
                              eventId:
                                id !== undefined && isNumber(id)
                                  ? Number(id)
                                  : eveId,
                              couponCode: coupon,
                              guestCalling: false,
                            });
                            setCodeEntered(true);
                          } else {
                            handleCouponCodeClear();
                            setCodeEntered(false);
                          }
                        } else {
                          if (
                            coupon !== undefined &&
                            coupon !== "" &&
                            !codeEntered
                          ) {
                            ValidateCouponCodeAsync({
                              eventId:
                                id !== undefined && isNumber(id)
                                  ? Number(id)
                                  : eveId,
                              couponCode: coupon,
                              guestCalling: true,
                            });
                            setCodeEntered(true);
                          } else {
                            handleCouponCodeClear();
                            setCodeEntered(false);
                          }
                        }
                      }}
                      disable={!disableButton}
                    />
                  </div>
                </div>
                <p className="errorText">
                  {validateCouponCodeError !== undefined
                    ? validateCouponCodeError
                    : ""}
                </p>
                <div className="amount-div">
                  <div className="amount-text">
                    <span className="discount-text">
                      Discount: ₹
                      {validateCouponCodeResult !== undefined
                        ? validateCouponCodeResult.type === "percent"
                          ? validateCouponCodeResult.maximumDiscount !==
                              undefined &&
                            validateCouponCodeResult.maximumDiscount !== null &&
                            validateCouponCodeResult.maximumDiscount !== 0
                            ? (
                                (totalVillaBookingAmount() *
                                  validateCouponCodeResult.value) /
                                100
                              ).toFixed(2) <=
                              validateCouponCodeResult.maximumDiscount
                              ? (
                                  (totalVillaBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                              : validateCouponCodeResult.maximumDiscount
                            : (
                                (totalVillaBookingAmount() *
                                  validateCouponCodeResult.value) /
                                100
                              ).toFixed(2)
                          : Number(validateCouponCodeResult.value) >
                            Number(totalVillaBookingAmount())
                          ? villaDet.price !== null
                            ? villaDet.price * totalDays
                            : 0
                          : validateCouponCodeResult.value
                        : 0}
                    </span>
                  </div>
                  <div className="amount-text">
                    <span>
                      Total Payable: ₹
                      {validateCouponCodeResult !== undefined
                        ? validateCouponCodeResult.type === "percent"
                          ? validateCouponCodeResult.maximumDiscount !==
                              undefined &&
                            validateCouponCodeResult.maximumDiscount !== null &&
                            validateCouponCodeResult.maximumDiscount !== 0
                            ? (
                                (totalVillaBookingAmount() *
                                  validateCouponCodeResult.value) /
                                100
                              ).toFixed(2) <=
                              validateCouponCodeResult.maximumDiscount
                              ? (
                                  totalVillaBookingAmount() -
                                  (
                                    (totalVillaBookingAmount() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ).toFixed(2)
                              : (
                                  totalVillaBookingAmount() -
                                  validateCouponCodeResult.maximumDiscount
                                ).toFixed(2)
                            : (
                                totalVillaBookingAmount() -
                                (
                                  (totalVillaBookingAmount() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                              ).toFixed(2)
                          : Number(validateCouponCodeResult.value) >
                            Number(totalVillaBookingAmount())
                          ? villaDet.price !== null
                            ? (
                                (villaDet.price *
                                  totalDays *
                                  Number(eventDetails.gstPercentage)) /
                                  100 +
                                villaDet.price * totalDays -
                                villaDet.price * totalDays
                              ).toFixed(2)
                            : 0
                          : (
                              totalVillaBookingAmount() -
                              validateCouponCodeResult.value
                            ).toFixed(2)
                        : totalVillaBookingAmount()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="check-div">
                <Form.Check
                  // label={"Agree to terms and conditions"}
                  label={
                    <Link
                      to="/terms-conditions"
                      onClick={() => history.push("/terms-conditions")}
                    >
                      Agree to terms and conditions
                    </Link>
                  }
                  name="category"
                  // checked={checked}
                  checked={checked}
                  onChange={handleCheckButton}
                />
              </div>
              <div className="btn-div">
                <CustomButton
                  type="button"
                  name={
                    villaDet?.price * totalDays + addFacilityPrice === 0
                      ? "Register"
                      : "Continue to Payment"
                  }
                  filled={true}
                  primary={true}
                  classname="pay-btn"
                  onClick={() => {
                    console.log("checkk", checkProperties(person));

                    if (userLoggedIn !== undefined) {
                      //                      console.log("clicked1");
                      handlePaymentButtonCall(false);
                    } else if (
                      userLoggedIn === undefined &&
                      _.isEmpty(person) === false &&
                      checkProperties(person) === false &&
                      (person.email !== "" ||
                        emailPattern.test(person.email) === true) &&
                      (person.mobile !== "" || re.test(person.mobile) === true)
                    ) {
                      // console.log("clicked2");
                      // console.log("inside else if 1");
                      handlePaymentButtonCall(true);
                    } else {
                      if (
                        person.email === "" ||
                        emailPattern.test(person.email) === false
                      ) {
                        // console.log("clicked3");
                        alert("Please enter valid email");
                      } else if (
                        person.mobile === "" ||
                        re.test(person.mobile) === false
                      ) {
                        // console.log("clicked4");
                        alert("Please enter valid mobile number !");
                      } else if (person.userName === "") {
                        alert("Please enter you name !");
                      }
                      // else {

                      //   alert("Please fill all the fields to continue !");
                      // }
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="terms">
              <div className="terms-title">
                <span>Terms and Conditions</span>
              </div>
              <p className="desc">
                Followgoa is an authorised Agent of Travel Talk. Please
                familiarise yourself with the Travel Talk{" "}
                <a>payment, cancellation and refund policies</a> and Followgoa{" "}
                <a>Terms & Conditions</a>. Followgoa will charge you in the
                stated currency and we do not charge any booking fees.
              </p>
            </div>
          </Col>
        </Row> */}
      </Container>
      <BookingSuccessPopup
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        show={showSuccessPopup}
        setShowModal={setShowSuccessPopup}
        forGuest={popupForGuest}
        successOrFail={success}
        setSucc={setSuccess}
        closeForGuest={(e) => setPopupForGuest(e)}
      />
      <div className="footer-div">
        <MainFooter />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user = {}, event = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", []);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const faqsLoading = _.get(event, "faqsLoading", false);
  const faqs = _.get(event, "faqs", []);
  const faqsError = _.get(event, "faqsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const eventItinerariesLoading = _.get(
    event,
    "eventItinerariesLoading",
    false
  );
  const eventItineraries = _.get(event, "eventItineraries", undefined);
  const eventItinerariesError = _.get(
    event,
    "eventItinerariesError",
    undefined
  );
  const villaDetLoading = _.get(event, "villaDetailLoading", false);
  const villaDet = _.get(event, "villaDetails", undefined);
  const villaError = _.get(event, "villaDetailsError", undefined);
  const villaFaqLoading = _.get(event, "villaFacilityLoading", false);
  const villaFaq = _.get(event, "villaFacility", undefined);
  const villaFaqError = _.get(event, "villaFacilityError", undefined);
  const villaBookingLoading = _.get(event, "villaBookLoading", false);
  const villaBookingSucc = _.get(event, "villaBooking", undefined);
  const villaBookError = _.get(event, "villaBookingError", undefined);
  const userDet = _.get(user, "userDetails", undefined);
  const validateCouponCodeLoading = _.get(
    event,
    "validateCouponCodeLoading",
    false
  );
  const validateCouponCodeResult = _.get(
    event,
    "validateCouponCode",
    undefined
  );
  const validateCouponCodeError = _.get(
    event,
    "validateCouponCodeError",
    undefined
  );
  const bookedDateLoading = _.get(event, "alreadyBookedDatesLoading", false);
  const bookedDatesDetails = _.get(event, "alreadyBookedDates", undefined);

  return {
    eventLoading,
    eventDetails,
    eventError,
    faqsLoading,
    faqs,
    userDet,
    faqsError,
    userLoggedIn,
    eventItinerariesLoading,
    eventItineraries,
    eventItinerariesError,
    villaDetLoading,
    villaDet,
    villaError,
    villaFaqLoading,
    villaFaq,
    villaFaqError,
    villaBookingLoading,
    villaBookingSucc,
    villaBookError,
    validateCouponCodeLoading,
    validateCouponCodeResult,
    validateCouponCodeError,
    bookedDatesDetails,
    bookedDateLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  GetFaqs: (data) => dispatch({ type: "getFaqsCalled", payload: data }),
  clearBackendErrorAsync: () => dispatch(clearBackendError()),
  eventItinerariesCalled: (data) =>
    dispatch({ type: "eventItinerariesCalled", payload: data }),
  GetVillaDetails: (data) =>
    dispatch({ type: "villaDetailsSagaCalled", payload: data }),
  GetVillaFacilities: (data) =>
    dispatch({ type: "villaFacilitySagaCalled", payload: data }),
  BookVillaAsync: (data) =>
    dispatch({ type: "bookVillasagaCalled", payload: data }),
  refreshBookingState: () => dispatch(resetBookingVilla()),

  ValidateCouponCodeAsync: (data) =>
    dispatch({ type: "validateCouponCodeSagaCalled", payload: data }),
  clearCouponCodeAsync: () => dispatch(clearCouponResult()),
  GetBookedDatesAsync: (data) =>
    dispatch({ type: "bookedDatesSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LuxuryCarsBooking);
