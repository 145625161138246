import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import app from "./appredux";
import event from "./eventredux";
import saga from "./sagaindex";
import user from "./userredux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["app", "user", "event"],
};
const reducer = combineReducers({
  app,
  user,
  event,
});
const persistedReducer = persistReducer(persistConfig, reducer);
let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { persistor, store };
