import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { resetForgotPasswordState } from "@store/userredux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/Navbar";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import logo from "@images/logo.svg";
import "@features/forgot-password/ForgotPasswordStyle.scss";
import * as _ from "lodash";

function ForgotPassword({
  forgotPasswordLoading,
  forgotPassword,
  forgotPasswordError,
  resetForgotPassword,
  requestResetPassword,
}) {
  const [email, setEmail] = useState();

  useEffect(() => {
    if (forgotPasswordError !== undefined) {
      if (alert(`${forgotPasswordError}`)) {
        resetForgotPassword();
      } else {
        resetForgotPassword();
      }
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    if (forgotPassword !== undefined) {
      if (alert(`${forgotPassword}`)) {
        setEmail("");
        resetForgotPassword();
      } else {
        setEmail("");
        resetForgotPassword();
      }
    }
  }, [forgotPassword]);

  const onSubmit = () => {
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      let data = {
        email: email.trim(),
      };
      requestResetPassword(data);
    } else {
      alert("Please enter valid email");
    }
  };

  return (
    <>
      <NavBar />
      {forgotPasswordLoading && <Loader />}
      <div id="ForgotPassword">
        <Container>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="login-card">
                  <span className="heading">Forgot Password</span>
                  <img src={logo} className="brandlogo" />
                  <Card.Body>
                    <Form>
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          name="email"
                          placeholder="Enter email"
                          type="text"
                          classname="form-field"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                      <Row>
                        <CustomButton
                          type="button"
                          name="Submit"
                          filled={true}
                          primary={true}
                          classname="submit-btn"
                          onClick={onSubmit}
                        />
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const forgotPasswordLoading = _.get(user, "forgotPasswordLoading", false);
  const forgotPassword = _.get(user, "forgotPassword", undefined);
  const forgotPasswordError = _.get(user, "forgotPasswordError", undefined);
  return {
    forgotPasswordLoading,
    forgotPassword,
    forgotPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetForgotPassword: () => dispatch(resetForgotPasswordState()),
  requestResetPassword: (data) =>
    dispatch({ type: "requestResetPasswordCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
