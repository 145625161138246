import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/success-popup/SuccessPopupStyle.scss";

const SuccessPopup = ({ show, setShowModal, onClick, message, scope }) => {
  let history = useHistory();
  return (
    <Modal
      className="SuccessPopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">{message}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CustomButton
                classname="cancelbtn"
                type="submit"
                name="Close"
                filled={true}
                primary={true}
                onClick={() => {
                  if (scope === "user") {
                    history.push("/login");
                  } else {
                    if (scope === "operator") {
                      location.href = "https://operator.followgoa.com/signin";
                    } else if (scope === "ChangePassword") {
                      history.push("/myprofile");
                    }
                  }
                  setShowModal(false);
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SuccessPopup);
