import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import MainFooter from "@components/footer/MainFooter";
import Navbar from "@components/navbar/Navbar";
import ConfirmationPopup from "@components/confirmation-popup/ConfirmationPopup";
import location from "@images/location.svg";
import mail from "@images/mail.svg";
import phone from "@images/phone.svg";
import "@features/profile/ProfileStyles.scss";
import * as _ from "lodash";
import { useHistory } from "react-router-dom";

function Profile({
  GetProfile,
  ProfileDataLoading,
  UserProfileData,
  JoinAsOperator,
  joinAsOperator,
  joinAsOperatorLoading,
  joinAsOperatorError,
}) {
  const { name, email, mobile, country, profileImage } = UserProfileData || {};
  const [popUp, setPopUp] = useState(false);
  const history = useHistory();

  // console.log(popUp);

  useEffect(() => {
    GetProfile();
  }, []);

  return (
    <div id="profile">
      <Navbar />
      <Container>
        <div className="profileouterbox mt-3 mb-5">
          <div className="d-flex">
            <div className="profilewrapper hiddenformobile">
              <img src={profileImage?.md} className="profileimg" />
            </div>

            <div className="outerwrapper hiddenformobile">
              <span className="nameemail">{name}</span>
              <div className="textalignleft">
                <span>
                  <img src={mail} className="smalliconspace" />
                </span>
                <span className="headingemail">{email}</span>
              </div>
              {mobile ? (
                <div className="textalignleft">
                  <span>
                    <img src={phone} className="smalliconspace" />
                  </span>
                  <span className="heading">{mobile}</span>
                </div>
              ) : null}
              <div className="textalignleft bottomborder">
                {country ? (
                  <>
                    <span>
                      <img src={location} className="smalliconspace" />
                    </span>
                    <span className="heading">{country}</span>
                  </>
                ) : null}
              </div>
            </div>
            <div className="linksblock hiddenformobile">
              <div className="textalignleft">
                <a
                  onClick={() => history.push(`/edit-profile`)}
                >
                  <span className="links">Edit Profile</span>
                </a>
              </div>
              <div className="textalignleft">
                <a
                  onClick={() => history.push(`/change-password`)}

                >
                  <span className="links">Change a Password</span>
                </a>
              </div>
              {/* {_.isEmpty(UserProfileData?.roles) ? (
                <div className="textalignleft">
                  {" "}
                  <a href="#" onClick={() => setPopUp(true)}>
                    <span className="links">Join as a Operator</span>
                  </a>
                </div>
              ) : null}
              {!_.isEmpty(UserProfileData?.roles) &&
              UserProfileData?.roles[0]?.role === "operator" &&
              UserProfileData?.roles[0]?.approved === false ? (
                <div className="textalignleft topMargin">
                  {" "}
                  <span className="red">
                    Joining As Operator Request is Pending
                  </span>
                </div>
              ) : null}
              {!_.isEmpty(UserProfileData?.roles) &&
              UserProfileData?.roles[0]?.role === "operator" &&
              UserProfileData?.roles[0]?.approved === true ? (
                <div className="textalignleft topMargin">
                  {" "}
                  <span className="greenText">
                    Your operator request is approved
                  </span>
                  <br></br>
                  <a
                    className="links"
                    href="https://operator.followgoa.com/#/signin"
                  >
                    Sign In As a Operator
                  </a>
                </div>
              ) : null} */}
            </div>
          </div>

          <div className="profilewrapper1 hiddenfordesktop">
            <img
              src={profileImage?.md}
              className="profileimg1"
              referrerpolicy="no-referrer"
            />
          </div>

          <div className="outerwrapper1 hiddenfordesktop">
            <span className="nameemail">{name}</span>
            <div className="textalignleft">
              <span>
                <img src={mail} className="smalliconspace" />
              </span>
              <span className="headingemail">{email}</span>
            </div>
            {mobile ? (
              <div className="textalignleft">
                <span>
                  <img src={phone} className="smalliconspace" />
                </span>
                <span className="heading">{mobile}</span>
              </div>
            ) : null}
            <div className="textalignleft bottomborder">
              {country ? (
                <>
                  <span>
                    <img src={location} className="smalliconspace" />
                  </span>
                  <span className="heading">{country}</span>
                </>
              ) : null}
            </div>
          </div>
          <div className="linksblock1 hiddenfordesktop">
            <div className="textalignleft">
              <a
                onClick={() => history.push(`/edit-profile`)}

              >
                <span className="links">Edit Profile</span>
              </a>
            </div>
            <div className="textalignleft">
              <a
                onClick={() => history.push(`/change-password`)}

              >
                <span className="links">Change a Password</span>
              </a>
            </div>
            {/* <div className="textalignleft">
              {_.isEmpty(UserProfileData?.roles) ? (
                <div className="textalignleft">
                  {" "}
                  <a href="#" onClick={() => setPopUp(true)}>
                    <span className="links">Join as a Operator</span>
                  </a>
                </div>
              ) : null}
              {!_.isEmpty(UserProfileData?.roles) &&
              UserProfileData?.roles[0]?.role === "operator" &&
              UserProfileData?.roles[0]?.approved === false ? (
                <div className="textalignleft topMargin">
                  {" "}
                  <span className="red">
                    Joining As Operator Request is Pending
                  </span>
                </div>
              ) : null}
              {!_.isEmpty(UserProfileData?.roles) &&
              UserProfileData?.roles[0]?.role === "operator" &&
              UserProfileData?.roles[0]?.approved === true ? (
                <div className="textalignleft topMargin">
                  {" "}
                  <span className="greenText">Your operator request is approved</span>
                  <br></br>
                  <a className="links" href="https://operator.followgoa.com/#/signin">Sign In As a Operator</a>
                </div>
              ) : null}
            </div> */}
          </div>
        </div>

        <ConfirmationPopup
          show={popUp}
          setShowModal={setPopUp}
          setShowSuccess={JoinAsOperator}
        />
      </Container>
      <MainFooter />
    </div>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const ProfileDataLoading = _.get(user, "userProfileLoading", false);
  const UserProfileData = _.get(user, "userDetails", null);
  const joinAsOperatorLoading = _.get(user, "joinAsOperatorLoading", false);
  const joinAsOperator = _.get(user, "joinAsOperator", null);
  const joinAsOperatorError = _.get(user, "joinAsOperatorError", undefined);

  return {
    ProfileDataLoading,
    UserProfileData,
    joinAsOperatorLoading,
    joinAsOperator,
    joinAsOperatorError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetProfile: (data) =>
    dispatch({ type: "GetProfileDataCalled", payload: data }),
  JoinAsOperator: (data) =>
    dispatch({ type: "joinAsOperatorCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
