import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { resetOperatorSignUpState } from "@store/userredux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import NavBar from "@components/navbar/Navbar";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import SuccessPopup from "@components/success-popup/SuccessPopup";
import ConfirmationPopup from "@components/confirmation-popup/ConfirmationPopup";
import logo from "@images/logo.svg";
import "@features/operator-signup/OperatorSignupStyle.scss";
import * as _ from "lodash";

function OperatorSignup({
  operatorSignUpLoading,
  operatorSignUp,
  operatorSignUpError,
  callOperatorSignUp,
  resetOperatorSignUp,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showeye1, setshoweye1] = useState(true);
  const [showeye2, setshoweye2] = useState(true);

  // useEffect(() => {
  //   if (operatorSignUp !== undefined) {
  //     if (alert("Operator signup successful!")) {
  //       resetOperatorSignUp();
  //       // setName("");
  //       // setEmail("");
  //       // setMobile("");
  //       // setPassword("");
  //       // setChecked(false);
  //       // setConfirmPassword("");
  //     } else {
  //       resetOperatorSignUp();
  //       // setName("");
  //       // setEmail("");
  //       // setMobile("");
  //       // setPassword("");
  //       // setChecked(false);
  //       // setConfirmPassword("");
  //     }
  //   }
  // }, [operatorSignUp]);

  useEffect(() => {
    if (operatorSignUpError !== undefined) {
      if (alert(`${operatorSignUpError}`)) {
        resetOperatorSignUp();
      } else {
        resetOperatorSignUp();
      }
    }
  }, [operatorSignUpError]);

  const handleCheckButton = (e) => {
    setChecked(e.target.value);
  };

  const onSubmit = () => {
    if (
      email === "" ||
      mobile === "" ||
      password === "" ||
      name === "" ||
      checked === false
    ) {
      alert("Please enter all fields!");
    } else {
      if (checked === false) {
        alert("Please agree to terms and conditions!");
      } else {
        if (
          new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)
        ) {
          if (password !== confirmPassword) {
            alert("Password and confirm password do not match!");
          } else if (mobile.length < 10) {
            alert("Please enter a valid mobile number uptill 10 digits !");
          } else {
            let data = {
              email: email,
              name: name,
              password: password,
              mobile: mobile,
              openSuccessPopup: () => setShowSuccess(true),
            };
            callOperatorSignUp(data);
          }
        } else {
          alert("Please enter a valid email !");
        }
      }
    }
  };
  return (
    <>
      <NavBar />
      {operatorSignUpLoading && <Loader />}
      <div id="OperatorSignup">
        <Container>
          <div className="form-div">
            <Row>
              <Col lg={12}>
                <Card className="signup-card">
                  <span className="heading">Sign Up As Operator</span>
                  <img src={logo} className="brandlogo" />
                  <Card.Body>
                    <Form>
                      <FormGroup className="form-input">
                        <label>Organization Name/Individual Name</label>
                        <InputField
                          placeholder="Enter Organization Name/Individual Name"
                          type="text"
                          classname="form-field"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Email</label>
                        <InputField
                          placeholder="Enter email"
                          type="email"
                          classname="form-field"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Mobile</label>
                        <InputField
                          placeholder="Mobile"
                          type="text"
                          classname="form-field"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Password</label>
                        <InputField
                          placeholder="Password"
                          type={showeye1 ? "password" : "text"}
                          classname="password-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          eye
                          setshow={() => setshoweye1(!showeye1)}
                          showeye={showeye1}
                        />
                      </FormGroup>
                      <FormGroup className="form-input">
                        <label>Confirm Password</label>
                        <InputField
                          placeholder="Confirm Password"
                          type={showeye2 ? "password" : "text"}
                          classname="password-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          eye
                          setshow={() => setshoweye2(!showeye2)}
                          showeye={showeye2}
                        />
                      </FormGroup>
                      <FormGroup className="check-div">
                        <Form.Check
                          label={"Agree to terms and conditions"}
                          name="category"
                          value={checked}
                          onClick={(e) => handleCheckButton(e)}
                        />
                      </FormGroup>

                      <Row>
                        <CustomButton
                          type="button"
                          name="Sign Up"
                          filled={true}
                          primary={true}
                          onClick={onSubmit}
                          // onClick={() => setShowConfirm(true)}
                          classname="signup-btn"
                        />
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <ConfirmationPopup
          show={showConfirm}
          setShowModal={(e) => setShowConfirm(e)}
          setShowSuccess={(e) => setShowSuccess(e)}
        />
        <SuccessPopup
          show={showSuccess}
          scope="operator"
          setShowModal={(e) => setShowSuccess(e)}
          message={
            "Thank you for showing interest in Followgoa! Your request to become an operator has been summited for review. Our team will update status by mail."
          }
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ user = {} }) => {
  const operatorSignUpLoading = _.get(user, "operatorSignUpLoading", false);
  const operatorSignUp = _.get(user, "operatorSignUp", undefined);
  const operatorSignUpError = _.get(user, "operatorSignUpError", undefined);

  return {
    operatorSignUpLoading,
    operatorSignUp,
    operatorSignUpError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callOperatorSignUp: (data) =>
    dispatch({ type: "requestOperatorSignUpCalled", payload: data }),
  resetOperatorSignUp: () => dispatch(resetOperatorSignUpState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorSignup);
