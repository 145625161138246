import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "@components/navbar/Navbar";
import MainFooter from "@components/footer/MainFooter";
import "@features/terms-and-conditions-for-events/TermsConditionForEventsStyle.scss";

const TermsandConditionsForEvents = () => {
  return (
    <div id="termsForEvents">
      <NavBar />
      <Container>
        <h1>Terms and Conditions for FollowGoa Car Rentals</h1>

        <h2>User Agreement</h2>
        <p>
          Through the Website, Followgoa shall provide you ("User")
          entertainment-related information, pricing, availability, and
          reservations for tickets for cinemas, plays, concerts, sports events
          across cities and rural areas throughout India ("Service"), as more
          particularly described and defined in the terms of service ("TOS")
          relating to such Service. This Service may be availed by the User and
          their family members.
        </p>

        <p>
          This User Agreement ("Agreement") sets out the terms and conditions on
          which Followgoa shall provide the Services to the User through the
          Website. In addition to this Agreement and depending on the Services
          opted for by the User, the User shall be required to read and accept
          the relevant TOS for such Service, which may be updated or modified by
          Followgoa from time to time. Such TOS shall be deemed to be a part of
          this Agreement and in the event of a conflict between such TOS and
          this Agreement, the terms of this Agreement shall prevail.
        </p>

        <p>
          Use of the Website is offered to the User conditioned on acceptance
          without modification of all the terms, conditions, and notices
          contained in this Agreement and the TOS, as may be posted on the
          Website from time to time. For the removal of doubts, it is clarified
          that the use of the Website by the User constitutes an acknowledgment
          and acceptance by the User of this Agreement and the TOS. If the User
          does not agree with any part of such terms, conditions, and notices,
          the User must not use the Website.
        </p>

        <p>
          Additionally, the Service Provider may provide terms and guidelines
          that govern particular features, offers or the operating rules and
          policies applicable to each Service (for example, movie tickets, gift
          vouchers, Combo's, etc.). The User shall be responsible for ensuring
          compliance with the terms and guidelines or operating rules and
          policies of the Service Provider with whom the User elects to deal,
          including terms and conditions set forth in a Service Provider's. In
          the event that any of the terms, conditions, and notices contained in
          this Agreement or the TOS conflict with the additional/other terms and
          guidelines specified by the Service Provider, then the latter
          terms/guidelines shall prevail.
        </p>

        <h2>Follow Goa Platform</h2>
        <p>
          Follow Goa Platform ("Platform"), owned and operated by Elusive
          Entertainment OPC Private Limited ("Company"). By accessing or using
          our Platform, you agree to comply with and be bound by the following
          terms and conditions ("Terms"). Please read these Terms carefully
          before using the Platform.
        </p>

        <p>
          The Platform serves as a booking and information platform for
          third-party events in Goa. The Company is not the organizer of these
          events but facilitates the booking process on behalf of event
          organizers.
        </p>

        <p>Users must be at least 15 years old to use the Platform.</p>

        <p>
          Users are responsible for providing accurate and up-to-date
          information during the booking process.
        </p>

        <p>
          Users are solely responsible for their interactions with event
          organizers and other users.
        </p>

        <h2>Bookings and Payments</h2>
        <p>
          All bookings are subject to availability and the terms set by the
          event organizer.
        </p>

        <p>
          Users are responsible for the payment of all fees associated with
          their bookings.
        </p>

        <p>
          The Company is not responsible for any financial transactions between
          users and event organizers.
        </p>

        <h2>Refund Policy</h2>
        <h3>Event Cancellation</h3>
        <p>
          In case of a cancellation by the event organizer, a full refund will
          be applicable to all ticket holders. The refund will be processed
          using the original payment method.
        </p>

        <h2>Date Change Policy</h2>
        <p>
          If the date of the event is changed, tickets remain valid for the
          rescheduled date. No refunds will be provided in case of date changes.
        </p>

        <h2>Cancellation Policy</h2>
        <p>
          Once a ticket is booked, it cannot be cancelled. No refunds will be
          provided for any cancellations made by users.
        </p>

        <h3>User-Initiated Ticket Cancellation</h3>
        <p>
          Users are not eligible for a refund if they cancel their tickets. All
          ticket sales are final, and no refunds will be issued for
          user-initiated cancellations.
        </p>

        <h2>Non-Transferable Tickets</h2>
        <p>
          Tickets are non-transferable. They can only be used by the individual
          whose name is on the ticket.
        </p>

        <h2>Tour Cancellation Terms</h2>
        <p>
          For tours booked through the Platform, there shall be a partial refund
          if cancelled within the specified time frame.
        </p>

        <h2>Rights of Admission</h2>
        <p>
          The event organizer reserves the right to refuse admission to any
          attendee. Follow Goa Platform is not responsible for decisions made by
          the event organizer regarding admission.
        </p>

        <h2>Contact Information for Refund Inquiries</h2>
        <p>
          For refund inquiries or concerns, users can contact our customer
          support at{" "}
          <a href="mailto:followgoa@gmail.com">followgoa@gmail.com</a>. Please
          include the event name, booking reference, and a detailed description
          of the issue in your email.
        </p>

        <h2>Event Policies</h2>
        <p>
          Users must adhere to the rules and policies set by the event
          organizers. The Company is not responsible for any changes,
          cancellations, or disputes related to the events.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          The Company is not liable for any direct, indirect, incidental,
          special, or consequential damages arising out of or in any way
          connected with the use of the Platform or the events booked through
          it.
        </p>

        <h2>Indemnification</h2>
        <p>
          Users agree to indemnify and hold the Company harmless from any
          claims, losses, damages, liabilities, and expenses arising out of
          their use of the Platform.
        </p>

        <h2>Termination</h2>
        <p>
          The Company reserves the right to terminate or suspend a user's access
          to the Platform without notice for any reason.
        </p>

        <h2>Governing Law</h2>
        <p>
          These Terms are governed by the laws of India, and any disputes
          arising out of or in connection with these Terms will be subject to
          the exclusive jurisdiction of the courts of Goa.
        </p>

        <h2>Changes to Terms</h2>
        <p>
          The Company reserves the right to update or modify these Terms at any
          time without prior notice. Users are responsible for reviewing the
          Terms regularly.
        </p>

        <p>
          By using the Follow Goa Platform, you acknowledge that you have read,
          understood, and agree to be bound by these Terms and Conditions. If
          you do not agree with any part of these Terms, you should not use the
          Platform.
        </p>

        <h2>Terms and Conditions for FollowGoa Rentals</h2>
        <h3>Acceptance of Terms</h3>
        <p>
          By accessing or using the FollowGoa.com website and mobile application
          (hereinafter collectively referred to as "FollowGoa"), you agree to
          comply with and be bound by these Terms and Conditions. If you do not
          agree to these terms, please do not use FollowGoa's services.
        </p>
        <h2>Description of Service</h2>
        <p>
          FollowGoa is an online platform that connects users with third-party
          car/bike rental providers. FollowGoa facilitates the booking of
          cars/villas/bikes, etc., for rental purposes only. We do not own,
          operate, or control any of the vehicles listed on our platform.
        </p>

        <h2>Booking and Payments</h2>
        <h3>Booking</h3>
        <p>
          Users can book a rental through FollowGoa by providing necessary
          details such as check-in/checkout pickup location, drop-off location,
          date, and time, etc. The availability of a specific rental is subject
          to the discretion of the rental providers.
        </p>

        <h3>Payment</h3>
        <p>
          Payments for rentals are processed securely through FollowGoa's
          payment gateway. Users agree to pay the total amount specified at the
          time of booking, which includes the rental fee, applicable taxes, and
          any additional charges for extra services or equipment.
        </p>

        <h2>Cancellation and Refunds</h2>
        <h3>Cancellation</h3>
        <p>
          Users can cancel their booking through FollowGoa, subject to the
          cancellation policy of the respective car rental provider.
          Cancellation fees may apply.
        </p>

        <h3>Refunds</h3>
        <p>
          Refunds, if applicable, will be processed following the car rental
          provider's refund policy. FollowGoa is not responsible for the refund
          process and timelines.
        </p>

        <h2>Cancellation Policy</h2>
        <p>
          <strong>Cancellation 20 Days or More Before Booking:</strong> If the
          booking is cancelled 20 days or more before the scheduled pickup date,
          the user will receive a 100% refund of the total booking amount.
        </p>
        <p>
          <strong>Cancellation 24 Hours to 19 Days Before Booking:</strong> If
          the booking is cancelled between 24 hours to 19 days before the
          scheduled pickup date, a 15% cancellation charge will apply, and the
          remaining amount will be refunded to the user.
        </p>

        <h2>Booking Conditions</h2>
        <p>
          <strong>Valid License Holder:</strong> Bookings must be made under the
          name of a valid license holder. The person whose name appears on the
          booking must be the same individual to whom the car or bike will be
          delivered.
        </p>
        <p>
          <strong>Pre-Delivery Inspection:</strong> Users are advised to
          thoroughly inspect the car or bike before taking delivery. Any
          pre-existing damages or issues should be documented and reported to
          the rental provider immediately.
        </p>
        <p>
          <strong>Damage Settlement:</strong> In case of any damage caused
          during the rental period, the settlement for repair costs or damages
          will be handled directly between the user and the rental provider.
          FollowGoa shall not be responsible for any settlements related to
          damages or disputes arising during the rental period.
        </p>

        <h2>User Responsibilities</h2>
        <p>
          <strong>Accuracy of Information:</strong> Users are responsible for
          providing accurate and complete information during the booking
          process. FollowGoa and the car rental providers rely on this
          information for the service.
        </p>
        <p>
          <strong>Compliance:</strong> Users must comply with all applicable
          laws, rules, and regulations while using the car rental service.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          FollowGoa is not liable for any damages, including but not limited to,
          incidental, consequential, or punitive damages, arising out of the use
          or inability to use the services provided by FollowGoa or the
          third-party car rental providers.
        </p>

        <h2>Privacy</h2>
        <p>
          FollowGoa respects the privacy of its users. Please refer to our{" "}
          <a href="/privacy-policy">Privacy Policy</a> to understand how we
          collect, use, and disclose information.
        </p>
        <h2>Modifications</h2>
        <p>
          FollowGoa reserves the right to modify, suspend, or discontinue any
          part of the service at any time, without notice. FollowGoa also
          reserves the right to amend these Terms and Conditions at its sole
          discretion.
        </p>

        <h2>Governing Law</h2>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of Goa Government, without regard to its conflict of law
          principles.
        </p>

        <p>
          By using FollowGoa's services, you acknowledge that you have read,
          understood, and agree to be bound by these Terms and Conditions. If
          you have any questions or concerns, please contact us at{" "}
          <a href="tel:9773999910">9773999910</a>.
        </p>

        <h2>Privacy Policy</h2>
        <p>
          The User hereby consents, expresses and agrees that he has read and
          fully understands the Privacy Policy of Followgoa in respect of the
          Website, The User further consents that the terms and contents of such
          Privacy Policy are acceptable to him.
        </p>

        <p>
          Any customer grievances can be reported to{" "}
          <a href="mailto:followgoa@gmail.com">followgoa@gmail.com</a>
        </p>

        <h2>Limited User</h2>
        <p>
          The User agrees and undertakes not to sell, trade or resell or exploit
          for any commercial purposes, any portion of the Service. For the
          removal of doubt, it is clarified that the Website is not for
          commercial use but is specifically meant for personal use only.
        </p>

        <p>
          Followgoa has endeavored to ensure that all the information on the
          website is correct, but Followgoa neither warrants nor makes any
          representations regarding the quality, accuracy or completeness of any
          data or information contained. Followgoa makes no warranty, express or
          implied, concerning the website and/or its contents and disclaims all
          warranties of fitness for a particular purpose and warranties of
          merchantability in respect of services, including any liability,
          responsibility or any other claim, whatsoever, in respect of any loss,
          whether direct or consequential, to any user or any other person,
          arising out of or from the use of the information contained in the
          website.
        </p>

        <p>
          Since Followgoa acts only as a booking agent, it shall not have any
          liability whatsoever for any aspect of the arrangements between the
          service provider and the user as regards the standards of services
          provided by the service providers. In no circumstances shall Followgoa
          be liable for the services provided by the service provider.
        </p>

        <p>
          Although Follow Goa makes reasonable commercial efforts to ensure that
          the description and content in the TOS and on each page of the website
          is correct, it does not, however, take responsibility for changes that
          occurred due to human or data entry errors or for any loss or damages
          suffered by any user due to any information contained herein. Also,
          Follow Goa is not the service provider and cannot therefore control or
          prevent changes in the published descriptions which are based upon
          information provided by.
        </p>

        <p>
          Follow Goa shall not be liable for any direct, indirect, punitive,
          incidental, special, consequential damages or any other damages
          resulting from: (a) the use or the inability to use the services; (b)
          the cost of procurement of substitute goods and services or resulting
          from any goods, information, or services purchased or obtained or
          messages received or transactions entered into through the services;
          (c) unauthorized access to or alteration of the user's transmissions
          or data; (d) any other matter relating to the services; including,
          without limitation, damages for loss of use, data or profits, arising
          out of or in any way connected with the use or performance of the
          website. Neither shall Elusive Entertainment be responsible for the
          delay or inability to use the website or related services, the
          provision of or failure to provide services, or for any information,
          software, products, services and related graphics obtained through the
          website, or otherwise arising out of the use of the website, whether
          based on contract, tort, negligence, strict liability or otherwise.
        </p>
        <p>
          The Website may contain services such as email, chat, WhatsApp,
          bulletin board services, information related to recommendations, news
          groups, forums, communities, personal web pages, calendars, and/or
          other message (hereinafter collectively referred to as "Communication
          Services"). The User agrees and undertakes to use the Communication
          Services only to post, send and receive messages and material that are
          proper and related to the particular Communication Service.
        </p>

        <p>
          By way of example, and not as a limitation, the User agrees and
          undertakes that when using a Communication Service, the User will not:
        </p>

        <ul>
          <li>
            Defame, abuse, harass, stalk, threaten or otherwise violate the
            legal rights of others;
          </li>
          <li>
            Upload files that contain software or other material protected by
            intellectual property laws unless the User owns or controls the
            rights thereto or has received all necessary consents;
          </li>
          <li>
            Upload or distribute files that contain viruses, corrupted files, or
            any other similar software or programs that may damage the operation
            of the Website or another's computer;
          </li>
          <li>
            Advertise or offer to sell or buy any goods or services for any
            business purpose, unless such Communication Service specifically
            allows such messages;
          </li>
          <li>
            Conduct or forward surveys, contests, pyramid schemes or chain
            letters;
          </li>
          <li>
            Download any file posted by another user of a Communication Service
            that the User knows or reasonably should know cannot be legally
            distributed in such a manner;
          </li>
          <li>
            Falsify or delete any author attributions, legal or other proper
            notices or proprietary designations...
          </li>
          <li>
            Violate any code of conduct or other guidelines, which may be
            applicable for or to any particular Communication Service;
          </li>
          <li>
            Violate any applicable laws or regulations for the time being in
            force in or outside India; and
          </li>
          <li>
            Violate any of the terms and conditions of this Agreement or any
            other terms and conditions for the use of the Website contained
            elsewhere herein.
          </li>
        </ul>

        <p>
          Followgoa has no obligation to monitor the Communication Services.
          However, Followgoa reserves the right to review materials posted
          through Communication Service and to remove any materials in its sole
          discretion. Followgoa reserves the right to terminate the User's
          access to any or all of the Communication Services at any time without
          notice for any reason whatsoever.
        </p>

        <p>
          Followgoa reserves the right at all times to disclose any information
          as is necessary to satisfy or comply with any applicable law,
          regulation, legal process or governmental request, or to edit, refuse
          to post or to remove any information or materials, in whole or in
          part.
        </p>

        <p>
          Followgoa does not control or endorse the content, messages or
          information found in any communication service and, therefore,
          Followgoa specifically disclaims any liability or responsibility
          whatsoever with regard to the communication services and any actions
          resulting from the user's participation in any communication service.
        </p>
        <p>
          Materials uploaded to a Communication Service may be subject to posted
          limitations on usage, reproduction and/or dissemination. User is
          responsible for keeping himself updated of and adhering to such
          limitations if they download the materials. Followgoa reserves the
          right, in its sole discretion, to terminate the access to the website
          and the related services or any portion thereof at any time, without
          notice. Followgoa reserves the right to charge listing fees for
          certain listings, as well as transaction fees based on certain
          completed transactions using the Followgoa Services. Followgoa further
          reserves the right to alter any and all fees from time to time,
          without notice. The User shall be liable to pay all applicable
          charges, fees, duties, taxes, levies and assessments for availing the
          Followgoa Services.
        </p>
        <h2>User's obligation and user account</h2>
        <p>
          In consideration of use of the Website, the User represents and
          confirms that the User is of legal age to enter into a binding
          contract and is not a person barred from using the Website and/or
          receiving the Services under the laws of India or other applicable
          law.
        </p>

        <p>
          To avail a Service, the User has and must continue to maintain at his
          sole cost:
        </p>
        <ol>
          <li>
            All the necessary equipment including a computer and modem etc. to
            access the Website/avail Services
          </li>
          <li>Own access to the World Wide Web</li>
        </ol>
        <p>
          The User shall be responsible for accessing the Services, and that
          access may involve third-party fees including airtime charges or
          internet service provider's charges, which are to be exclusively borne
          by the User.
        </p>

        <p>
          The user also understands that the services may include certain
          communications from Followgoa as service announcements and
          administrative messages. The user understands and agrees that the
          services are provided on an "as is" basis and that Followgoa does not
          assume any responsibility for deletions, miss-delivery, or failure to
          store any user communications or personalized settings.
        </p>

        <p>
          Registration of the User on the Website is optional. If the User opts
          to register himself on the Website, upon completion of the
          registration process, the User shall receive a user id and password.
          The User agrees and undertakes at all times to be responsible for
          maintaining the confidentiality of the password and user id and shall
          be fully responsible for all activities that occur by use of such
          password or user id. Further, the User agrees not to use any other
          party's user id and password for any purpose whatsoever without proper
          authorization from such party. You are responsible for the security of
          your password and for all transactions undertaken using your password
          through our service. The Password entered by you is transmitted in a
          one-way encrypted form to our database and stored as such. Thus, the
          Password will not be known even to Followgoa.
        </p>

        <p>
          The user also agrees and undertakes to immediately notify Followgoa of
          any unauthorized use of the user's password or user id and to ensure
          that the user logs off at the end of each session at the website.
          Followgoa shall not be responsible for any direct or indirect loss or
          damage arising out of the user's failure to comply with this
          requirement.
        </p>

        <p>
          Furthermore, the User grants Followgoa the right to disclose to third
          parties Registration Data to the extent necessary for the purpose of
          carrying out the Services.
        </p>
        <h2>Breach</h2>
        <p>
          Without prejudice to the other remedies available to Followgoa under
          this agreement, the TOS or under applicable law, Followgoa may limit
          the user's activity, or end the user's listing, warn other users of
          the user's actions, immediately temporarily/indefinitely suspend or
          terminate the user's registration, and/or refuse to provide the user
          with access to the website if:
        </p>
        <ol>
          <li>
            the user is in breach of this agreement, the TOS and/or the
            documents it incorporates by reference;
          </li>
          <li>
            Followgoa is unable to verify or authenticate any information
            provided by the user; or
          </li>
          <li>
            Followgoa believes that the user's actions may infringe on any third
            party rights or breach any applicable law or otherwise result in any
            liability for the user, other users of the website and/or Followgoa
            may at any time in its sole discretion reinstate suspended users.
          </li>
        </ol>

        <h2>Proprietary Rights</h2>
        <p>
          Followgoa may provide the User with content such as sound,
          photographs, graphics, video or other material contained in sponsor
          advertisements or information. This material may be protected by
          copyrights, trademarks, or other intellectual property rights and
          laws. The User may use this material only as expressly authorized by
          Followgoa and shall not copy, transmit or create derivative works of
          such material without express authorization from Followgoa.
        </p>
        <p>
          The User acknowledges and agrees that they shall not upload, post,
          reproduce or distribute any content on or through the Website that is
          protected by copyright or other proprietary right of a third party,
          without obtaining the permission of the owner of such right.
        </p>

        <h2>None of the provisions...</h2>
        <p>
          None of the provisions of this Agreement, terms and conditions,
          notices or the right to use the Website by the User contained herein
          or any other section or pages of the Website and/or the Linked Sites
          shall be deemed to constitute a partnership between the User and
          Followgoa and no party shall have any authority to bind or shall be
          deemed to be the agent of the other in any way. It may be noted,
          however, that if by using the Website, the User authorizes Followgoa
          and its agents to access third party sites designated by them or on
          their behalf for retrieving requested information, the
        </p>

        <h2>Headings</h2>
        <p>
          The headings and subheadings herein are included for convenience and
          identification only and are not intended to describe, interpret,
          define or limit the scope, extent or intent of this Agreement, the TOS
          or the right to use the Website by the User contained herein or any
          other section or pages of the Website or any Linked Sites in any
          manner whatsoever.
        </p>

        <h2>User's Indemnification</h2>
        <p>
          The User agrees to indemnify, defend and hold harmless Followgoa from
          and against any and all losses, liabilities, claims, damages, costs
          and expenses (including legal fees and disbursements in connection
          therewith and interest chargeable thereon) asserted against or
          incurred by Followgoa that arise out of, result from, or may be
          payable by virtue of any breach or non-performance of any
          representation, warranty, covenant or agreement made or obligation to
          be performed by the User pursuant to this Agreement and/or the TOS.
        </p>

        <h2>Invalidity or Unenforceability</h2>
        <p>
          If any provision of this Agreement is determined to be invalid or
          unenforceable in whole or in part, such invalidity or unenforceability
          shall attach only to such provision or part of such provision and the
          remaining part of such provision and all other provisions of this
          Agreement shall continue to be in full force and effect.
        </p>
        <h2>Termination of Agreement and Services</h2>
        <p>
          Either the User or Followgoa may terminate this Agreement and a
          Service with or without cause at any time to be effective immediately.
        </p>

        <p>
          The User agrees that Followgoa may, under certain circumstances and
          without prior notice, immediately terminate the User's user id and
          access to the Website/Services. Causes for termination may include,
          but shall not be limited to, breach by the User of this Agreement or
          the TOS, requests by enforcement or government agencies, requests by
          the User, nonpayment of fees owed by the User in connection with the
          Services as specified in the applicable TOS.
        </p>

        <p>
          This Agreement may be terminated by Followgoa without a written notice
          to the other. Followgoa shall not be liable.
        </p>

        <p>
          Upon termination of the Service, User's right to use the
          Website/Services shall immediately cease. The User shall have no right
          and Followgoa shall have no obligation thereafter to execute any of
          the User's uncompleted tasks or forward any unread or unsent messages
          to the User or any third party.
        </p>

        <p>
          All notices and communications (including those related to changes in
          the TOS, Service, termination of Service etc.,) shall be in writing,
          in English and shall be deemed given if delivered personally or by
          commercial messenger or courier service, or mailed by registered or
          certified mail (return receipt requested) or sent via email/facsimile
          (with acknowledgment of complete transmission) to the following
          address:
        </p>

        <p>
          If to Followgoa, at followgoa@gmail.ocm or at the address posted on
          the Website. Notice shall be deemed to have been served 48 hours after
          it has been sent, dispatched, displayed, as the case may be unless,
          where notice has been sent by email, it comes to the knowledge of the
          sending party that the email address is invalid.
        </p>

        <h2>Payment Methods</h2>
        <p>
          Followgoa accepts Visa, MasterCard, American Express, select Debit
          Cards, and Net Banking. Other payment methods may be added from time
          to time.
        </p>

        <h2>Who You Are Buying From</h2>
        <p>
          Followgoa acts as the agent for those who are promoting the events for
          which you purchase tickets ("Event Organizer") and for tours (Travel
          agent). When you purchase a ticket for an event/tour, Followgoa will
          be handling the transaction and collecting payment for the
          organizer/host.
        </p>

        <h2>Pricing and Availability</h2>
        <p>
          Followgoa sells tickets on behalf of Event organizers/Hosts and does
          not control the inventory or its availability and pricing.
        </p>

        <h2>Internet Handling Fees and Order Processing Fees</h2>
        <p>
          Tickets purchased on Followgoa are subject to a per-ticket internet
          handling fee and a non-refundable per-order processing fee.
        </p>
        <h2>Cancelled/Postponed events/tours</h2>
        <p>
          Occasionally, events/tours are canceled or postponed by the
          promoter/host. Should this occur, we will attempt to contact you and
          refund the amount as per the policy of the organizer/tours operator.
        </p>

        <h2>Customer Care</h2>
        <p>
          If you need help regarding your order or any other issue, the best in
          the company is available. We guarantee a response at the best.
        </p>

        <h2>Read Through our Terms and Conditions Carefully</h2>
        <p>
          Accepting the Terms and conditions while booking a ticket online makes
          it a valid contract between you and us. Booking Id's generated only
          through Followgoa shall be considered by us as a valid Booking Id.
        </p>
        <p>
          Ensure the personal details entered including the card details are
          accurate. Booking Id's once generated cannot be
          cancelled/refunded/exchanged.
        </p>
        <p>
          Ensure the Booking Id's are kept safe and always in your or such
          person's possession who is considered as your authorized/rightful
          holder of the same. Anybody in possession of the Booking Id shall be
          entitled to get the same exchanged for a valid Ticket without further
          security checks on the title of such holder.
        </p>
        <p>Ensure that the Booking Id's are not compromised or resold.</p>
        <p>
          Do not entertain any person who sells printouts or SMS of Booking Id's
          generated by us. If you come across any person undertaking such
          activity, immediately report the same to the local police station.
        </p>
        <p>
          We never sell a Ticket at a price higher than the price mentioned on
          our website.
        </p>
        <p>
          We will never ask for your Personal Identification Number (PIN)
          details over the phone or through any means except through the
          transaction page on the website when you book online.
        </p>
        <p>
          Booking Id's generated by us entitle the holder thereof to exchange it
          for a valid Ticket. The same does not constitute a valid Ticket in
          itself. We will never sell you Booking Id's as Tickets.
        </p>
        <p>
          Booking Id's generated entitle the holder thereof to a valid ticket.
          It is your responsibility to ensure that the holder is the rightful
          holder of the same. Do not compromise or misuse the Booking ID's.
        </p>
        <p>Customers are advised to avoid carrying dangerous items.</p>

        <h2>Notice & Take Down Policy</h2>
        <p>
          AS AN INTERMEDIARY, FOLLOWGOA IS NOT LIABLE FOR ANY THIRD-PARTY
          CONTENT UPLOADED, TRANSMITTED, DISPLAYED, PUBLISHED OR SHARED ON ITS
          PLATFORM. BY USING FOLLOWGOA.COM, YOU AGREE TO THE CONDITIONS SET OUT
          IN THIS NOTICE AND TAKE DOWN POLICY If the owner of a
          copyright-protected work or an agent authorized to act on the
          copyright owner's behalf (the "Owner") believes the work posted on
          followgoa ("the Site") is infringing the Owner's.copyright under the
          relevant law, the Owner may submit a copyright infringement
          notification. We recommends Owners of copyright-protected content to
          report alleged copyright infringement by submitting a written
          complaint (the "Complaint") either through a web-form (recommended) or
          through email . The Complaint should include the following:-
          (a)Contact Information: The Owner will need to provide information
          that will allow us and the uploader(s) of the video(s) that the
          Owner's objects to, to contact the Owner regarding the Complaint, such
          as an email address, physical address or telephone number
        </p>
        <p>
          In case of a Complaint by the Owner that is submitted in accordance
          with the procedure in Clause 2, and found to be valid, Followgoa may
          refrain from facilitating access to the allegedly infringing work for
          a period of 21 (twenty-one) days or until an order from a competent
          court is received, whichever is earlier.
        </p>

        <p>
          Users may submit a complaint against objectionable content by
          submitting a written complaint (the "Complaint") either through a
          web-form or through email.
        </p>

        <p>
          Through its "Followgoa.com” website, we provide a User with the
          ability to, inter alia, purchase and/or reserve tickets for tours,
          concerts, sports events, cars, villas, etc. throughout Goa.
        </p>

        <ul>
          <li>Followgoa means “Elusive Entertainment OPC Private Limited".</li>
          <li>
            Followgoa shall not be liable in the event it fails to fulfill any
            of its obligations under this agreement due to flood, earthquake,
            storm, cyclone, or any other acts of God of similar nature, war
            strike, lockout, or governmental or judicial or quasi-judicial
            policies/intervention/direction/prohibition or change in law or for
            any other reasons beyond its control.
          </li>
          <li>
            A waiver of any of the terms and conditions by Followgoa shall not
            be deemed to be a continuing waiver but shall apply solely to the
            instances to which the waiver is directed.
          </li>
          <li>
            All claims and disputes arising under the terms and conditions
            should be notified to Followgoa within 15 days post transaction date
            after which no customer shall have any claim against Followgoa.
          </li>
          <li>
            Followgoa reserves the right to change, at any time, these terms and
            conditions, features, and benefits offered through COUPONS Cash.
          </li>
          <li>
            Registration to Followgoa account is at the sole discretion of
            Followgoa and it may refuse membership to any applicant without
            assigning any reasons.
          </li>
        </ul>

        <h2>Refund Policy for Followgoa.com</h2>
        <ol>
          <li>
            <strong>Eligibility for Refund</strong>
            <p>We offer refunds under the following circumstances:</p>
            <ul>
              <li>
                <strong>Service Unavailability:</strong> If a service becomes
                unavailable due to technical issues or unforeseen circumstances.
              </li>
              <li>
                <strong>Cancellation by User:</strong> No user cancellation
                refund for events.
              </li>
            </ul>
          </li>
          <li>
            <strong>
              Depending on the cancellation window, you may be eligible for a
              refund:
            </strong>{" "}
            Convert all the content for me the same way you are doing.
          </li>
        </ol>
        <h2>Refund and Cancellation Policy</h2>

        <h3>Cancellation Terms</h3>
        <p>Cancel 30 Days: Full refund.</p>
        <p>Less than 30 to 5 days: Partial refund.</p>
        <p>
          Less than 4 days: No refund. (Applicable for tours, villas, yachts,
          car bookings, and adventure activities. No user cancellation refund
          for events.)
        </p>
        <p>
          Billing Errors: If you encounter a billing error, contact our customer
          support team.
        </p>

        <h3>Refund Requests</h3>
        <p>
          <strong>To request a refund:</strong>
        </p>
        <p>
          Contact Us: Email{" "}
          <a href="mailto:bookings@followgoa.com">bookings@followgoa.com</a>{" "}
          with the subject "Refund Request," providing details like your name,
          contact information, booking reference number, and a brief explanation
          of the reason.
        </p>
        <p>
          <strong>Review Process:</strong> Allow 3 days for our team to
          thoroughly investigate your request.
        </p>
        <p>
          <strong>Notification:</strong> We will notify you of the decision via
          email. If approved, the refund will be processed within 3 to 5 working
          days to the original payment method.
        </p>

        <h3>Non-Refundable Items</h3>
        <p>
          Note that some services or items may be non-refundable, clearly
          indicated during the booking or purchase process.
        </p>

        <h3>Contact Information</h3>
        <p>For questions or assistance, contact our customer support team:</p>
        <p>
          Email:{" "}
          <a href="mailto:booking@followgoa.com">booking@followgoa.com</a>
        </p>
        <p>Phone: +91 96739 99910</p>

        <p>
          By using Followgoa, you agree to abide by this Refund and Cancellation
          Policy. We reserve the right to update or modify this policy as
          needed. Please check periodically for any changes.
        </p>

        <p>
          Your satisfaction is important to us, and we are here to assist you
          throughout the refund and cancellation process. Thank you for choosing
          Followgoa!
        </p>

        <h3>Third Party Sites</h3>
        <p>
          This Site may contain links to other websites ("Third Party Sites").
          We do not own or operate the Third Party Sites, and we have not
          reviewed, and cannot review, all of the material, including goods or
          services, made available through Third-Party Sites. The availability
          of these links on this Site does not represent, warrant or imply that
          we endorse any Third Party Sites or any materials, opinions, goods or
          services available on them. Third party materials accessed through or
          used by means of the Third Party Sites may also be protected by
          copyright and other intellectual property laws. THIS AGREEMENT DOES
          NOT APPLY TO THIRD PARTY SITES. BEFORE VISITING A THIRD PARTY SITE BY
          MEANS OF THIS SITE OR A LINK LOCATED ON THIS SITE, USERS SHOULD REVIEW
          THE THIRD PARTY SITE'S TERMS AND CONDITIONS, PRIVACY POLICY AND ALL
          OTHER SITE DOCUMENTS, AND INFORM THEMSELVES OF THE REGULATIONS,
          POLICIES AND PRACTICES OF THESE THIRD PARTY SITES.
        </p>
        <h2>Disclaimers</h2>
        <p>
          ALL CONTENT OR CONTRIBUTIONS OR ANY OTHER MATERIALS OR ITEMS PROVIDED
          THROUGH OUR PLATFORM BY US ARE PROVIDED "AS IS" AND "AS AVAILABLE,"
          WITHOUT WARRANTY OR CONDITIONS OF ANY KIND. BY OPERATING OUR PLATFORM,
          WE DO NOT REPRESENT OR IMPLY THAT WE ENDORSE ANY CONTRIBUTIONS OR ANY
          OTHER MATERIALS OR ITEMS AVAILABLE ON OR LINKED TO BY OUR PLATFORM,
          INCLUDING WITHOUT LIMITATION, CONTENT HOSTED ON THIRD PARTY SITES, OR
          THAT WE BELIEVE CONTENT OR ANY OTHER MATERIALS OR ITEMS TO BE
          ACCURATE, USEFUL OR NON-HARMFUL. WE CANNOT GUARANTEE AND DO NOT
          PROMISE ANY SPECIFIC RESULTS FROM USE OF OUR PLATFORM. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR OUR
          PLATFORM SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS
          AGREEMENT. YOU AGREE THAT YOUR USE OF OUR PLATFORM AND SERVICES WILL
          BE AT YOUR SOLE RISK. WE DO NOT WARRANT THAT OUR PLATFORM OR SERVICES
          WILL BE AVAILABLE FOR USE, AND WE DO NOT MAKE ANY WARRANTIES AS TO THE
          QUALITY OF THE SITE, SERVICES OR ITS CONTENT. TO THE FULLEST EXTENT
          PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED IN
          CONNECTION WITH OUR PLATFORM AND YOUR USE THEREOF.
        </p>
        <p>
          WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY,
          RELIABILITY, TIMELINESS OR COMPLETENESS OF OUR PLATFORM'S CONTENT, THE
          CONTENT OF ANY SITE LINKED TO OUR PLATFORM, CONTRIBUTIONS, INFORMATION
          OR ANY OTHER ITEMS OR MATERIALS ON THIS PLATFORM OR LINKED TO BY OUR
          PLATFORM. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS,
          MISTAKES OR INACCURACIES OF CONTENT, CONTRIBUTIONS AND MATERIALS, (B)
          PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM YOUR ACCESS TO AND USE OF OUR PLATFORM OR SERVICES, (C)
          ANY ILLEGAL OR UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND
          ALL PERSONAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM OUR PLATFORM, (E) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
          THROUGH OUR PLATFORM BY ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR
          OMISSIONS IN ANY CONTRIBUTIONS, CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT,
          CONTRIBUTIONS, OR MATERIALS POSTED, TRANSMITTED, OR OTHERWISE MADE
          AVAILABLE VIA OUR PLATFORM.WE WILL NOT BE LIABLE TO YOU FOR ANY LOS
          CONTENT) OR FOR LOSS OF USE OF THIS SITE.
        </p>
        <p>
          We might share basic information with our business partners, such as
          when you are redirected to any platform. We may also use your usage
          and location details for ad targeting purposes.
        </p>

        <p>Image & Posters Fair Usage Policy:</p>

        <p>
          All the used Images/Posters, Backgrounds, and other data are copyright
          and trademarks of their respective Publishers/Owners.
        </p>
      </Container>
      <MainFooter />
    </div>
  );
};

export default TermsandConditionsForEvents;
