import React from "react";
import { Tab } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "@components/tab/TabStyles.scss";

function Historytab({ children, page, changeTab, tableno }) {
  return (
    <div className="Tab">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={"first"}
        activeKey={tableno}
      >
        {page == "history" && (
          <Nav className="align-left fordisplay">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => changeTab("first")}>
                Confirmed Booking
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => changeTab("second")}>
                Pending Booking
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => changeTab("third")}>
                Failed Booking
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        <Tab.Content>{children}</Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default Historytab;
