import * as _ from "lodash";
import { select } from "redux-saga/effects";

export function* getAccessToken() {
  const user = yield select(({ user }) => user);
  return _.get(user, "signedIn.accessToken", "");
}

export function* getUserInfo() {
  const user = yield select(({ user }) => user);
  return _.get(user, "userDetails", undefined);
}
